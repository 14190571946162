$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.hero-popup{
  //border:solid 1px red;
  margin-top:100px;
  width:743px;
  max-width: calc(100% - 60px);
  position:relative;
  &__close{
    position:absolute;
    top:20px;
    right:20px;
    z-index:1050;

    &-ico{
      fill:#545454;;
      @include s{
        fill:#fff;
      }
    }
  }
  &__wrap{
    position:relative;
    display:flex;
  }
  &__slider{
    width:50%;
    min-width: 50%;
    background: url( $cdn + "/alf/hero-slider.jpg") 50% 50% no-repeat;
    background-size: cover;
    border-radius: 10px;
    height:403px;
  }
  &__info{
    width:50%;
    min-width: 50%;
    border-radius: 10px;
    height:403px;
    box-sizing: border-box;
    padding:0 30px 0 18px;
  }
  &__btns{
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 60px;
  }
  @include s{
    padding:20px 30px;
    &__wrap{
      flex-direction: column;
    }
    &__slider{
      width:100%;
      min-width: 100%;
      min-height: 700px;

    }
    &__info{
      width:100%;
      min-width: 100%;
    }
    &__btns{
      bottom: 55px;
    }
    &__info{
      height: auto;
    }
  }
  @include xs{
    padding:20px 15px;
    max-width: calc(100% - 30px);
    &__slider{
      min-height: 100vw;
      height: 100vw;
    }
    &__info{
      padding:20px 15px;
    }
    & .hero-desc__text_rp{
      margin:0;
    }
  }
}

.hero-popup-vip{
  padding-top:12px;
  &__item{
    &:not(:first-child){ border-top: solid 1px  #e9e9e9;}
    display:flex;
    justify-content: space-between;
    line-height: 25px;
    min-height:25px;


  }
  &__key{
    color: #666666;
    font-weight: 400;
    font-size: 10px;
  }
  &__val{
    color: #2a2b2b;
    font-size: 10px;
    font-weight: 400;
  }

}


.hero-popup-desc{
  &__title{
    padding:20px 0 9px;
    text-align: center;
    color: #545454;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.18px;
    & span{ font-size: 18px;
      letter-spacing: 0.23px;
    }
    @include s{
      font-size:14px;
      & span{ font-size: 18px}
    }
    @include xs{
      padding: 0 0 16px;
    }

  }
  &__text{
    color: #292929;
    font-weight: 400;
    text-align: justify;
    max-height: 144px;
    overflow-y: auto;
    height:108px;
    padding-right:12px;
    font-size: 10px;
    line-height: 19.63px;


    &_rp{
      padding-right:19px;
      margin-right:-20px;

    }


    @include s{
      font-size:10px;
    }
  }
}



.hero-popup-evolution{
  &__title{
    color: #545454;
    font-size: 12px;
    font-weight: 700;
    padding-top:12px;
    text-align: center;
  }
  &__list{
    display:flex;
    justify-content: space-between;
    width:100%;
    margin: 0 auto;
    max-width: 100%;
    padding-top:12px;
    overflow-x: auto;
    overflow-y: hidden;


  }
  &__ico{
    width:64px;
    height:64px;
    margin:0 auto;
    display:flex;

    //border:solid 1px red;
    border-radius: 50%;
    box-sizing: border-box;
    &.active{
      border:solid 2px $violetD;
    }
  }
  &__img{
    cursor: pointer;
    width:52px;
    height:52px;
    border-radius: 50%;
    margin:auto;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    overflow:hidden;
    background-size: 500% !important;
    &.level1{ background-position:0 50% !important; }
    &.level2{ background-position:25% 50% !important; }
    &.level3{ background-position:50% 50% !important; }
    &.level4{ background-position:75% 50% !important; }
    &.level5{ background-position:100% 50% !important; }




    .registration-hero__evolution-ico.active &{
      //margin:0 auto;
      //width:70px;
      //height:70px;
      box-shadow: none;
    }
  }


  &__level{
    text-align: center;
    font-size: 10px;
    font-weight: 700;
  }
  @include l{
    &-item{
      width:60px;
      min-width:60px;
    }
    &-ico{
      width:60px;
      height:60px;
    }
    &-img{
      width:50px;
      height:50px;
    }
  }
  @include s{
    &-list{
      justify-content: flex-start;
    }
    &-item{ width:20%;}
    &__item {
      padding-bottom: 11px;
    }
  }
}


.hero-popup-evolution__img{
  .hero-popup-evolution__list.flamma &{ background-image: url( $cdn + "/alf/hero-ava/sprite-flamma.jpg" ); }
  .hero-popup-evolution__list.morbi &{ background-image: url( $cdn + "/alf/hero-ava/sprite-morbi.jpg" ); }
  .hero-popup-evolution__list.terra &{ background-image: url( $cdn + "/alf/hero-ava/sprite-terra.jpg" ); }
  .hero-popup-evolution__list.tractus &{ background-image: url( $cdn + "/alf/hero-ava/sprite-tractus.jpg" ); }
  .hero-popup-evolution__list.liquidum &{ background-image: url( $cdn + "/alf/hero-ava/sprite-liquidum.jpg" ); }
}