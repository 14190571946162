$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-bnr-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
    width: 88px;
    height: 24px;

    @include xxs {
      width: 65px;
      height: 18px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 12px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    font-size: 10px;
    line-height: 1;
    word-break: break-all;
  }
}

// .main-banner-icons-ja {


//   &__list {
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     height: 100%;
//   }

//   &__item {
//     width: 88px;
//     height: 24px;

//     line-height: 0;
//     font-size: 12px;
//     &:not(:last-child) {
//       margin-right: 20px;
//     }
//   }

//   @include xxs() {
//     min-height: 117px;
//     &__list {
//       flex-direction: column;
//     }
//     &__item {
//       padding: 5px 8px;
//       text-align: center;

//       background-color: #fff;
//       border-radius: 10px;

//       &:not(:last-child) {
//         margin-right: 0;
//         margin-bottom: 10px;
//       }
//     }
//   }
// }
