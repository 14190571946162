$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.santa-slope-wrapper {
  overflow: hidden;
}

.event-promo-banner-wrapper {
  margin-block: 0 50px;
}

.event-promo-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  min-height: 138px;
  background-image: $banner-gradient;
  border-radius: 10px;
  padding: 15px 42px;
  margin-block: 20px 0;

  @include xs {
    padding: 8px 35px;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &:after {
    inset: 0;
    background-image: url($cdn + "/alf/christmas/christmas-banner.png");
    background-size: cover;
    mix-blend-mode: soft-light;
    border-radius: 10px;

    @include s {
      background-position: 0;
    }
  }

  &:before {
    bottom: -52%;
    left: auto;
    right: 0;
    width: 335px;
    height: 297px;
    background-image: url($cdn + "/alf/christmas/santa-mob.png");
    background-size: 100%;

    @include xxs {
      bottom: -50%;
      left: auto;
      right: -57px;
      background-image: url($cdn + "/alf/christmas/santa-ski.png");
      background-size: 66%;
    }
  }

  &__content,
  &__btn {
    z-index: 1;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $yellow;
    margin: 0 0 6px 0;

    @include xxs {
      font-size: 12px;
    }
  }

  &__text {
    font-size: 25px;
    font-weight: 700;
    text-shadow: 0 0 30px rgba(255, 255, 160, 0.80);
    color: $white;
    margin: 0;

    @include xs {
      font-size: 22px;

      @include xxs {
        font-size: 16px;
      }
    }
  }

  &__title,
  &__text {
    word-break: break-word;
    hyphens: auto;
  }

  &__btn {
    width: 178px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
  }

  &__bg {
    @include xxs {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 41%;
      border-radius: 10px;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 13px;
        left: 23%;
        width: 90%;
        height: 500px;
        border-radius: 331px;
        background-color: #8331AD;
        filter: blur(11px);
      }
    }
  }
}
