$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.vip-page{

}





.vip-page-hero{
  display:flex;
  &__slider{
    min-width: 50%;
    border-radius: 5px;
    min-height:550px;
    background: url( $cdn + "/alf/hero-slider.jpg") 50% 50% no-repeat;
    background-size:cover;

  }
  &__desc{
    min-width: 50%;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    padding:20px;
    box-sizing: border-box;

  }
  &__table-title{
    padding:30px 0 20px;
    text-align: center;
    color: #545454;
    font-size: 14px;
    font-weight: 700;
  }
  &__table-content{
    overflow-x: auto;
    overflow-y: hidden;
  }

  @include s{
    display:block;
    &slider{ min-height: 500px;}
  }
  @include xs{
    &__slider{ min-height: calc(100vw - 30px); }
  }
  @include xxs(){
    &__desc{
      padding:20px 10px;
    }
  }
}



.vip-p{
  background: url('/img/wave.svg') no-repeat 50% 100% ;
  background-size: 100%;
  margin-bottom: 14px;
  min-height:400px;
  &__block{
    display: flex;
    width:100%;
    padding: 40px 0 50px;
  }
  &__item{
    max-width:25%;
    width:25%;
    margin-right: 50px;
  }
  &__title {
    color: #292929;
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.21px;
    padding: 16px 0;
  }
  &__ico{
    width: 116px;
    height: 77px;
  }
  & svg{
    width: 100%;
    height: 100%;
  }
  &__text{
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.12px;
    text-align: justify;
    }
  @include l{
    margin-bottom:0;
    &__item {
      max-width:50%;
      width: 50%;
      padding: 0 50px 0 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 0;
    }
    &__title{
      width: calc(100% - 117px);
      padding: 0 15px;

      font-size: 24px;
      font-weight: 500;
    }
    &__text{
      width: 100%;
    }
    &__block{
      flex-wrap: wrap;
      padding-top:20px;
    }
    &__ico{
      margin: 10px 0;
    }
  }

  @include s{
    &__item{
      max-width: 100%;
      width: 100%;
    }
    &__ico{
      width: 85px;
      height: 57px;
    }
    &__title {
      width: calc(100% - 86px);
    }
    &__block{
      padding: 0 0 30px;
    }
  }
  @include xs{
    &__item{padding: 0;}
  }
}

