$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.page-popup {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 50px;
  & * {
    box-sizing: border-box;
  }
  &__wrap {
    max-width: 1090px;
    margin: 0 auto;
  }
  &__close {
    margin-right: -15px;
  }
  &__header {
    align-items: center !important;
    display: flex;
    &-l {
      width: calc(100% - 50px);
    }
  }

  @include l {
    padding: 30px;
    &__header-l {
      width: calc(100% - 35px);
    }
  }
  @include s {
    padding: 20px 30px;

    &__header-l {
      width: calc(100% - 30px);
    }
  }
  @include xs {
    padding: 15px;
    &__header-l {
      width: calc(100% - 45px);
    }
    &__close {
      margin-right: -2px;
    }
  }
}
