$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.table {
  margin: 20px 0;

  &__thead {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  &__tbody {
    max-height: 320px;
    overflow-y: auto;
    border-top: 1px solid #ecebf6;
    border-bottom: 1px solid #ecebf6;
  }

  &__th {
    color: #545454;
    font-size: 11px;
    font-weight: 700;

    width: 25%;
    max-width: 25%;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  &__tr {
    display: flex;
    border-bottom: 1px solid #ecebf6;

    &:last-child {
      border-bottom: none;
    }
  }

  &__td {
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    box-sizing: border-box;
    width: 25%;
    max-width: 25%;
    text-align: left;
    //padding:10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #666666;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2em;
  }

  &__cancel {
    width: 20px;
    height: 20px;
    background: $red;
    border-radius: 50%;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 9px;
      left: 4px;
      width: 13px;
      height: 2px;
      background: #fff;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  @include xs {
    &__th,
    &__td {
      width: 50%;
      max-width: 50%;
    }
  }
  @include xxs {
    &__th,
    &__td {
      width: 50%;
      max-width: 50%;
      padding: 10px 4px;
    }
  }

  &.g-scroll {
    overflow-x: auto;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      margin-top: 40px;
    }

    .table__thead {
      position: sticky;
      top: 0;
    }

    .table__tbody {
      overflow-y: unset;
      border: unset;
    }

    .table__tr {
      border: unset;
    }

    .table__th,
    .table__td {
      min-width:70px;
      background: $white;
      text-align: center;
      border-bottom: 1px solid #ecebf6;
    }
  }
}
