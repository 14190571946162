$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-slider {

  display: block;

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
  }

  &__text {
    margin-top: 20px;
    font-weight: bold;
    font-size: 28px;
    line-height: 140%;
  }

  &__btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;

    &-bonus {
      font-size: 18px;
      line-height: 1;
      color: #FFFFFF;
      background-color: #09AF48;
      border-radius: 80px;
      padding: 16px 30px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      letter-spacing: .2px;

      @include min-l {
        font-size: 28px;
        line-height: 20px;
        padding: 32px 42px;
      }
    }

    @include xs {
      margin-top: 10px;
    }

    @include min-s {
      margin-top: 20px;
    }
  }

  &__link {
    margin-top: 20px;
    text-align: center;

    & a {
      font-weight: bold;
      font-size: 12px;
      line-height: 140%;
      text-align: center;
      text-decoration-line: underline;
      color: #FFFFFF;

      @include min-m {
        font-size: 16px;
      }
    }
  }

  &__nav {
    position: absolute;
    width: 0px;
    height: 0;
    left: 50%;
    transform: translateY(55px);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dots {
    order: 2;
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    & ul {
      margin: 0;
    }

    & li {
      margin: 0;
      padding: 0;
    }

    & button {
      padding: 0;
      margin: 4px;
      font-size: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: none;
      background: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }

    & .slick-active button {
      background: #fff;
    }

    @include min-xs {
      margin-top: -30px;
    }

    @include min-m {
      margin-top: -10px;
    }
  }

  .slick {

    &-slider {
      max-width: 540px;
      margin: 0 auto;

      @include min-m {
        max-width: 800px;
      }

    }

    &-active {
      @include min-xs {
        opacity: 1 !important;
      }

      .satellite-planet {
        visibility: hidden;
      }
    }

    &-slide {
      transition: .25s linear;
      opacity: 0;
      @include min-xs {
        margin: 0 30px;
        display: flex;
        align-items: flex-end;
        width: 144px !important;
        opacity: 0;
      }

      @include min-m {
        width: unset !important;
      }
    }

    &-list {
      @include min-xs {
        padding: 0 40px 60px !important;
        margin-left: -30px;
        margin-right: -30px;
      }

      @include min-s {
        padding: 0 40px 65px !important;
      }

      @include min-m {
        padding: 0 40px 35px !important;
      }
    }

    &-track {
      @include min-xs {
        display: flex;
        top: 20px;
        align-items: flex-end;
        height: 256px;
      }

      @include min-m {
        justify-content: center;
      }
    }
  }

  .slick-current {
    opacity: 1;
    @include min-xs {
      width: auto !important;
      z-index: 11;
      transform: translateY(-80px);

      .main-slider__item {
        bottom: 0;
      }
    }

    @include min-m {
      width: unset !important;
    }

    .satellite-planet {
      animation: linear 4s orbit infinite;
      visibility: visible;

      @include min-xs {
        animation: linear 4s orbit-xs infinite;
      }

      @include min-m {
        animation: linear 4s orbit-m infinite;
      }
    }


    .image-container {
      @include min-xs {

        .bonus-name {
          top: 50%;
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;

          span {
            font-size: 18px;
            line-height: 22px;

            &:last-child {
              padding-top: 10px;
              font-size: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }

    .image-container__planet {
      opacity: 1;
      z-index: 1;
      @include min-xs {
        transform: scale(2.3);
      }
    }
  }

  .video-banner {
    @include m {
      display: none;
    }

    &__wrap {
      position: relative;
      width: 100%;
      height: 625px;

      .banner-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .first-video__banner {
        max-width: 1160px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 90%;


        .main-slider__video {
          top: 5%;
          right: 3vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;

          animation-name: hide-first-banner-animation;
          animation-delay: 5s;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-fill-mode: both;
          transition: 1s;

          width: 500px;

          @include xl {
            right: 1vw;
          }
        }

        .all-planet__items {
          .planet_item {
            position: absolute;

            .planet-name {
              display: flex;
              flex-direction: column;
              align-items: center;
              //max-width: 250px;

              width: 305px;

              &__1 {
                width: 32px;
              }

              span {
                display: block;
                font-weight: 800;
                color: #FFFFFF;
                text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                padding-bottom: 10px;
              }
            }

            &.planet_4 {
              left: 79%;
              top: 42%;

              animation-name: scale_planet;
              animation-delay: 7.75s;
              animation-duration: .5s;
              animation-timing-function: linear;
              animation-fill-mode: both;
              transition: transform .5s;

              @include xl {
                left: 79.5%;
              }

              span {
                font-size: 18px;
                line-height: 22px;

                &:last-child {
                  font-size: 29px;
                  line-height: 40px;

                  @include xl {
                    font-size: 34px;
                  }
                }
              }
            }

            &.planet_3 {
              //left: 66%;
              left: 59.5%;
              top: 17%;

              animation-name: scale_planet;
              animation-delay: 7.5s;
              animation-duration: .5s;
              animation-timing-function: linear;
              animation-fill-mode: both;
              transition: transform .5s;

              span {
                font-size: 18px;
                line-height: 22px;

                &:last-child {
                  font-size: 42px;
                  line-height: 40px;
                }
              }

              .sattelit-planet {
                position: absolute;
                width: 77px;
                height: 77px;

                animation: linear 5.5s sattelite_2 infinite;
                animation-delay: 7s;

                transition: left .5s, top .5s;

                &__item {
                  width: 100%;
                  height: auto;
                }

                &__bonus {
                  position: absolute;
                  font-weight: 800;
                  font-size: 22px;
                  line-height: 23px;
                  color: #FFFFFF;
                  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                  margin: 0;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  text-align: center;
                  display: inline;
                }
              }
            }

            &.planet_2 {
              //left: 37.3%;
              left: 32.5%;
              top: 13%;

              animation-name: scale_planet;
              animation-delay: 7.25s;
              animation-duration: .5s;
              animation-timing-function: linear;
              animation-fill-mode: both;
              transition: transform .5s;

              span {
                font-size: 18px;
                line-height: 22px;

                &:last-child {
                  font-size: 50px;
                  line-height: 40px;
                }
              }
            }

            &.planet_1 {
              left: 7.5%;
              top: 38%;

              animation-name: scale_planet;
              animation-delay: 7s;
              animation-duration: .5s;
              animation-timing-function: linear;
              animation-fill-mode: both;
              transition: transform .5s;

              span {
                font-size: 24px;
                line-height: 22px;
                padding-bottom: 15px;

                &:last-child {
                  //font-size: 72px;
                  font-size: 50px;
                  line-height: 40px;
                }
              }

              .sattelit-planet {
                position: absolute;
                width: 77px;
                height: 77px;

                animation: linear 7s sattelite_1 infinite;
                animation-delay: 7s;
                animation-duration: 4s;
                transition: left .5s, top .5s;

                &__item {
                  width: 100%;
                  height: auto;
                }

                &__bonus {
                  position: absolute;
                  font-weight: 800;
                  font-size: 22px;
                  line-height: 23px;
                  color: #FFFFFF;
                  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                  top: 50%;
                  margin: 0;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  text-align: center;
                  display: inline;
                }
              }
            }
          }
        }

        .welcom-bonus {
          animation-name: welcome-bonus-desk;
          animation-delay: 1s;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-fill-mode: both;
          transition: transform 1s;

          span {
            font-size: 44px;
            line-height: 56px;
            font-weight: 800;
            display: block;
            text-align: center;
            letter-spacing: 0.362973px;
            color: #FFFFFF;
            text-shadow: 0 1px 8px rgba(18, 66, 92, 0.62);

            &:last-child {
              font-size: 120px;
              line-height: 166px;
            }
          }
        }

        .sun-planet {
          position: absolute;
          right: -20px;
          top: 15%;

          animation-name: welcome-bonus-desk;
          animation-delay: 2s;
          animation-duration: .25s;
          animation-timing-function: linear;
          animation-fill-mode: both;
          transition: transform .25s;

          &__img {
            width: 70px;
            height: 70px;
          }

          &__name {
            font-weight: 800;
            font-size: 22px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }

  .first-banner {
    position: relative;
    animation-name: banner-out;
    animation-delay: 4s;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transition: all 2s;
    opacity: 1;
    top: 0;

    .alien {
      position: absolute;
      left: -140px;
      top: 42px;
      width: 290px;
      z-index: 1;

      @include min-xs {
        left: -60px;
        top: 32px;
      }

      @include min-s {
        width: 327px;
        left: -30px;
        top: 0;
      }

      @include min-m {
        left: 46px;
      }

      @include min-l {
        left: 4px;
        width: 438px;
      }

      @include min-xl {
        left: -90px;
      }
    }

    .sun-wrap {
      position: absolute;
      right: -140px;
      top: -140px;
      width: 474px;
      height: 485px;


      &__text {
        position: absolute;
        top: 50%;
        left: 45%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        line-height: 22px;

        span {
          display: block;
          text-shadow: 0 1px 8px rgba(18, 66, 92, 0.62);

          &:last-child {
            font-size: 45px;
            line-height: 60px;
            text-shadow: 0 6px 8px rgba(18, 66, 92, 0.72);

            @include min-xs {
              font-size: 52px;
              line-height: 60px;
            }

            @include min-s {
              font-size: 58px;
              line-height: 65px;
            }

            @include min-l {
              font-size: 60px;
              line-height: 70px;
            }
          }

          &:first-child {
            padding-bottom: 5px;
          }
        }

        @include min-xs {
          font-size: 20px;
          line-height: 24px;
        }

        @include min-s {
          top: 55%;
          font-size: 24px;
          line-height: 30px;
        }

        @include min-m {
          top: 50%;
          left: 50%;
        }

        @include min-l {
          font-size: 44px;
          line-height: 54px;
        }
      }

      &__sun {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: linear 6s rotate-sun infinite;
      }

      .satellite-planet {
        position: absolute;
        bottom: 70px;
        right: 160px;
        animation: linear 3s rotate;
        animation-fill-mode: both;

        &__img {
          width: 80px;
          height: 80px;
        }

        &__name {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #FFFFFF;
          font-size: 18px;
          margin: 0;
          text-align: center;
          font-weight: 700;

          @include min-xs {
            font-size: 22px;
            line-height: 24px;
          }
        }

        @include min-s {
          right: 210px;
        }
      }

      @include min-xs {
        width: 546px;
        height: 546px;
        right: -160px;
      }

      @include min-s {
        width: 650px;
        height: 650px;
        right: -200px;
        top: -300px;
      }

      @include min-m {
        width: 580px;
        height: 580px;
        right: -50px;
        top: -120px;
      }

      @include min-l {
        width: 950px;
        height: 950px;
        right: -150px;
        top: -350px;
      }

      @include min-xl {
        width: 1068px;
        height: 1068px;
        top: -430px;
        right: -250px;
      }
    }

    @include min-l {
      display: none;
    }
  }

  &__wrap {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    animation-name: banner-in;
    animation-delay: 4s;
    animation-duration: .75s;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transition: all 2s;
    opacity: 0;
    left: 0;
    transform: translateX(1500px);

    @include min-l {
      animation-name: unset;
      transform: translateX(0);
      opacity: 1;

      display: none;
    }
  }

  @include min-l {
    //padding: 0;
    margin: 0;
    padding-top: 35px;
  }
}

.btn-wrap {
  @include min-l {
    position: absolute;
    bottom: 200px;
    max-width: 1160px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--cont {
    @include min-l {
      animation-name: btn-center;
      animation-delay: 5.5s;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-fill-mode: both;
      transition: all 1.2s;

      position: absolute;
      top: -140px;
      right: 145px;
    }
  }
}

.main-slider__items {
  @include min-l {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
  }
}

.main-slider__item {
  transition: translate .5s;
  color: #FFFFFF;
  @include min-xs {
    position: relative;
  }

  @include min-l {
    position: absolute;

    &.planet_1 {
      left: -110px;
      width: 570px;
      height: 355px;
      z-index: 4;

      animation-name: planet_1;
      animation-delay: 1s;
      animation-duration: 20s;
      animation-timing-function: linear;
      animation-fill-mode: both;
      transition: all 1s;

      .image-container {
        .bonus-name {
          .planet-number {
            padding-bottom: 10px;
          }

          span {
            &:last-child {
              padding-top: 20px;
              font-size: 72px;
              line-height: 40px;
            }
          }
        }
      }
    }
    &.planet_2 {
      top: -80px;
      left: 247px;
      width: 500px;
      z-index: 3;
      height: 280px;

      .image-container {
        .bonus-name {
          .planet-number {
            padding-bottom: 10px;
          }

          span {
            &:last-child {
              padding-top: 10px;
              font-size: 60px;
              line-height: 40px;
            }
          }
        }
      }
    }
    &.planet_3 {
      width: 453px;
      left: 555px;
      top: -40px;
      z-index: 2;
      height: 280px;

      .image-container {
        .bonus-name {
          .planet-number {
            padding-bottom: 10px;
          }

          span {
            &:last-child {
              padding-top: 10px;
              font-size: 56px;
              line-height: 40px;
            }
          }
        }
      }
    }
    &.planet_4 {
      width: 370px;
      left: 850px;
      top: 70px;
      z-index: 1;
      height: 216px;

      .image-container {
        .bonus-name {
          .planet-number {
            padding-bottom: 10px;
          }

          span {
            &:last-child {
              padding-top: 10px;
              font-size: 56px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  .image-container {
    position: relative;
    padding-top: 26px;

    @include min-xs {
      max-width: 400px;
      margin: 0 auto;
    }

    @include min-l {
      max-width: 100%;
    }

    .bonus-name {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-weight: 800;
      line-height: 22px;
      z-index: 1;
      //width: 100%;
      width: 260px;

      @include min-xs {
        transform: translate(-50%, -50%) scale(.6);
        opacity: 0;
        transition: opacity .5s;
      }

      span {
        display: block;

        &:last-child {
          padding-top: 10px;
          font-size: 40px;
          line-height: 40px;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

          @include min-xs {
            font-size: 24px;
            line-height: 40px;
          }
        }
      }

      .planet-number {
        margin: 0 auto;
        padding-bottom: 15px;
        height: 32px;
      }

      @include min-xs {
        top: 65%;
        font-size: 14px;
        line-height: 22px;
      }

      @include min-l {
        top: 50%;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .planet-item {
      z-index: 1;
      max-width: 300px;
      margin: 0 auto;
      position: relative;
    }

    .satellite-planet {
      position: absolute;
      width: 80px;
      height: 80px;

      &__name {
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: 700;
        width: fit-content;
        max-width: 43px;
        text-align: center;
      }

      @include min-l {
        animation: linear 10s orbit-l infinite;
        transform: translate(-50%, -50%);
      }
    }

    &__planet {
      transition: opacity .5s;
      opacity: .35;
      z-index: 10;

      @include min-xs {
        width: 204px;
        transform: scale(1.5);
        transition: all .25s;
      }

      @include min-l {
        transform: unset;
        width: auto;
      }
    }
  }
}

.slide-label {
  display: block;
  position: absolute;
  left: 20px;
  padding: 6px 10px;
  top: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  font-weight: 700;
  width: fit-content;
  border-radius: 12px;
  z-index: 100;
  transition: background-color .25s linear;

  &.slide-0 {
    background: rgba(35, 127, 126, 0.5);
  }

  &.slide-1 {
    background: rgba(197, 28, 14, 0.5);
  }

  &.slide-2 {
    background: rgba(249, 115, 68, 0.5);
  }

  &.slide-3 {
    background: rgba(0, 114, 203, 0.5);
  }

  @include min-l {
    display: none;
  }
}
