$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;


.reg-bank{
  padding: 100px 0 150px;
  background: url(  $cdn + "/alf/registration_1800.jpg" ) 50% 50% no-repeat;
  background-size: cover;
  &__wrap {
    width: 590px;
    margin:0 auto;
    display:flex;
    flex-direction: column;
  }
  &__link-back {
    width: 18px;
    height: 15px;
    display: inline-block;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
  &__logo {
    width: 138px;
    height: 37px;
    margin: 0 auto;
    display: block;
  }
  &__content{
    min-width: 100%;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 40px 40px;
  }
  &__step-title {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
    color: #FFFFFF;
    font-weight: 600;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  &__step-text {
    width: 100%;
    text-align: center;
  }
  &__title{
    color: #545454;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &__steps{
    display:flex;
    flex-wrap: wrap;
    padding: 20px 0 5px;
    justify-content: center;
    max-width: 380px;
    margin: auto;
  }
  &__step{
    overflow:hidden;
    width: 170px;
    color: #292929;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  &__form{
    display:flex;
    width: 220px;
    flex-wrap: wrap;
    margin: auto;
  }
  &__input{ width:100%;}

  &__btns{
    padding-top:20px;
    text-align: center;
    width:100%;
  }
  &__nobank{
    color: #545454;
    font-size: 12px;
    font-weight: 500;

    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top:10px;
    width:100%;
    & a{
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__nobank-i{
    padding:10px 5px;
    a {
      color: #545454;
    }
  }
  &__step_3, &__step_4 {
    margin-left: 40px;
  }

  &__step-0 {

  }

  &__accept-rules-text {
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    margin: 20px 0 0;
    & a {
      color: #fff;
      text-decoration: underline;
    }
  }
  &__slider {
    width: 100%;
    min-width: 100%;
    height: 400px;
    border-radius: 5px;
    overflow: hidden;
  }
  @include s{
    padding: 20px 15px 60px;
    background: url( $cdn + "/alf/registration_904.jpg" ) no-repeat 50% 50%;
    background-size: cover;

    &__wrap{
      display:block;
    }
    &__content{
      width: 100%;
      min-width: 100%;
    }
  }


  @include xs {
    &__wrap {
      width: 100%;
    }
  }
  @include xxs{
    &__content{
      padding: 30px 20px;
    }
    &__steps {
      flex-direction: column;
      padding: 15px 0 5px;
      max-width: 100%;
    }
    &__step {
      width: auto;
      &_2 {
        order: 2;
      }
      &_3, &_4 {
        order: 3;
        margin-left: 0;
      }
    }
  }
}

.reg-bank-step-2 {
  .reg-bank__content {
    padding: 0;
  }
  @include xxs {
    padding: 20px 0px 60px;
    .reg-bank__link-back {
      padding-left: 15px;
    }
  }
}