$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.paymentcb{
  background: url( $cdn + "/alf/404.jpg") no-repeat 50% 50%;
  background-size: cover;
  width:100%;

  &.success{
    .show-fail,
    .show-processing { display:none;}
  }
  &.fail{
    .show-success,
    .show-processing { display:none;}
  }
  &.processing {
    .show-fail,
    .show-success { display: none; }
  }

  &__wrap{
    min-height:100vh;
    box-sizing: border-box;

    display:flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

  }
  &__logo{
    padding-top:50px;
    width:120px;
  }

  &__container{
    flex-grow: 1;
    padding-bottom: 100px;
    display:flex;
    flex-direction: column;
  }

  &__title{
    padding:100px 0 20px;
    color: #f0f3f4;
    font-size: 36px;
    font-weight: 500;
  }
  &__text{
    padding:10px 0 60px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    &-or{
      padding:29px 0 26px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  &__btn{
    padding-bottom:60px;
  }
  &__button{
    font-size: 16px;
  }

  &__info-wrap {
    display: flex;
    margin-bottom: 25px;
    justify-content: center;
  }
  &__content-wrap {
    text-align: left;
  }
  &__content-wrap + &__content-wrap {
    margin-left: 40px;
  }
  &__key {
    font-size: 12px;
    line-height: 1.36;
    color: #fff;
    margin-bottom: 5px;
  }
  &__value {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.33;
    color: #fff;
  }
  &__item {
    margin-bottom: 10px;
  }


  @include xs{
    &__container{
      justify-content: flex-start;
    }
    &__logo{ padding-top:20px;
      height: 100%; }
    &__title{
      padding:35px 0 19px;
      font-size: 21px;
      font-weight: 500;
    }
    &__text{
      font-size: 11px;
      font-weight: 500;
      padding:0 14px 31px;
      letter-spacing: 0.75px;
      &-or{
        padding:20px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    &__btn{
      padding-bottom:24px;
    }
    &__content-wrap {
      width: 210px;
    }
    &__content-wrap + &__content-wrap {
      margin-left: 0;
    }
    &__info-wrap {
      flex-direction: column;
    }

  }
}






.timer-progres{
  position:relative;
  margin:auto;
  width:240px;
  height:240px;

  &__bg{
    position:absolute;
    top:0px;
    left:0px;
    //width:calc(100% - 20px);
    //height:calc(100% - 20px);
    border-radius: 100%;
    background: #292929;
    border:solid 8px #5b5b5b;
    width:100%;
    height:100%;
    box-sizing: border-box;



  }

  &__l, &__r{ position:absolute; top:0; width:50%; height:100%;
    overflow: hidden;
  }
  &__l{ left:1px; }
  &__r{ right:0; }

  &__content{
    position:absolute;
    top:8px;
    right:8px;
    width:calc(100% - 16px);
    height:calc(100% - 16px);
    background: #292929;
    border-radius: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#fff;
  }
  &__text{
    color: #c7c7c9;
    font-size: 16px;
    font-weight: 500;
    padding:0 15px;

  }
  &__time{
    color: #ffffff;
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 5px;
    padding:15px 0 10px 0;
  }



  &__item{
    position:relative;
    width:100%;
    height:100%;

    animation-fill-mode:both !important;
    animation: leftTimer 10s linear;
    transform: rotate( -180deg );
    overflow: hidden;
    &:before{
      content:'';
      position:absolute;
      display:block;
      top:0;
      left:0;
      width:100%;
      height:100%;
      transform: rotate( 180deg );
      animation-direction: reverse;
      animation: leftTimer 10s linear;
      animation-fill-mode:both !important;
    }


    &_l{
      border-radius: 140px 0 0 140px;
      animation-delay: 1s;
      transform-origin: right center;
      background: #b1f9dc;
      //&:before{
      //  animation-delay: 1s;
      //  transform-origin: right center;
      //  background: #b1f9dc;
      //}
    }
    &_r{
      border-radius: 0 140px 140px 0;
      animation-delay: 11s;
      transform-origin: left center;
      background: #b1f9dc;
      //&:before{
      //  transform-origin: left center;
      //  background:linear-gradient(to right, #f3786e 0%, #ed577d 100%);
      //  animation-delay: 11s;
      //}
    }
  }
  @include xs{

    width:190px;
    height:190px;
    &__bg{ border-width:5px;}
    &__content{
      top:5px;
      right:5px;
      width:calc(100% - 10px);
      height:calc(100% - 10px);
    }
    &__text{
      font-size: 11px;
    }
    &__time{
      font-size: 36px;
    }
  }
}


@keyframes leftTimer {
  0%{ transform: rotate( 0deg ); }
}
