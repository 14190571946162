$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.bonus-item {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_empty {
    background: url('/img/empty-dashboard-2.svg');

    & * {
      display: none;
      opacity: 0;
    }
  }

  &__status {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;

    .bonus-item_popup & {
      top: -20px;
      right: -20px;
    }

    & svg {
      width: 40px;
      height: 40px;
    }
  }

  &__name {
    color: #292929;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  &__sum {
    padding: 18px 0;
    color: #6443b3;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.37px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;

    & sup {
      font-size: 13px;
      vertical-align: middle;
      line-height: 1px;
    }
  }

  &__small {
    font-size: 70%;
  }

  @include l {
    &__status {
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;

      & svg {
        width: 30px;
        height: 30px;
      }

      .bonus-item_popup & {
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;

        & svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  @include xs {
    &__sum {
      line-height: 1em;
      padding: 2px 0 5px 0;
    }

    &__text {
      font-size: 12px;
      padding-bottom: 10px;
    }

    & .g-btn {
      height: 35px;
      line-height: 35px
    }
  }
}


.autobonus .bonus-item__status {
  top: -30px !important;
  right: -30px !important;
}