$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sitemap{

  width:750px;
  max-width: calc(100% - 60px);
  margin:0 auto;
  padding-bottom:40px;

  &__title{
    padding:40px 0;
    margin: 0;
    color: #26d86a;
    font-size: 30px;
    font-weight: 700;

    text-transform: uppercase;
  }
  &__section{
    border-top:solid 2px #cecece;
    padding-bottom:20px;
  }
  &__subtitle{
    padding:25px 0;
    color: #545454;
    font-size: 21px;
    font-weight: 700;
    line-height: 1em;
  }

  &__list{
    display:flex;
    flex-wrap: wrap;
  }

  &__item{
    width:25%;
    box-sizing: border-box;
    padding-right:15px;

  }
  &__link{
    color: #292929;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
  }
  &__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  @include s{
    &__item{
      width:33.333%;
    }
  }
  @include xs{
    &__item{
      width:50%;
    }
  }

}