$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar-userbox {
  padding: 10px 0 5px;
  width: 210px;
  margin: 0 auto;
  transition: 0.3s;
  &__top {
    display: flex;
    align-items: center;
  }
  &__avatar {
    display: block;
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  &__nav {
    width: calc(100% - 90px);
    padding-left: 10px;
  }
  &__profile {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    display: flex;

    & span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 15px);
    }
    & svg {
      width: 9px;
      height: 12px;
      fill: #fff;
      margin-top: 2px;
      margin-left: 5px;
    }
    &:hover {
      color: #b7b3dc;
      & svg {
        fill: #b7b3dc;
      }
    }
  }
  &__logout {
    height: 25px;
    border: solid 1px #fff;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    border-radius: 15px;
    margin-top: 7px;
    transition: 0.3s;
    &:hover {
      background: #fff;
      color: #2a134a;
    }
  }
  &__balance {
    margin-top: 10px;
    display: block;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    background: $violetL;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    transition: 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: #6443b3;
    }
  }
}
@include min-s {
  html:not(.menu-open) {
    .sidebar-userbox {
      width: 100px;
      padding-top: 0;
      &__balance {
        height: 30px;
        line-height: 30px;
      }
      &__avatar {
        margin: 0 auto;
        display: block;
        width: 80px;
        min-width: 80px;
        height: 80px;
      }
    }
  }
}
