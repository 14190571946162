$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.registration {
  padding-top: 70px;

  &-hero__title {
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.21px;
    color: #ECEBF6;
  }

  &__back {
    position: absolute;
    left: 18px;
    top: 12px;
    width: 32px;
    height: 32px;
    background-image: url($cdn + "/alf/registration-page-bg/arrow_reg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__close {
    position: absolute;
    right: 18px;
    z-index: 10 ;
  }

  &__form {
    display: flex;
    flex-direction: column;
    grid-row-gap: 12px;
    min-height: 426px;
  }

  &__wrap {
    padding: 12px 24px 22px;
    background: white;
    max-width: 328px;
    margin: 0 auto;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    position: relative;
    min-height: 466px;

    &.show-elem {
      .dep-char {
        opacity: 0;
        visibility: hidden;
        height: 1px;
        transform: scale(0);
      }
    }

    &.is-second {
      background: linear-gradient(179.59deg, #102272 3.81%, #5944CF 67.35%, #442059 105.02%);
      box-shadow: 0 4px 4px rgba(72, 39, 111, 0.26);
      min-height: auto;

      .first-step {
        margin-top: 60px;
      }
      .slick-prev, .slick-next {
        top: 85%;
      }

      .slick-next {
        right: 40px;
      }

      .slick-prev {
        left: 40px;
      }
    }

    &.is-step {
      min-height: auto;
      padding: 0;

      // .registration-progress__step {
      //   display: none;
      // }

      .register-style {
        width: 100%;

        // &:before {
        //   content: none;
        // }
      }
    }
  }

  &-progress__step {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.21px;
    color: #666666;
    padding-bottom: 16px;
  }

  .g-formbox-container {
    position: relative;

    .g-form-error {
      top: 35px;
    }
  }

  .link {
    color: #09AF48;
    display: inline-block;
    padding: 0 3px;
    &-marketing {
      padding: 0;
    }
  }

  &__wrap-items {
    display: grid;
    grid-template-columns: 160px auto;
    grid-gap: 16px;

    &.last {
      grid-template-columns: .5fr .5fr;
      grid-gap: 12px;
    }

  }

  // radio

  &__gender {
    display: flex;
    grid-column-gap: 8px;
  }

  .gender-item {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;

    width: 48px;
    height: 48px;

    &__radio {
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark-radio {
        background: #B7B3DC;
        box-shadow: 0 1px 4px rgba(105, 43, 142, 0.28), inset 0 4px 4px rgba(0, 0, 0, 0.12);
        .gender {
          color: white;
        }
      }

      &:checked ~ .checkmark-radio:after {
        display: block;
      }
    }
  }
  .checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 48px;
    background: #FFFFFF;
    box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .gender {
      color: #B7B3DC;
      margin-top: 5px;

      svg {
        fill: currentColor;
      }
    }
  }

  // checkbox

  &__check-item {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
  }

  &__check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &.ng-invalid ~ .g-form-error {
      display: block;

      .g-required {
        display: block;
      }
    }

    &:checked ~ .checkmark {
      background-image: url(/img/check.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
    }

    :checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 24px;
    width: 24px;
    background: #FFFFFF;
    box-shadow: 0 1px 4px rgba(105, 43, 142, 0.28);
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
    }
  }

  .redis-reg {
    &.show-placeholder {
      &:after {
        content: attr(placeholder);
        position: absolute;
        top: 12px;
        z-index: 99;
        left: 20px;
        color: #b7b3dc;
        width: 60%;
        text-align: left;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }


  &__input {
    width: 100%;
    box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    -webkit-box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    -moz-box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    border-radius: 5px;
    text-indent: 20px;
    padding: 14px 20px 13px 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #B7B3DC;
    box-sizing: border-box;
    border: 1px solid rgba(146, 127, 191, 0.4);

    &::placeholder {
      color: #B7B3DC;
    }

    &.birthday-element {
      text-indent: 10px;
      //padding-right: 10px;
      height: 48px;
      width: 160px;
      padding: 11px 10px 11px 0;
      background-color: #FFFFFF;

      &::-webkit-calendar-picker-indicator {
        background-image: url(/img/birthday.svg);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 18px;
      }
    }
  }

  &__btn {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: auto;

    button {
      background: #09AF48;
      border-radius: 80px;
      border: none;
      padding: 16px 50px;
      color: #FFFFFF;
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      min-width: 192px;
    }
  }

  &__step-2 {
    position: relative;

    .bonus-wrap {
      margin-bottom: 2px;
      display: none;
      box-shadow: unset;

      &.dep_2 {
        box-shadow: unset;
      }
    }

    .dep_1 {
      background: #F6F3FE;
    }

    .dep_2 {
      background: #F6F3FE;
      box-shadow: 0 9px 13px 3px rgba(82, 44, 129, 0.18) !important;
    }

    .bonus-other {
      background: #F6F3FE;
      box-shadow: unset;
    }
  }

  &__terms {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #7E6294;
  }

  .code-style {
    display: flex;
    width: 100%;
    justify-content: center;

    &__btn-code {
      font-weight: bold;
      font-size: 11px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #6443B3;
      width: fit-content;
      border-bottom: 1px dashed #6443B3;
    }
  }

  &__select {
    width: 100%;
    height: auto;
  }

  .register-page {
    .register-style {
      &:before {
        content: none;
      }
    }
  }

  &__step {
    display: block !important;
    opacity: 1;
    height: auto;
    visibility: visible;
    

    &.dep-section {
      .register-style {
        background: none;
      }

      // &.is-hide {
      //   display: none;
      // }
    }
  }
}

.form-control {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 112;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; //very important shit for blur input on iOS

  &.login-eye {
    top: 23px;
  }

  &.is-left {
    right: auto;
    left: 0;
  }

  &.is-disabled {
    opacity: .5;
  }

  &.is-currency {
    font-weight: 900;
    font-size: 20px;
    color: #BFB5C8;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &.icon-password-on {
    background-image: url( $cdn + "/alf/registration-page-bg/password_open.svg" );
  }

  &.icon-password-off {
    background-image: url( $cdn + "/alf/registration-page-bg/password_close.svg" );
  }
}
