$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

/*
XL - browser viewport 1441 and more, site width fix 1420 px
L -  browser viewport 1440-1241, site width fix 1180 px
M - browser viewport 1240-1001, site width fix 940 px
S - browser viewport 1000-768, site width fix 700 px
XS - browser viewport 767-541, site width fix 500 px
XXS - browser viewport 540 and less, min site width stretch  320 px
*/


/* break points*/
$xl: 1440px;
$l: 1240px;
$m: 1024px;
$s: 768px;
$xs: 540px;
$xxs: 320px;


@mixin xxs {
  @media only screen  and (max-width: #{$xs - 1px}) {
    @content;
  }
}
@mixin xs {
  @media screen  and (max-width: #{$s - 1px}) {
    @content;
  }
}
@mixin s {
  @media  screen  and (max-width: #{$m - 1px}) {
    @content;
  }
}
@mixin m {
  @media screen and (max-width: #{$l - 1px}) {
    @content;
  }
}
@mixin l {
  @media screen and (max-width: #{$xl - 1px}) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}





@mixin min-xs {
  @media only screen  and (min-width: #{$xs}) {
    @content;
  }
}
@mixin min-s {
  @media screen  and (min-width: #{$s}) {
    @content;
  }
}
@mixin min-m {
  @media  screen  and (min-width: #{$m}) {
    @content;
  }
}
@mixin min-l {
  @media  screen  and (min-width: #{$l}) {
    @content;
  }
}
@mixin min-xl {
  @media  screen  and (min-width: #{$xl}) {
    @content;
  }
}
