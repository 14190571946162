$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.funid {

  &__button {
    box-sizing: border-box;
    min-width: 250px;
    height: 48px;
    margin: 0 auto 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    border: 2px solid #DEDDEB;
    background-color: white;

    &:hover {
      background-color: #3F38DD;
      & span {
        color: white;
      }
    }

    &:after {
      content: "";
      background-image: url(/joxi/fun-id/funid_logo.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 2px;
      border-radius: 50%;
    }
  }

  &__button-text {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: #6443B3;
    z-index: 1;
    padding: 0 35px;
    white-space: nowrap;
  }
}
