$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.seasonal-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: #431584;
    background-image: var(--xxs-bg);
    background-size: 100% 100%;
    background-position: top;
    border-radius: 10px;
    text-align: center;
    transition: .3s;

    &:hover {
        background-size: 110% 110%;
        opacity: .8;
    }

    @include min-xs {
        background-image: var(--xs-bg);
    }

    @include min-s {
        background-image: var(--s-bg);
    }

    @include min-m {
        background-image: var(--m-bg);
    }

    &__text {
        font-size: 44px;
        line-height: 54px;
        font-weight: 900;
        color: #ffffff;
        text-shadow: 0 5px 1px #520A7D;

        @include min-xs {
            max-width: 340px;
            min-width: 340px;
            font-size: 47px;
            line-height: 57px;
        }
    }
}