$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.exchanger{
  display:block;
  padding:20px 20px;
  position:relative;
  &__wrap{
    display:flex;
    position:relative;

  }
  &__item{
    width:80px;
    flex-grow: 1;
  }
  &__arr{
    min-width: 70px;
    width: 70px;
    display:flex;
    & svg{
      margin: 40px auto 0;
      width: 24px;
      height: 21px;
      fill: #949494;
    }
  }
  &__label{
    color: #545454;
    font-size: 11px;
    font-weight: 700;
  }
  &__box{
    padding-top:10px;
    position:relative;
    //-border:solid 1px red;
  }
  &__input{
    height:50px !important;
    line-height: 50px !important;
    color: #9b9b9b !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  & div.exchanger__input{
    padding:0 5px;
    text-align: center;
    overflow: hidden
  }
  &__value{
    color: #949494;

    font-size: 21px;
    font-weight: 500;
    text-transform: uppercase;

    height: 50px;
    line-height: 50px;

  }
  &__btns{
    text-align: center;
    padding-top:30px;
    position:relative;
  }

  @include xs{
    padding:20px ;
    &__input{ font-size:13px !important; }
  }
}
