$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  max-height: 100%;
  overflow-y: auto;
  background: rgba(56, 32, 95, 0.8);
  &__wrap {
    min-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
  }
  &__content {
    position: relative;
    margin: 50px 0;
    background: #fff;
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.3);
    border-radius: 5px;
    padding: 40px 40px;
    box-sizing: border-box;
    overflow: hidden;
    &.is-autobonus {
      margin: auto;
      padding: 30px 22px;
    }
    &.is-login-bank {
      padding: 0;
    }
    &.is-cashbox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 560px;
      min-height: 450px;
      width: 100%;
      padding: 16px 8px 24px;
    }
  }
  &__small {
    width: 300px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 15px;
  }
  &__title {
    color: #666666;
    font-size: 21px;
    font-weight: 400;
    max-width: calc(100% - 16px);
    overflow: hidden;
  }
  &__subtitle {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;

    color: #353535;

    padding-top: 5px;
    margin-bottom: 20px;
  }
  &__close {
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;

    & svg,
    & img {
      width: 16px;
      height: 16px;
      fill: #666666;
    }
    &_white {
      margin-top: 46px;
      & svg,
      & img {
        fill: #ffffff;
      }
    }
  }
  &__footer-text {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    &.is-underline {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
      text-decoration: none;
      }
    }
  }

  .cancel-reg {
    width: 100%;
    max-width: 380px;
    min-height: 348px;
    margin: 50px 16px;
    position: relative;
    background-image: url($cdn + '/alf/registration-page-bg/cancel-reg-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }

  .reg-close {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .cancel-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.24px;
    color: #666666;
    padding-bottom: 16px;
  }

  .cancel-subtext {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #353535;
  }

  .cancel-btn {
    background: #09af48;
    border-radius: 80px;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    width: fit-content;
    padding: 16px 50px;
    margin: auto auto 0;
  }

  .cancel-btn__succ {
    width: fit-content;
    margin: 16px auto 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.12px;
    text-decoration-line: underline;
    color: #666666;
  }
}
