$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.landing-promo {
  background: url('/img/home-dep-wave.svg') 50% calc(100% - 20px) no-repeat;
  padding: 0 25px;
  &__top {
    max-width: 1021px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 0 0 0;
  }
  &__desc {
    width: 50%;
    min-width: 394px;
  }
  &__stone {
    padding-left: 50px;
    width: 49%;
  }
  &__stone-wrap {
    height: 320px;
    background: url('/img/home-dep-bonus_stone.svg') 0 50% no-repeat;
    background-size: 420px;
  }
  &__stone-text {
    padding: 19px 0 0 105px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    & .strong {
      font-size: 72px;
    }
  }

  &__title {
    color: #525252;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
  &__text {
    padding: 22px 0;
    color: #292929;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.14px;
  }

  &__steps {
    padding: 0 0 122px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 965px;
    margin: 0 auto;
  }
  &__step {
    position: relative;
    width: 200px;
    margin: -20px 70px 0 12px;
  }
  &__step-text {
    padding-top: 13px;
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.12px;
  }
  &__step-title {
    padding-top: 20px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.2px;
  }

  &__ico {
    text-align: left;
    & svg {
      width: 130px;
      max-width: 130px;
      max-height: 102px;
    }
  }

  &__btn-box {
    display: flex;
  }

  @include m {

    &__stone-wrap {
      background-size: 360px;
    }
    &__stone-text {
      font-size: 20px;
    }
    &__title {
      font-size: 24px;
    }
    &__text {
      font-size: 12px;
    }
    padding: 0 25px 0 40px ;
  }
  @include s {
    &__top {
      padding: 50px 0 0 30px;
    }
    &__btn-box {
      justify-content: flex-start;
    }
    &__desc {
      min-width: 50%;
    }
    &__stone {
      padding-left: 0px;
    }
    &__stone-wrap {
      width: 240px;
      margin: 0 auto;
      background-size: 240px;
      background-position: top left;
      height: 250px;
    }
    &__stone-text {
      font-size: 16px;
      padding: 20px 0 0 60px;
      & .strong {
        font-size: 39px;
      }
    }
    &__title {
      font-size: 21px;
      letter-spacing: 0.21px;
    }
    &__steps {
      padding: 20px;
      justify-content: flex-start;
    }
    &__step-text {
      font-size: 11px;
    }
    &__step-title {
      font-size: 16px;
      padding-top: 8px;
    }
    &__step {
      margin: 0 40px 0 12px;
    }
    &__ico svg {
      width: 100px;
      max-width: 100px;
      max-height: 100px;
    }
    padding: 0 25px 0 0;
  }

  @include xs {
    background-size: 1400px;
    &__stone {
      display: none;
    }
    &__top {
      padding: 0 ;
    }
    &__btn-box {
      justify-content: center;
    }
    &__desc {
      padding-top: 0;
      width: 100%;
    }
    &__text {
      padding: 20px 0;
    }
    &__steps {
      padding: 0;
      display: block;
    }
    &__step {
      width: 100%;
      margin: 20px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__step-title {
      width: auto;
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 700;
      padding-left: 30px;
    }
    &__step-text {
      padding-top: 5px;
      line-height: 16px;
      font-size: 12px;
      width: 100%;
    }
    &__content {
      padding-left: 20px;
    }
    &__ico {
      width: 100px;
      min-width: 100px;
      text-align: center;
      & svg {
        width: 75px;
        height: 78px;
      }
    }
    padding: 0 25px 70px;
  }
  @include xxs {
    &__content {
      //padding-left: 10px;
    }
    &__ico {
      width: 80px;
      min-width: 80px;
      text-align: center;
      & svg {
        width: 75px;
        height: 78px;
      }
    }
    &__step {
      margin: 0;
    }
    padding: 0 15px 70px;
  }
}
