$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;



.cashbox-balance{
  padding-top:10px;
  &__list{
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    display:inline-flex;
    max-width: 100%;

    margin:0 auto;
  }
  &__item{
    padding:40px;
    width: 25%;
    box-sizing: border-box;
    overflow: hidden;
    &:not(:first-child){ border-left: solid 1px #e9e9e9;}
  }

  &__title{
    color: #292929;
    font-size: 14px;
    font-weight: 400;
  }

  &__sum{
    padding-top:25px;
    color: #6443b3;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    line-height:1em;
    & sup {
      font-size: 35%;
      line-height: 3em;
    }
    &.is-crab{
      display: flex;
      align-items: center;
      & svg{
        margin-left:4px;
        width: 22px;
        height: 22px;
        fill: #6443B3;
      }
      &.is-disabled{
        color: #D0D0D0;
        & svg{ fill: #D0D0D0;}
      }
    }
  }

  &__wager{
    padding-top:15px;
    &-key{
      color: #838383;
      font-size: 12px;
      font-weight: 400;
    }
    &-val{
      color: #292929;
      font-size: 12px;
      font-weight: 500;
      padding-left:6px;
    }

    &-progress{
      padding-top:15px;
    }
    &-str{
      width:100%;
      height:4px;
      border-radius: 3px;
      background-color: #e9e9e9;
      position:relative;
    }
    &-active{
      position:absolute;
      top:0;
      left:0;
      height:4px;
      border-radius: 3px;
      background-color: $violetD;
    }
  }
  &__link{
    padding-top:15px;
    & a{
      color: #545454;
      font-size: 12px;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__refresh{
    cursor:pointer;
    padding-top:20px;
    display:inline-flex;
    align-items: center;
    &-ico{
      width:20px;
      height:20px;

      &.active{ animation: rotate360 0.6s infinite linear; }
    }
    & svg{
      width:20px;
      height:20px;
      fill:$violet;
    }
    &-text{
      color: #838383;
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      padding-left:10px;
    }
  }
  @include l{
    &__sum{ font-size:32px;}
  }
  @include m{
    &__item{
      padding:40px 30px;
    }
  }

  @include s{
    &__list{
      flex-wrap: wrap;
      width: 100%;
    }
    &__item{
      padding: 30px 20px;
      width: 50%;
      &:nth-child(3){
        border-left: none;
        border-top: solid 1px #e9e9e9;
      }
      &:nth-child(4){
        border-top: solid 1px #e9e9e9;
      }
    }
    &__sum{
      font-size:22px;
      &.is-crab svg{
        width: 16px;
        height: 16px;
      }
    }
  }

  @include xs{
    &__list{
      display:flex;
      flex-direction: column;
      margin:0 auto;
      width: 290px;
      max-width: 100%;
    }
    &__item{
      width:290px;
      max-width: 100%;
      text-align: center;
      &:not(:first-child){
        border-left:none;
        border-top: solid 1px #e9e9e9;

      }
    }
    &__sum.is-crab{
      justify-content: center;
    }
    &__wager-progress{
      width:150px;
      margin: 0 auto;
    }
  }
}

@keyframes rotate360 {
  100%{ transform: rotate(360deg);}

}