$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
// GRID
.dashboard{
  display:flex;
  flex-wrap: wrap;
  margin:0 -10px;
  padding:10px 0;
  &__item{
    height:200px;
    position:relative;
    width:calc( 25% - 22px);
    margin:10px;
    border-radius: 10px;
    overflow:hidden;
  }
  @include s{
    margin:0 -5px;
    &__item{
      width:calc( 33.334% - 12px);
      margin:5px;
    }
  }
  @include xs{
    &__item{ width:calc( 50% - 12px); }
  }
  @include xxs{
    &__item{ width:calc( 100% - 12px); }
  }

  .seasonal-banner {
    @include min-s {
      background-image: var(--xs-bg);
    }

    .seasonal-banner__text {
      min-width: unset;
      max-width: unset;
      font-size: 44px;
      line-height: 54px;

      @include min-xs {
        font-size: 22px;
        line-height: 32px;
        min-width: 150px;
        max-width: 150px;
      }
    }
  }
}

@supports ( display: grid ) {
  .dashboard {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 20px;

    //Return after Santa's slope event
    // padding-bottom: 50px;
    
    margin:0 !important;
    &__item {
      background: $violetEEL;
      border-radius: 10px;
      overflow: hidden;
      width: auto !important;
      height: auto !important;
      margin:0 !important;
      &_position{
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
      &_1 {
        grid-row-start: 1;
        grid-row-end: 6;
        grid-column-start: 1;
        grid-column-end: 9;
      }
      &_2 {
        grid-row-start: 6;
        grid-row-end: 13;
        grid-column-start: 1;
        grid-column-end: 9;
      }
      &_3 {
        position: relative;
        padding-top: 100%;
        grid-row-start: 1;
        grid-row-end: 5;
        grid-column-start: 9;
        grid-column-end: 13;
      }

      &_4 {
        grid-row-start: 5;
        grid-row-end: 9;
        grid-column-start: 9;
        grid-column-end: 13;
      }
      &_5 {
        grid-row-start: 1;
        grid-row-end: 9;
        grid-column-start: 13;
        grid-column-end: 21;
      }

      &_6 {
        grid-row-start: 9;
        grid-row-end: 13;
        grid-column-start: 9;
        grid-column-end: 15;
      }
      &_7 {
        grid-row-start: 9;
        grid-row-end: 13;
        grid-column-start: 15;
        grid-column-end: 21;
      }
    }
    @include l {
      grid-gap: 10px;
    }
    @include s {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(8, 1fr);
      &__item {
        &_1 {
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 4;
        }
        &_2 {
          grid-row-start: 1;
          grid-row-end: 3;
          grid-column-start: 4;
          grid-column-end: 7;
        }
        &_3 {
          grid-row-start: 3;
          grid-row-end: 5;
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &_4 {
          grid-row-start: 5;
          grid-row-end: 7;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_5 {
          grid-row-start: 3;
          grid-row-end: 7;
          grid-column-start: 3;
          grid-column-end: 7;
        }

        &_6 {
          grid-row-start: 7;
          grid-row-end: 9;
          grid-column-start: 1;
          grid-column-end: 4;
        }
        &_7 {
          grid-row-start: 7;
          grid-row-end: 9;
          grid-column-start: 4;
          grid-column-end: 7;
        }
      }

    }
    @include xxs {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 288px 390px 1fr 2fr 1.3fr 1.3fr;
      &__item {
        &_1 {
          grid-row-start: 1;
          grid-row-end: 2;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_2 {
          grid-row-start: 2;
          grid-row-end: 3;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_3 {
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 2;
        }
        &_4 {
          grid-row-start: 3;
          grid-row-end: 4;
          grid-column-start: 2;
          grid-column-end: 3;
        }
        &_5 {
          grid-row-start: 4;
          grid-row-end: 5;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_6 {
          grid-row-start: 5;
          grid-row-end: 6;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_7 {
          grid-row-start: 6;
          grid-row-end: 7;
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}