$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.footer{
  display:block;
  background:#ecebf6;
  padding:15px 0;
  width: 100%;
  &__container{
    padding-top:20px;
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__text-block{
    width: 50%;
    color: #626262;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    & a{
      color: #626262;
      text-decoration: underline;
    }
  }
  &__text {
    margin-top: 0;
    margin-bottom: 20px;
  }
  &__payments{
    width: 50%;
  }
  &__bottom{
    padding-top:10px;
    width:100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__copyright{
    color: #626262;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align:right;
  }
  &__lang{
    margin-right: auto;
  }
  &__lang-btn{
    display:block;
    width:40px;
    height: 40px;
    transition:0.3s;
    border-radius:50%;
    padding:4px;
    &:hover{ background: #bbb; }
    & img{
      width: 100%;
      height: 100%;
      font-size: 10px;
      word-break: break-all;
    }
  }
  &__socials{
    margin-right: auto;
    margin-left: auto;
  }
  @include m {
    &__container {
      justify-content: space-between;
    }
    &__text-block {
      width: 450px;
    }
    &__payments {
      width: 350px;
    }
    &__lang{
      min-width: auto;
      margin-right: auto;
    }
    &__copyright{
      min-width: auto;
    }
    &__socials{
      margin-right: 20px;
    }
    &__bottom{
      justify-content: flex-end;
    }     
  }
  @include s{
    &__container {
      align-items: center;
    }
    &__text-block {
      width:100%;
      min-width: 100%;
      margin-bottom: 10px;
    }
    &__bottom{
      max-width:245px;
      padding-top:0;
      flex-direction: column;
      margin-bottom: 10px;
    }
    &__payments {
      width: 320px;
    }
    &__copyright{ margin-top:10px;}
    &__socials{
      margin: 10px auto 0;

    }
    &__copyright{
      min-width:auto;
      text-align: center;
    }
    &__lang{
      min-width:auto;
      margin: auto;
    }
  }
  @include xs{
    &__payments {
      width: 235px;
    }
    &__bottom {
      width: 203px;
    }
    &__copyright{
      font-size: 10px;
    }
    &__copyright,
    &__socials {
      margin-top: 20px;
    }
  }
  @include xxs{
    &__container {
      justify-content: center;
    }
    &__bottom{ width:100%; min-width: 100%; padding-top:5px;}
  }

}


.footer-license{
  display:flex;
  justify-content: flex-end;
  align-items: center;
  margin:0 -15px;
  max-width: calc(100% + 30px);
  &__item{
    flex: 0 0 auto;
    margin:0 15px;
    display:block;
    &_1{ width: 70px; height: 32px; }
    &_2{ width: 70px; height: 24px; }
    &_3{ width: 36px; height: 36px; }
    &_4{ width: 34px; height: 34px; }
    &_5{ width: 28px; height: 34px; }
    &_6{ width: 46px; height: 34px; }
    &_7{ width: 25px; height: 34px; }
    & svg{
      width: 100%;
      height: 100%;
      fill:#2b3894;
    }
    & img{
      height:100%;
      width:auto;
    }
    &.is-igate {
      background-image: var(--icon-igate);
    }
    &.is-gcb {
      background-image: url('/img/gcb.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &.is-pagcor {
      background-image: url($cdn + '/footer-icons/pagcor-seal.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @include xxs{
    justify-content: flex-start;
  }
  &__vegas {
    margin:0 15px;
  }
}





.footer-social{
  display:flex;
  align-items: center;
  justify-content: center;

  &__item{
    display:flex;

    &:not(:last-of-type) {
      margin-right: 25px;
    }

    & svg{
      margin:auto;
      fill: #525252;
      transition:0.3s;
    }
    &_inst {
      & svg {
        width: 17px;
        height: 17px;
      }
      &:hover svg{ fill:#4267b2; }
    }
    &_fb{
      & svg {
        width: 10px;
        height: 18px;
      }
      &:hover svg{ fill:#E5247D; }
    }
    &_tw {
      & svg {
        width: 18px;
        height: 15px;
      }
      &:hover svg{ fill:#1da1f2; }
    }    
  }
}
