$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    width: 100px;
  }
}
