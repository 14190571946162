$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.hero-table {
  display: flex;

  &__label {
    min-width: 100px;
    max-width: 100px;
    &-header {
      height: 100px;
    }

    &-item:not(:first-child) {
      border-top: solid 1px #e9e9e9;
    }

    &-item {
      height: 45px;
      font-size: 10px;
      font-weight: 400;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }

  &__content {
    max-width: calc(100% - 100px);
    flex-grow: 1;
    display: flex;
  }

  &__item {
    width: 20%;
    min-width: 106px;
    padding: 0 3px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  &__ico {
    border-radius: 50%;
    box-sizing: border-box;
    width: 75px;
    height: 75px;
    display: flex;

    &.active {
      border: solid 2px $violet;
    }

    &-w {
      width: 60px;
      height: 60px;
      box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
      border-radius: 50%;
      margin: auto;
      overflow: hidden;
      cursor: pointer;

      &.active {
        box-shadow: none;
      }

      background-size: 500% !important;

      &.level1 {
        background-position: 0 50% !important;
      }

      &.level2 {
        background-position: 25% 50% !important;
      }

      &.level3 {
        background-position: 50% 50% !important;
      }

      &.level4 {
        background-position: 75% 50% !important;
      }

      &.level5 {
        background-position: 100% 50% !important;
      }
    }
  }

  &__level {
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 10px;
    font-weight: 700;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__i {
    &:not(:first-child) {
      border-top: solid 1px #e9e9e9;
    }

    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 400;
    margin: 0 -3px;
  }
}
