$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-balance{
  display: flex;
  align-items: center;
  color: #262626;
  background-color: #FFF;
  border-top: solid 1px #F3F3F3;
  height: 36px;
  padding:0 20px;


  &::after{
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: var(--icon-crab);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left:4px;
  }
  &__key{
    font-size: 12px;
    line-height: 120%;
  }
  &__val{
    font-size: 16px;
    line-height: 120%;
    margin-left:8px;
    font-weight: 800;
  }
}