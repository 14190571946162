$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.eventlist{
  width:180px;
  margin:0 auto;
  height:100%;
  overflow:hidden;
  display:block;
  &__wrap{
    max-height: 100%;
    overflow-y: scroll;
    width:calc( 100% + 17px);
  }
  &__item-wrap{
    display:flex;
    align-items: center;
    margin:7px 0;
  }


  &__item.ng-move,
  &__item.ng-enter,
  &__item.ng-leave {
    transition:all 0.5s;
  }


  &__item.ng-enter {
    margin-top:-50px;
    opacity:0;
  }
  &__item.ng-enter.ng-enter-active {
    margin-top:0;
    opacity:1;
  }
  &__item.ng-leave{
    opacity:1;
  }
  &__item.ng-leave.ng-leave-active {
    opacity:0;
  }



  &__ico-wrap{
    width:70px;
    min-width:70px;
    height:70px;
    border-radius: 50%;
    background: $violet;
    padding:5px;
    box-sizing: border-box;
  }
  &__ico{
    width:60px;
    height:60px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__content{
    padding-left:17px;
    font-size: 11px;
    font-weight: 300;
    color:#fff;
    line-height: 1.3em;
    width:110px;
    box-sizing: border-box;
    overflow: hidden;
    & a{
      color: $blue;
      &:hover{ text-decoration: underline}
    }
    & span.blue{
      color: $blue;
    }
  }

}