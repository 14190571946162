$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
body.is-anim {
  .halloween.is-bonus {
    display: block !important;
  }

  .halloween.is-slider {
    overflow-y: auto;
  }
}

.halloween-alf {

  .halloween.is-bonus {
    display: none;
  }

  .halloween-slider__number {
    @include min-m {
      transform: translateY(-83px);
    }
  }

  .halloween-slider__number.is-prev {
    @include min-m {
      transform: translate(-322px,-83px);
    }
  }

  .halloween-slider__number.is-next {
    @include min-m {
      transform: translate(323px,-83px);
    }
  }

  .swiper-slide-active {
    &.is-yesterday {
      .halloween-slider__number.is-next {
        @include min-m {
          transform: translate(340px,-69px);
        }
      }
    }
  }

  .swiper-slide-active {
    &.is-today {
      .halloween-slider__number {
        @include min-m {
          transform: translateY(-70px);
        }
      }

      .halloween-slider__number.is-next {
        @include min-m {
          transform: translate(323px,-83px);
        }
      }

      .halloween-slider__number.is-prev {
        @include min-m {
          transform: translate(-322px,-83px);
        }
      }
    }
  }

  .swiper-slide-active {
    &.is-next {
      .halloween-slider__number.is-prev {
        @include min-m {
          transform: translate(-340px,-69px);
        }
      }
    }
  }

}

.is-halloween-icon {
  width: 26px;
  height: 26px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}