$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sw-acc-frozen-modal {

  &__title {
    font-weight: 500;
    padding-bottom: 0;
  }

  &__text {
    display: block;
    font-size: 12px;
    line-height: 1.71;
    color: #353535;
    margin-top: 20px;
    text-align: left;
  }

  &__text_red {
    color: #ff7c54;
  }

  &__link {
    color: #3e3e3e;
    font-weight: bold;
    text-decoration: underline;
  }

  &__link_chat {
    font-size: 12px;
    color: #666666;
    text-align: center;
    display: block;
    transition: all 0.2s;

    &:hover {
      color: #949494;
    }
  }

  &__btn.g-btn {
    font-size: 14px;
    min-width: 80%;
    display: block;
    margin: 20px auto 20px;
  }
}