$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.info-popup {
  width: calc(100% - 60px);
  max-width: 900px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 50px;

  color: #292929;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8em;

  &.is-promo {
    @include m {
      padding: 40px 16px;
    }
  }

  &__title {
    color: #26d86a;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4em;
    margin-left: -30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    min-height: 50px;
    padding: 0 0 15px 70px;
  }

  &__content {
    overflow-y: auto;
    flex-grow: 1;
    padding: 0 50px 0 70px;

    & p {
      position: relative;
    }

    & p strong {
      position: absolute;
      left: -50px;
    }
  }

  &__terms {
    width: calc(100% + 28px);
    margin-left: -28px;

    & p>span {
      margin-right: 12px;
      font-weight: 700;
    }

    &.is-responsive-table {
      table {
        width: 100%;
        @extend .g-scroll;

        @include xs {
          display: block;
          overflow-x: auto;
        }
      }
    }

    table {
      padding-bottom: 8px;

      thead {
        tr {
          background: $violetL;
          color: $white;
        }
      }

      tbody {
        td {
          white-space: nowrap;
          padding: 8px;
          border: 1px solid #E9EBF6;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }

  &__hide {
    min-height: 60px;
    box-sizing: border-box;
    padding-top: 20px;
    text-align: center;

    &>.g-btn {
      box-sizing: border-box;
      min-width: 260px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  & h1 {
    font-size: 21px;
    font-weight: 700;
    line-height: 2em;
    color: #545454;
  }

  & h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 2em;
    color: #545454;
  }

  @include l {
    &__header {
      padding: 0 0 15px 40px;
    }

    &__content {
      padding: 0 30px 0 40px;
    }

    &__content p strong {
      left: -30px;
    }
  }

  @include s {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;

    &__title {
      font-size: 24px;
    }
  }

  @include xs {
    &__header {
      padding: 0;
    }

    &__content {
      padding: 0 15px 0 30px;
    }

    &__title {
      font-size: 18px;
      line-height: 1.9em;
    }
  }

  @include xs {
    width: calc(100% - 20px);
    padding: 30px 15px 20px;
  }
}