.easter-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    max-width: 310px;
    position: relative;

    .easter-info & {
        margin: 0 auto 80px;
    }

    .easter-btn {
        white-space: nowrap;
        margin: 16px auto -58px;
    }

    &__content {
        width: 100%;
    }

    &__inner {
        width: 100%;
        opacity: 0.3;
        transition: opacity .2s ease;
    }

    &__not-selected,
    &__selected {
        width: 100%;
    }

    &__selected {
        transform: scale(0);
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    &__num {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 7px;
        text-align: center;
        margin: 0 auto 30px;
        width: 50px;
        height: 52px;
        background-image: url($cdn + '/easter/2023/elements/level-number.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        -webkit-text-stroke: 1px $c-brown;
        text-shadow: 1px 2px 0 $c-brown, -1px 2px 0 $c-brown, 1px 2px 0 $c-brown;
        font-family: $f-ubuntu;
        font-size: 32px;
        line-height: 1em;
        font-weight: 700;
        color: $c-white;
        position: relative;

        .easter-slider__item.is-today & {
            margin: 0 auto 5px;
        }
    }

    &__status {
        position: absolute;
        top: -8px;
        right: -7px;
        width: 22px;
        height: 23px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        .easter-slider__item.is-yesterday &,
        .easter-slider__item.is-previous & {
            background-image: url($cdn + '/easter/2023/elements/status-success.png');
        }

        .easter-slider__item.is-next &,
        .easter-slider__item.is-future & {
            background-image: url($cdn + '/easter/2023/elements/status-locked.png');
        }
    }

    &__gift {
        width: 175px;
        height: 168px;
        margin: 0 auto;
        position: relative;
    }

    &__gift-img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        z-index: 1;
        filter: grayscale(1);

        .easter-info & {
            filter: none;
        }
    }

    &__gift-shine {
        width: 310px;
        height: 295px;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        pointer-events: none;

        .easter-info & {
            display: block;
        }
    }

    &__panel {
        width: 100%;
        position: relative;
        padding: 2px 2px 7px;
        margin-top: -15px;
        background-color: $c-yellow;
        border-radius: 22px;
        z-index: 2;
    }

    &__panel-label {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: -28px;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 11px;
        height: 46px;
        width: 100%;
        text-align: center;
        background-image: url($cdn + '/easter/2023/elements/label.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        font-family: $f-josefin;
        font-weight: 700;
        font-size: 18px;
        line-height: 1em;
        color: $c-light;
        z-index: 2;
    }

    &__panel-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background: linear-gradient(180deg, #F4D165 0%, #F4D165 100%);
        border-radius: 19px;
        position: relative;
        padding: 32px 22px 24px;

        &:after {
            content: '';
            display: block;
            width: 41px;
            height: 18px;
            position: absolute;
            top: 5px;
            left: 5px;
            background-image: url($cdn + '/easter/2023/elements/shine-panel.png');
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
        }

        .easter-info & {
            padding-bottom: 45px;
        }
    }

    &__panel-countdown {
        margin-top: 16px;
    }

    &__panel-title {
        display: block;
        width: 100%;
        white-space: break-spaces;
        font-weight: 800;
        font-size: 24px;
        line-height: 1.25em;
        text-align: center;
        color: $c-violet;
        word-break: break-word;

        &+.easter-box__panel-text {
            margin-top: 8px;
        }
    }

    &__panel-text {
        display: block;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.4em;
        text-align: center;
        color: $c-violet;
    }

    &__panel-info {
        font-weight: 600;
        font-size: 12px;
        line-height: 1.16em;
        text-align: center;
        color: $c-violet;
        margin-top: 8px;
        word-break: break-word;
    }

    &__panel-btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__row {
        width: calc(100% + 20px);
        margin-left: -10px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__row-side {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &:first-child {
            order: 0;
        }

        &:nth-child(2) {
            order: 2;
        }

        &:only-child {
            width: 100%;
        }
    }

    &__row-title {
        width: 100%;
        min-height: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.42em;
        text-align: center;
        color: $c-violet;
        margin-bottom: 8px;
        text-transform: capitalize;

        .easter-box__row-side:only-child & {
            font-size: 18px;
            min-height: 26px;
        }
    }

    &__row-bonus {
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.42em;
        text-align: center;
        color: $c-violet;
        word-break: break-word;

        .easter-box__row-side:only-child & {
            font-size: 20px;
            line-height: 1.2em;
            font-weight: 800;
        }
    }

    &__row-trigger {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
    }

    &__row-trigger-btn {
        display: flex;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        position: relative;
        background-color: $c-silver;
        cursor: pointer;
        outline: none;
        border: none;

        &.is-active {
            &::after {
                transform: scale(1);
                opacity: 1;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
        }

        &::before {
            width: 100%;
            height: 100%;
            top: -2px;
            left: -1px;
            border-radius: 50%;
            background-color: $c-white;
        }

        &::after {
            width: 19px;
            height: 21px;
            top: 1px;
            left: 3px;
            background-image: url($cdn + '/easter/2023/icons/check-circle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: scale(0);
            opacity: 0;
            transition-property: transform, opacity;
            transition-duration: .2s;
        }
    }

    &__row-divider {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 41px;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.1;
        color: rgba(92, 34, 93, .4);
        position: relative;
        flex-shrink: 0;
        margin: 18px 10px 0;

        span {
            height: 14px;
            display: flex;
            align-items: center;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba($c-violet, 0.4);
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    &__info {
        width: 280px;
        margin: 0 auto;
        background-color: $c-violet2;
        border-radius: 0 0 22px 22px;
        padding: 0 2px 7px;
    }

    &__info-inner {
        width: 100%;
        background-color: $c-violet3;
        border-radius: 0 0 19px 19px;
        padding: 37px 11px 17px;
    }

    &__requirements {
        width: 100%;
        margin-bottom: 16px;
        margin-top: 16px;
        transform: scale(0);
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    &__requirements-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px 8px;
        background-color: $c-violet2;
        border-radius: 8px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    body.rtl-lang &__requirements-item-descr {
      margin-right: 0;
    }
    body.rtl-lang &__requirements{
      direction: rtl;
    }

    &__requirements-item-descr {
        margin-right: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4em;
        color: rgba($c-white, 0.6);
        text-align: left;
    }

    &__requirements-item-val {
        font-weight: 600;
        color: $c-white;
        font-size: 12px;
        line-height: 1.4em;
        text-align: right;
        word-break: break-word;
    }

    &__terms-wrap {
        margin-top: auto;
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__terms-trigger {
        margin: 12px auto 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration-line: underline;
        color: $c-white;
        background-color: transparent;
        outline: none;
        border: none;

        .easter-box__terms-wrap & {
            color: $c-violet;
            margin: 0;
        }
    }
}