$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-popup{
  position:relative;
  overflow: visible;
  padding:40px 30px;
  width: 440px;
  max-width: calc(100% - 20px);
  text-align: center;
  &__close{
    position:absolute;
    top: 14px;
    right: 18px;
  }
  & .swiper{
    overflow: hidden;
  }
  &__title{
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color:#666666;
  }
  &__text{
    margin-top:8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #353535;
  }
  &__step{
    margin:24px auto 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 260px;
    width: 100%;
    &.is-1{
      background-image: url( $cdn +  '/crab/brand/alf/cash-crab-popup1.png');
    }
    &.is-2{
      background-image: url( $cdn + '/crab/brand/alf/cash-crab-popup2.png');
    }
  }
  &__row{
    margin-top:24px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.is-sm{
      height: 162px;
    }
  }
  &__item{
    width: 110px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #666666;
  }
  &__symbol{
    font-size: 28px;
    line-height: 40px;
    color: #262626;
    margin:0 28px;
  }


  &__btns{
    margin-top:34px;
  }
  &__btn{
    min-width: 170px;
  }

  &__dots{
    position:absolute;
    bottom: -24px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    & .swiper-pagination-bullet{
      background-color: #FFF;
      margin:0 6px;
    }
  }
}