$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar-links {
  width: 210px;
  margin: 0 auto;
  transition: 0.3s;
  padding-top: 20px;
  &.user {
    display: none;
  }
  &__item {
    padding: 5px 0;

    &.is-event-menu-item {
      border-top: 1px solid #ffffff40;
      padding-block: 15px 5px;
      margin-block: 15px 0;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    transition: 0.3s;

    & svg {
      display: none;
      fill: #fff;
      vertical-align: middle;
      margin-right: 10px;
    }

    &_green {
      background: $greenD;
      & svg {
        width: 30px;
        height: 20px;
      }
      &.active,
      &:hover {
        background: $green;
      }
    }
    &_empty {
      border: solid 1px #fff;
      background: #fff;
      color: #2a134a;
      & svg {
        width: 24px;
        height: 20px;
        fill: #2a134a !important;
      }
      &.active,
      &:hover {
        color: #fff;
        background: none;
        & svg {
          fill: #fff !important;
        }
      }
    }

    &_red {
      background: $red;
      & svg {
        width: 22px;
        height: 22px;
      }
      &.active,
      &:hover {
        background: #fd8976;
      }
    }
  }
}
@include min-s {
  html:not(.menu-open) {
    .sidebar-links {
      width: 52px;
      padding-top: 0px;

      &__btn {
        border-radius: 15px;
        & svg {
          margin: 0;
          display: block !important;
        }
        &_empty:hover svg {
          transition: 0.3s;
          fill: #2a134a;
        }
      }
    }
  }
}

.pay-n-play .sidebar-links__btn_green {
  background: transparent;
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid currentColor;
  & svg {
    fill: currentColor;
  }
  @media (min-width: 1024px) {
    &:hover {
      color: #fff;
    }
  }
}
