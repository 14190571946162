$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.pays {
  width: 100%;

  &_footer {
    max-width: 350px;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 5px;

    .pays_footer & {
      width: 33.3332%;
      min-width: 80px;
      padding: 0 5px 15px 0;
    }
  }

  & img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    font-size: 10px;
    word-break: break-all;
  }

  @include xs {
    &__item {
      .pays_footer & {
        min-width: 78px;
        padding: 0 5px 10px 0;
      }
    }
  }
}
