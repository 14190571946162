$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.pay-n-play {

  .main-banner {
    &__container {
      margin-top: 0;
      height: 250px;
    }
    &__wrap {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20px;
    }
    &__pays-bankid {
      margin-bottom: 60px;
      @include xxs {
          margin-bottom: 14px;
      }
    }
  }

}