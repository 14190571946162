.easter-countdown {
    width: 100%;
    text-align: center;

    &__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 1em;
        color: rgba($c-white, 0.6);
        margin-right: 2px;

        .easter-box__panel-countdown & {
            display: none;
        }
    }
    body.rtl-lang &__time {
      display: block;
      margin-right: 8px;
    }
    body.rtl-lang &__title {
      direction: rtl;
    }
    body.rtl-lang & {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
    }
  
    &__time {
        font-weight: 700;
        font-size: 14px;
        line-height: 1em;
        text-align: center;
        color: $c-light2;

        .easter-box__panel-countdown & {
            font-size: 24px;
            line-height: 1.15em;
            color: $c-violet;
            font-weight: 800;
        }
    }
}