$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar-tab {
  position: relative;
  padding: 5px 0;
  height: 70px;
  min-height: 70px;
  box-sizing: border-box;
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__bg {
    position: absolute;
    left: calc(50% - 35px);
    top: 9px;
    width: 70px;
    height: 53px;
    background: #9a8fa9;
    overflow: hidden;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 104px;
      height: 78px;
      background: #341f53;
      border-radius: 100%;
      left: -4px;
      transition: 0.3s;
    }
    &:before {
      bottom: 37px;
    }
    &:after {
      top: 37px;
    }
    &.right:before,
    &.right:after {
      left: -28px;
    }
  }

  &__item {
    width: 70px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      max-width: 17px;
      max-height: 17px;
      width: 17px;
      height: 17px;
      transition: 0.3s;
      fill: #949494;
    }
    &.active {
      width: 53px;
      height: 53px;
      & svg {
        max-width: 30px;
        max-height: 30px;
        width: 30px;
        height: 30px;
        fill: #6443b3;
      }
    }
  }
  &__shadow {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 15px;
  }
  @media screen and (max-height: 680px) {
    display: none !important;
  }
}
