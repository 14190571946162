$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-popup {
  &__steps {
    margin-left: -30px;

    &-list {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      padding-bottom: 20px;
      padding-left: 4px;
    }

    &-title {
      font-weight: 700;
      color: #26d86a;
      font-size: 30px;
      line-height: 22px;
      max-width: calc(100% - 16px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 20px;

      @include s {
        font-size: 24px;
      }

      @include xs {
        font-size: 18px;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 32px 1fr 10px;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
      border-radius: 8px;
      padding: 12px;
      grid-gap: 12px;
      transition: 0.2s;

      &.has-no-action {
        pointer-events: none;
      }

      &:hover {
        background: #ecebf6;
        box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
      }
    }

    &-number {
      border: 2px solid #666666;
      color: #666666;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    &-subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      color: #6443b3;
      padding-bottom: 4px;
    }

    &-description {
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #666666;
    }

    &-arrow {
      width: 6px;
      height: 9px;
      margin-left: auto;
      fill: #666666;
    }
  }

  &__opt-in {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #666666;
  }

  &__opt-in-block {
    margin-bottom: 20px;
    text-align: center;
  }

  &__opt-in-btn {
    display: block;
    max-width: fit-content;
    min-width: 140px;
    margin: 0 auto 20px;
    font-weight: 700;
    text-align: center;
  }

  &__opt-in-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}