$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;


.landing-hero{
  padding-top:90px;
  &__top{ padding-left:30px; }
  &__title{
    color: #525252;
    font-size: 30px;
    font-weight: 700;}
  &__subtitle{
    padding-top:10px;
    color: #525252;
    font-size: 14px;
    font-weight: 500;
  }

  &__slider{ position:relative; }
  &__list{ margin-top:30px; }
  &__item{ padding:0 8px;}
  &__item-wrap{  width:200px; margin:0 auto; }
  &__icon{
    position:relative;
    height:309px;
    //-background-color: #fafafd;
    background-size: 500%;
    background-image: url( $cdn + "/alf/hero-ava/sprite-landing-slider_x2.jpeg" );
    background-position-y: 50%;
    background-repeat: no-repeat;
    border-radius: 10px;
    overflow:hidden;
    &_flamma{ background-position: 0; }
    &_morbi{ background-position: 25%; }
    &_terra{ background-position: 50%; }
    &_tractus{ background-position: 75%; }
    &_liq{ background-position: 100%; }



  }
  &__over{
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:  rgba(45, 23, 78, 0.9);

    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity:0;
    transition:0.3s;
    &_hover:hover{ opacity:1; }
    &_tab{
      display:none;
      .landing-hero__item.active &{ display:flex; opacity:1;}
    }
  }
  &__over-link{
    margin-top:20px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
    &:hover{
      color: #b7b3dc;
    }
  }


  &__name{
    text-align: center;
    color: #292929;
    font-size: 20px;
    font-weight: 700;
    padding:20px 0 10px;
  }
  &__desc{
    padding:0 20px;
    color: #292929;
    font-size: 12px;
    font-weight: 400;
  }

  & .slick-arrow{
    position:absolute;
    top:130px;
    z-index: 52;
    width: 38px;
    height: 38px;
    box-shadow: 0 0 10px rgba(15, 4, 83, 0.35);
    background-color: #ffffff;
    border:none;
    border-radius: 50% ;
    font-size: 0;
    color:#fff;
    cursor:pointer;
  }
  & .slick-prev{ background: #fff url('/img/arr-l-slick.svg') 45% 50% no-repeat ; left: -50px; }
  & .slick-next{ background: #fff url('/img/arr-r-slick.svg') 55% 50% no-repeat ; right:-50px; }

  @include l{
    &__item{ padding:0 ; }
    &__slider{ padding:0 50px;}
    &__title{ font-size: 24px;}
    &__subtitle{ font-size: 14px;}
  }
  @include s{
    padding-top:30px;
    &__top{ padding-left:15px;}
    &__item-wrap{ width:180px;}
    &__title{ font-size: 21px;}
  }
}



/*.landing-hero{
  padding:50px 0;
  text-align: center;
  position:relative;
  &__list{
    padding-top:50px;
    margin:0 -20px;
    //border:solid 1px black;
  }
  &__item{

    box-sizing: border-box;

    &-w{
      padding:10px 8px;
      width:203px;
      margin:0 auto;
      background: lightgreen;

    }
  }
  @include l{
    &__list{
      margin:0 - 10px;
      padding-right:40px;
    }
    &__item{
      padding: 15px 10px;
    }
    &__item-w{
      width:146px;
      margin:0;
    }
  }
  @include xs{
    &__list{
      .landing-hero-pc &{ padding:50px 40px 0 ;}
    }
    &__item-w{ margin:0 auto;}
  }







  &__title{
    color: #525252;
    font-size: 26px;
    font-weight: 500;
  }


  &__ico{
    position:relative;
    width: 203px;
    height: 309px;
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.2);
    background-color: #ffffff;
    //border-radius: 50%;
    overflow: hidden;
    display:flex;
    align-items: center;
    justify-content: center;

    background-size: 500%;
    background-image: url( $cdn + "/alf/hero-ava/sprite-landing-slider.jpg" );


  }
  &__over{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: $violetN;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  &__btn{
    padding:2px 0;
  }



  &__name{
    padding:20px 0;
    color: #292929;
    font-size: 16px;
    font-weight: 700;
  }
  &__desc{
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
  }

  @include l{
    &__ico{
      width:146px;
      height:146px;
    }
    &__over-link{
      height:42px;
      line-height:42px;
      padding:0 15px;
    }
  }
  @include s{
    &__title{
      color: #525252;
      font-size: 19px;
      font-weight: 500;
    }
  }

  &__item:hover .landing-hero__over{ opacity: 1;}
  &__flamma{ background-position: 0; }
  &__morbi{ background-position: 25%; }
  &__terra{ background-position: 50%; }
  &__tractus{ background-position: 75%; }
  &__liq{ background-position: 100%; }

}


//+ .slick-slide
.landing-hero-mob .slick-current  .landing-hero__ico{
  border:solid 5px $violetD;
  box-sizing: border-box;
  width:146px;
  height:146px;
  margin:-6px 0 0 -6px;
  &-w{
    padding: 6px 0 0 0;
  }
  @include xxs{
    width:88px;
    height:88px;
    border-width: 3px;
  }
}


/* mob
.landing-hero-mob .landing-hero{
  &__list{ padding:40px 30px 0;}
  &__item-w{
    width:134px;
    margin: 0 auto;
  }
  &__ico{
    width:134px;
    height:134px;
    &-w{
      padding: 6px 0 6px 0;
    }

  }
  &__btns{
    display:flex;
    justify-content: center;
    padding-top:30px;
  }
  &__btn{
    padding:0 10px;
  }
  @include xxs{
    &__list{ padding:40px 15px 0;}
    &__item{
      width:110% !important;
    }
    &__item-w{
      width:76px;
      margin: 0 auto;
    }
    &__ico{
      width:76px;
      height:76px;

    }
    &__desc{ display:none;}
  }
}

/* ARROWS
.landing-hero{
  & .slick-arrow{
    position:absolute;
    top:115px;
    z-index: 50;
    width: 35px;
    height: 35px;
    box-shadow: 0 0 10px rgba(15, 4, 83, 0.35);
    background-color: #ffffff;
    border:none;
    border-radius: 50% ;
    font-size: 0;
    color:#fff;
    cursor:pointer;
  }
  & .slick-prev{ background: #fff url('/img/arr-l-slick.svg') 45% 50% no-repeat ; background-size: 12px; }
  & .slick-next{ background: #fff url('/img/arr-r-slick.svg') 55% 50% no-repeat ; background-size: 12px; }
}

.landing-hero-pc{
  & .slick-prev{ display:none !important; }
  & .slick-next{ right:0;}
  @include xs{
    & .slick-prev{ display:block !important;  left:10px;}
    & .slick-next{ right:10px;}
  }
}

.landing-hero-mob{

  & .slick-prev{ left:0;}
  & .slick-next{ right:0;}
  & .slick-arrow{ top:110px;}
  @include xxs{
    & .slick-arrow{ top:80px;}
  }
}

*/