$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.app-page{

  &__baner{
    background: url( $cdn + "/alf/info-page-faq-pc.jpg")  no-repeat 50% bottom;
    background-size: cover;
  }
  &__title{
    color: #26d86a;
    font-size: 30px;
    font-weight: 700;
    line-height: 43px;
    margin: 0;
    text-transform: uppercase;
  }
  &__text{
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
  }
  &__wrap{
    min-width: calc(100% - 258px);
  }
  &__logo{
    background: url( $cdn + "/alf/mobile-app.png")  no-repeat 50% bottom;
    background-size: cover;
    width: 220px;
    height: 270px;
    margin-top: 25px;
  }
  &__section-first{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  &__section-next{
    display: flex;
    justify-content:space-between;
    width: 100%;
    padding: 28px 0 0 0;
  }
  &__block{
    border-radius: 5px;
    background-color: #ecebf6;
    width:calc(50% - 10px);
    height: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__text-desc{
    display: none;
  }
  &__qr{
    width: 130px;
    height: 130px;
    background-color: #545454;
    margin-left: 35px;
  }
  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__content-wright{
    order: -1;
    position: relative;
    right: 23px;
    max-width: 40%;
  }
  &__content-left{
    position: relative;
    left: 23px;
  }

  &__content-text{
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.99px;
    //width: 130px;
    padding: 0 0 15px 0;
  }
  &__content-logo{
    width: 82px;
    height: 82px;
    box-shadow: 0 0 20px rgba(100, 67, 179, 0.5);
    border-radius: 15px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg{
      width: 44px;
      height: 53px;
      fill: #b7b3dc;
    }
  }
  &__info-title{
    color: #545454;
    font-size: 21px;
    font-weight: 700;
    padding: 30px 0 15px;
  }
  &__list-block{
    min-width: 16px;
    height: 16px;
    border: 7px solid #ecebf6;
    background-color: #838383;
    border-radius: 10px;
    position: relative;
    top: 5px;
  }
  &__info{
    padding-bottom: 40px;
  }
  &__list{
    display: flex;
  }
  &__list-text{
    color: #292929;
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    padding: 0 13px;
  }

  @include s{
    &__title{
      font-size: 24px;
    }
    &__text{
      font-size: 12px;
      line-height: 24px;
    }
    &__logo{
      width: 178px;
      height: 223px;
      margin: 37px 0 20px;
    }
    &__info-title{
      font-size: 18px;
      padding: 43px 0 15px;
    }
    &__list-text{font-size: 12px;}
    &__text-desc{
      display: block;
      width: 100%;
      text-align: center;
      padding: 0 60px 15px;
      color: #838383;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.99px;
    }
    &__block{
      height: 174px;
    }
    &__content-text{
      display: none;
    }
    &__content-wright{
      order: 0;
      right: 0;
    }
    &__content-left{
      left: 0;
    }
    &__qr{
      width: 80px;
      height: 81px;
      margin-right: 33px;
      margin-left: 0;
    }
    &__item{
      justify-content: center;
    }
  }
  @include xs{
    &__baner{
      background: url( $cdn + "/alf/info-page-faq-mob.jpg")  no-repeat 50% bottom;
      background-size: cover;
    }
    &__title{
      font-size: 18px;
      line-height: 30px;
    }
    &__logo{display: none;}

    &__block{
      width: 100%;
      height: 89px;
      margin-top: 18px;
    }
    &__text-desc{
      width: auto;
      max-width:calc(100% - 153px);
      padding: 0 16px ;
      font-size: 10px;
      line-height: 19.89px;
      letter-spacing: 0.75px;
      overflow: hidden;
    }
    &__item{
      width: 100%;
    }
    &__qr{
      width: 61px;
      height: 61px;
      margin-right: 14px;
      img { height: 100%; }
    }
    &__content-logo{
      width: 62px;
      height: 62px;
      margin-right: 15px;
      & svg{
        width: 36px;
        height: 41px;
      }
    }
    &__section-next{
      flex-direction: column;
      padding: 0;
    }
    &__section-first{
      padding: 37px 0;
    }
  }
}
