$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-settings{
  display:flex;
  align-items: flex-start;
  padding:10px 0;
  &__box{
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    padding:30px 40px 40px;
    //overflow: hidden;
    &:first-child{
      margin-right:20px;
    }
  }
  &__title{
    color: #545454;
    font-size: 16px;
    font-weight: 500;
    padding-bottom:20px;
  }
  @include l{
    &__box{ padding:30px;}
  }
  @include s{
      flex-direction: column;
      align-items: center;

    &__box{
      margin:20px 0;
      padding:30px 25px;
      width:510px;
      box-sizing: border-box;
      &:first-child{ margin:20px 0;}
    }
  }
  @include xs{
    &__box{
      width:300px;
      padding:30px 40px;
    }

  }

}



//.profile-change-pass{ position:relative; display:block;}