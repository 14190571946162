$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.grogress {
  &__str {
    fill: none;
    stroke: #5b5b5b;
    stroke-linecap: round;
  }

  &__value {
    fill: none;
    stroke: #b1f9dc;
    stroke-linecap: round;
  }
}
