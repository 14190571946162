$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cookie-banner {
  position: fixed;
  left: 120px;
  bottom: 0;
  right: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;

  @include xs {
    left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 10px 16px;
    background-image: linear-gradient(0deg, #2F1E4A -0.11%, #39205F 100.22%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    @include xs {
      border-radius: 0;
      width: 100%;
    }
  }

  &__content {
    flex: 1 1 50%;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btn {
    flex-shrink: 0;
    padding: 10px 30px;
    height: auto;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1px;
  }
}
