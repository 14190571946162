$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.g-title {
  color: #525252;
  font-size: 26px;
  font-weight: 500;
}


.g-box {
  box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
  border-radius: 5px;
  background-color: #ffffff;
}

.g-hidden-xs {
  @include xs {
    display: none;
  }
}

.g-hidden-xxs {
  @include xxs {
    display: none;
  }
}

.g-hide-s {
  @include s {
    display: none !important;
  }
}

.g-sprite {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

/*=====================
-------- BTN ----------
=====================*/
.g-btn {
  line-height: 50px;
  height: 50px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 30px;
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  transition: 0.3s;
  overflow: hidden;
  border: none;

  &:hover {

  }

  @include xs {
    padding: 0 30px;
  }

  &-v {
    background-color: $violet;
    color: #fff;

    &:hover {
      background-color: $violetL;
    }
  }

  &-v-link {
    color: #fff;

    &:hover {
      color: $violetD;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(41, 41, 41, 0.2);
    }
  }

  &-w {
    background-color: #fff;
    color: $violet;
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.2);
    font-weight: 600;

    &:hover {
      background-color: #e9e9e9;
      box-shadow: none;
    }
  }

  &-green {
    background-color: $greenD;
    color: #fff;

    &:hover {
      background-color: $green;
    }
  }

  &-16 {
    font-size: 16px;
    letter-spacing: 0.16px;
  }

  &-bold {
    font-weight: 600;
  }

  &-shadow {
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.2);
  }

  &-arr {
    padding-right: 45px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 7px);
      right: 15px;
      width: 16px;
      height: 13px;
      background: url('/img/arr-dots-r.svg');

    }
  }

  &-arrL {
    padding-left: 50px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 7px);
      left: 22px;
      width: 16px;
      height: 13px;
      background: url('/img/arr-dots-v-left.svg');

    }
  }

}


.g-link {
  position: relative;
  display: inline-block;
  color: #292929;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  //padding: 0 20px;

  & svg {
    width: 6px;
    height: 9px;
    fill: $violet;
    margin-left: 7px;
  }

  &-btn {
    height: 50px;
    line-height: 50px;
  }

  &-u {
    text-decoration: underline;
  }

  &-bold {
    font-weight: 700;
  }
}


/*==========================
------- F O R M ------------
==========================*/

.g-formbox-item {
  width: 220px;
  max-width: 100%;
  padding: 10px 0;
  position: relative;

  .registration & {
    width: 100%;
  }

}

.g-formbox-title {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;

  &__text {
    color: #545454;
    font-size: 11px;
    font-weight: 700;
  }

  &__not {
    margin: 2px 0 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #d0d0d0;
    display: none;
  }
}

.g-formbox-link {
  text-align: center;

  & a {
    color: #545454;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    text-decoration: underline;
  }

  & a.strong {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
  }
}

.g-formbox-btn {
  padding-top: 20px;
  text-align: center;
}


/* ---- -- FORM ELEMENTS -- ---- */
.g-input {
  width: 220px;
  max-width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  padding: 0 15px;
  border: solid 1px rgba(146, 127, 191, 0.35);
  box-sizing: border-box;

  background: #fff;
  box-shadow: 0 0 5px rgba(146, 127, 191, 0.35);

  color: #2c2e2e;
  font-size: 13px;
  font-weight: 600;

  &.bank-inp {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding-right: 0 !important;
  }

  &::placeholder {
    color: #d0d0d0;
    font-size: 13px;
    font-weight: 500;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(146, 127, 191, 0.7);
  }

}

.g-select {
  width: 220px;
  max-width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 5px;
  padding: 0 30px 0 15px;
  border: solid 1px rgba(146, 127, 191, 0.35);
  box-sizing: border-box;
  cursor: pointer;
  background: #fff url('/img/arr_select.svg') no-repeat calc(100% - 10px) 50%;
  background-size: 13px;
  box-shadow: 0 0 5px rgba(146, 127, 191, 0.35);
  color: #d0d0d0;

  font-size: 13px;
  font-weight: 600;

  -moz-appearance: none;
  -webkit-appearance: none;
  //text-indent: 15px;

  &:active, &:hover {
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(146, 127, 191, 0.6);
  }

  & option {
    color: #2c2e2e;
    line-height: 130px;
    height: 130px;
    border: none;
    padding: 20px;
    margin: 20px;
  }
}

@-moz-document url-prefix() {
  .g-select {
    text-indent: 15px;
  }
}

.g-input-disable {
  background: #E9E9E9;
  color: #666666;
  box-shadow: none;
  border-color: #E9E9E9;
  cursor: default;
}

.g-check {
  display: none;
}

.g-check-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%
}

.g-check-block {
  position: relative;
  width: 16px;
  height: 16px;
  box-shadow: 0 0 8px rgba(146, 127, 191, 0.6);
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 2px;
}

.g-check:checked + label .g-check-block {
  background: #fff;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //transform: rotate(90deg);
    background: url('/img/check.svg') 50% 50% no-repeat;
    background-size: 75%;
  }
}

.g-check-text {
  cursor: pointer;
  padding-left: 10px;
  line-height: 14px;
  width: calc(100% - 30px);
  color: #545454;
  font-size: 11px;
  font-weight: 500;

  & a {
    color: $violetL;

    &:hover {
      text-decoration: underline;
    }
  }

}

.g-radio {
  display: none;
}

.g-radio-label {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.g-radio-block {
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 100%;
  cursor: pointer;
  margin-top: 2px;
  background: #fff;
  box-shadow: 0 0 5px rgba(146, 127, 191, 0.35);
  box-sizing: border-box;
  border: solid 4px #fff
}

.g-radio:checked + label .g-radio-block {
  background: $violet;
  box-shadow: 0 0 0 2px $violet;
}

.g-radio-text {
  cursor: pointer;
  padding-left: 15px;
  color: #545454;
  font-size: 12px;
  font-weight: 600;
}

.g-radio-disabled {
  cursor: default;
}

.g-textarea {
  border-radius: 5px;
  padding: 10px 15px;
  border: none;
  box-sizing: border-box;

  background: #fff;
  box-shadow: 0 0 5px rgba(146, 127, 191, 0.35);

  color: #2c2e2e;
  font-size: 13px;
  font-weight: 600;
  width: 100%;

  &::placeholder {
    color: #d0d0d0;
    font-size: 13px;
    font-weight: 500;
  }

  &:focus {
    box-shadow: 0 0 5px rgba(146, 127, 191, 0.7);
  }
}

input {
  &[name="pass1"],
  &[name="pass2"],
  &[name="pass3"],
  &[name="password"] {
    padding-right: 46px;
  }
}

/* -------- MOBILE FORM ------------ */

.g-check-mobile {
  min-width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
}

.g-check-mobile-label {
  line-height: 17px;
  font-size: 11px;
  font-weight: 500;
  width: calc(100% - 46px);
}

.g-check-mobile-link {
  color: $violetD;
  text-decoration: underline;
  padding-left: 10px;
  font-size: 12px;
}

label span {
  pointer-events: none;
}

.g-radio-mobile {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.g-radio-mobile-label {
  padding-left: 10px;
  font-size: 12px;
}

/* ---- -- FORM VALIDATION -- ---- */

input.ng-valid.ng-dirty:not(.novalidate) {
  box-shadow: none;
  border: 2px solid $violet;

}

input.ng-invalid.ng-touched:not(.novalidate) {
  box-shadow: none;
  border: 2px solid $red;
}

select.ng-dirty:not(.novalidate) {
  line-height: 28px;
  box-shadow: none;
  border: 2px solid $violet;
  color: #2c2e2e;
  background: #fff url('/img/arr_select.svg') no-repeat calc(100% - 10px) 50%;
  background-size: 10px;

  & option {
    color: $grey;
  }
}

.g-check.ng-invalid.ng-touched + label,
.g-check.ng-invalid.ng-dirty + label {
  & .g-check-block {
    border: solid 2px $red;
    box-shadow: none;
    box-sizing: border-box;
  }
}

.novalidate {
  box-shadow: 0 0 5px rgba(146, 127, 191, 0.35);
  border: none !important;
}


/* ---- -- FORM ERROR -- ---- */

.g-form-error {
  display: none;
  //margin-top: 16px;
  //border-radius: 5px;
  //position: absolute;
  top: 60px;
  color: #FF7C54;
  //background: $red;
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  z-index: 600;

  //&:after {
  //  content: '';
  //  position: absolute; /* Абсолютное позиционирование */
  //  left: calc(50% - 10px);
  //  top: -18px; /* Положение треугольника */
  //  border: 10px solid transparent; /* Прозрачные границы */
  //  border-bottom: 10px solid $red; /* Добавляем треугольник */
  //}

  &_static {
    width: 250px;
    /*    z-index:1200; */
  }

  &:after {
    left: 40px;
  }


  & p {
    margin: 0;
    //color: #fff;
    color: #FF7C54;
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
  }
}

.g-form-error_check {
  background: #fff;
  margin-top: 10px;
  padding: 0;
  top: 20px;
  position: static;

  &:after {
    display: none
  }

  & p {
    margin: 0;
    color: $red;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
  }
}

.g-form-error,
.ng-untouched + .g-form-error,
.ng-valid + .g-form-error,
.datepicker-open + .g-form-error {
  display: none
}

.ng-invalid:focus + .g-form-error {
  display: none !important;
}

.ng-invalid.ng-touched + .g-form-error {
  display: block;
}

.datepicker-open + .g-form-error_inline {
  display: none !important;
}

.ng-invalid.datepicker-open + .g-form-error {
  display: none;
}

.g-check.ng-valid + label + .g-form-error_check {
  display: none;
}

.g-check.ng-pristine + label + .g-form-error_check {
  display: none;
}

.g-check.ng-invalid.ng-dirty + label + .g-form-error_check,
.g-check.ng-invalid.ng-touched + label + .g-form-error_check {
  display: block;
}


.g-minlength {
  display: none;
}

.g-maxlength {
  display: none;
}

.g-pattern {
  display: none;
}

.g-required {
  display: none;
}

.g-min {
  display: none;
}

.g-max {
  display: none;
}

.g-passwordVer {
  display: none;
}

.g-amount {
  display: none;
}

.ng-invalid-minlength + .g-form-error .g-minlength {
  display: block;
}

.ng-invalid-maxlength + .g-form-error .g-maxlength {
  display: block;
}

.ng-invalid-min + .g-form-error .g-min {
  display: block;
}

.ng-invalid-max + .g-form-error .g-max {
  display: block;
}

.ng-invalid-pattern + .g-form-error .g-pattern {
  display: block;
}

.ng-invalid-required + .g-form-error .g-required {
  display: block;
}

.ng-invalid-email + .g-form-error .g-pattern {
  display: block;
}

.ng-invalid:not(.ng-invalid-required)+.g-form-error .g-pattern {
  display: block;
}

.ng-invalid-phone + .g-form-error .g-pattern {
  display: block;
}

.ng-invalid-amount + .g-form-error .g-amount {
  display: block;
}

.ng-invalid-password-verify + .g-form-error .g-passwordVer {
  display: block;
}

.ng-invalid-password-verify + .g-form-error .g-passwordVer {
  display: block;
}

.g-check.ng-invalid-required.ng-dirty + label + .g-form-error_check,
.g-check.ng-invalid-required.ng-touched + label + .g-form-error_check,
.g-check.ng-invalid-required.ng-dirty + label + .g-form-error_check .g-required,
.g-check.ng-invalid-required.ng-touched + label + .g-form-error_check .g-required {
  display: block;
}

.g-check-mobile.ng-invalid-required.ng-dirty + label + .g-form-error_check,
.g-check-mobile.ng-invalid-required.ng-touched + label + .g-form-error_check,
.g-check-mobile.ng-invalid-required.ng-dirty + label + .g-form-error_check .g-required,
.g-check-mobile.ng-invalid-required.ng-touched + label + .g-form-error_check .g-required {
  display: block;
}

.ng-invalid.ng-valid-pattern + .g-form-error .g-pattern {
  display: none
}

.ng-invalid-pattern.ng-valid-email + .g-form-error .g-pattern,
.ng-invalid-pattern.ng-invalid-required + .g-form-error .g-pattern,
.ng-invalid-pattern.ng-invalid-minlength + .g-form-error .g-pattern,
.ng-invalid-pattern.ng-invalid-maxlength + .g-form-error .g-pattern {
  display: none;
}

.ng-invalid-letter + .g-form-error .g-patternLetter {
  display: block;
}

.ng-valid-letter + .g-form-error .g-patternLetter,
.ng-invalid-letter.ng-invalid-pattern + .g-form-error .g-patternLetter,
.ng-invalid-letter.ng-invalid-required + .g-form-error .g-patternLetter,
.ng-invalid-letter.ng-invalid-minlength + .g-form-error .g-patternLetter,
.ng-invalid-letter.ng-invalid-maxlength + .g-form-error .g-patternLetter, {
  display: none;
}

/*   DATEPICKER */
.datepicker-body {
  position: relative;
}

.ui-datepicker {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 300px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 1px 48px -5px rgba(0, 0, 0, 0.65);
  background: #fff;
  z-index: 10000 !important;

  &.registration-picker {
    .ui-datepicker_top .ui-datepicker {
      top: -230px !important;
      bottom: unset !important;
    }

  }

  &_top .ui-datepicker {
    top: -230px !important;
    bottom: unset !important;
    left: -9px !important;

    @include xs {
      left: -5px !important;
    }
  }

  @include xs {
    width: 290px;
    left: -30px !important;
    .profile & {
      left: -35px !important;
    }
  }

  &-header {
    //background:$dark3Pink;
    //background: linear-gradient(to right, rgba(105,51,160,1) 0%, rgba(207,49,120,1) 100%);
    border-radius: 8px 8px 0 0;
    padding: 20px 0;
    position: relative;
    color: #fff;
    display: flex;
  }

  &-title {
    margin: auto;
    display: flex;
  }

  &-year,
  &-month {
    display: block;
    //background:$dark3Pink;
    box-sizing: border-box;
    text-indent: 20px;
    //text-align-last:center;
    cursor: pointer;

    margin: 5px 7px;

    line-height: 40px;
    height: 40px;
    width: 120px;
    background: #ffffff url('/img/arr_select.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 13px;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    border-radius: 10px;
    color: #292929;
    font-size: 12px;
    font-weight: 400;


    & option {
      color: #292929;
    }
  }

  &-prev, &-next {
    display: none;
  }

  &-calendar {
    width: calc(100% - 40px);
    margin: 0 auto 20px;
    padding: 0 0 20px;
    border-radius: 0 0 8px 8px;

  }

  & td {
    padding: 3px;
    text-align: center;
    box-sizing: border-box;
    height: 27px;
    width: 27px;

    & a {
      line-height: 27px;
      color: #292929;
      font-size: 12px;
      font-weight: 500;
      display: block;
      //padding:6px 0;
      border: solid 2px #fff;
      border-radius: 3px;

      &.ui-state-active {
        border: solid 1px $violetL;
        background: $violetL;
        color: #fff;
      }

      &:hover {
        border: solid 2px $violetL;
        font-weight: 700;
        color: $violetL;
      }

    }

  }

  & th {
    //color:$darkPurple;
    //padding-top:10px;
    color: $grey;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-unselectable:hover {
    background-color: none !important;
  }

  .datepicker-body_r & {
    right: 0 !important;
    left: inherit !important;
    @include xs {
      right: -15px !important;
    }
  }

  .datepicker-body_l & {
    left: 0 !important;
    right: inherit !important;
    @include xs {
      left: -15px !important;
    }
  }

  .datepicker-body_top & {
    top: inherit !important;
    bottom: 0 !important;

  }


}

/* SLIDER WINGET */
.slider-widget {

  & .ui-widget.ui-widget-content {
    height: 10px;
    box-shadow: 0 0 8px rgba(146, 127, 191, 0.4);
    border-radius: 5px;
    background-color: #ffffff;
    border: none;
  }

  & .ui-slider-handle {
    top: -.2em !important;
    width: 15px !important;
    height: 15px !important;
    border: 2px solid #6443b3 !important;
    background-color: #ffffff !important;
    border-radius: 50% !important;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 2px);
      left: calc(50% - 3px);
      background: #6443b3;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }

  & .ui-slider-range {
    background: #6443b3;
    border-radius: 5px 0 0 5px;
  }
}


.slider-widget-points {
  height: 20px;
  position: relative;
  margin-top: 20px;

  &__item {
    position: absolute;
    top: 0;
    width: 14px;
    height: 20px;
    //border:solid 1px red;
    &:nth-child(1) {
      left: calc(10% - 7px);
    }

    &:nth-child(2) {
      left: calc(30% - 7px);
    }

    &:nth-child(3) {
      left: calc(50% - 7px);;
    }

    &:nth-child(4) {
      left: calc(70% - 7px);
    }

    &:nth-child(5) {
      left: calc(90% - 7px);
    }

    display: flex;
    justify-content: center;
    color: #666666;


    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;

    &:before {
      content: '';
      top: -30px;
      left: 3px;
      position: absolute;

      width: 6px;
      height: 6px;
      border: 2px solid #b7a8db;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }
}


/*==========================
------- SCROLL -------------
==========================*/

.g-scroll {
  &::-webkit-scrollbar-button {
    background-image: none;
    background-repeat: no-repeat;
    width: 5px;
    height: 0px
  }

  &::-webkit-scrollbar-track {
    background-color: #dbdbdb;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 5px;
    //background: #ffffff;
    background: #927ec1;
  }

  &::-webkit-resizer {
    background-image: none;
    background-repeat: no-repeat;
    width: 6px;
    height: 6px
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }


  &-dark {
    &::-webkit-scrollbar-track {
      background-color: #463462;
    }

    &::-webkit-scrollbar-thumb {
      background: #6443b3;
    }
  }

  &-none {
    &::-webkit-scrollbar-track {
      background-color: #292929;
      transition: 0.3s;
    }

    &::-webkit-scrollbar-thumb {
      background: #292929;
      transition: 0.3s;
    }
  }


}


@keyframes showFade {
  0% {
    opacity: 0;
  }
}

