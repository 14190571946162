$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;




.providers{
  position:relative;
  height:41px;
  width:100%;
  &__wrap{
    background:$violetEEL;
    border-radius:10px;
    &:hover{
      background:#fff;
      box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    }
    &.providers-open{
      background:#fff;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      z-index: 50;
      box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    }
  }

  &__select {
    position: relative;
    line-height: 41px;
    height: 41px;
    cursor: pointer;
  }
  &__select-text{
      padding-left:20px;
      color: #292929;
      font-size: 12px;
      font-weight: 500;
  }
  &__select-arr{
      position:absolute;
      right:20px;
      top:15px;
      width:8px;
      height:12px;
      transition:0.3s;
      line-height:15px;
      transform:rotate(90deg);
      .providers-open &{
        transform:rotate(-90deg);
      }
      & svg{
        fill:$violetL;
        width:8px;
        height:12px;
      }
  }

  &__over{
    padding:15px 15px 20px 35px;
    display:none;
    .providers-open &{
      display:block;
    }
  }
  &__list{
    padding-right:10px;
    max-height:210px;
    overflow: auto;
  }
  &__link{
    display:block;
    line-height:30px;
    color: #666666;
    font-size: 12px;
    font-weight: 500;
    &.active,
    &:hover{
      font-weight: 700;
    }
  }
}









//.provider-list{
//  display:block;
//  &__select{
//    position:relative;
//    border:solid 1px red;
//  }
//  &__select-link{
//      display:block;
//      position:relative;
//      background: #fff;
//      border-radius: 5px;
//      padding:0 40px 0 14px;
//  }
//
//  &__select-text{
//    line-height:41px;
//  }
//  &__select-arr{
//    position:absolute;
//    right:20px;
//    top:12px;
//    width:15px;
//    height:15px;
//    transition:0.3s;
//    &.active{
//      transform:rotate(180deg);
//    }
//  }
//
//
//  &__sub-ul{
//    position:absolute;
//    top:38px;
//    left:0;
//    width:100%;
//    box-sizing: border-box;
//    background: #fff;
//    padding:10px 10px 10px 15px;
//    border-radius: 0 0 5px 5px;
//    z-index: 30;
//
//  }
//  &__sub-link{
//    display:block;
//    line-height:27px;
//    color:#444;
//  }
//}