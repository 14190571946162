$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.article-box{
  color: #525252;
  padding-bottom:50px;
  & h1{
    font-size: 30px;
    font-weight: 700;
  }
  & h2{
    font-size: 24px;
    font-weight: 700;
  }
  & h3{
    font-size: 21px;
    font-weight: 500;
  }
  & h4{
    font-size: 19px;
    font-weight: 500;
  }
  & p{
    margin:10px 0;
    line-height: 1.5em;
    font-size:14px;
    text-align: justify;
  }
  & ul{
    padding-left:30px;
    list-style: disc;
  }
  & li{
    line-height: 1.5em;
    font-size:14px;
  }
  & a {
    color: #6443b3;
    font-weight: 500;
  }

  @include xs{
    & h1{ font-size:22px;}
    & h2{ font-size:18px;}
    & h3{ font-size:16px;  font-weight: 600;}
    & h4{ font-size:14px; font-weight: 600;}
  }
}