$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-avatar {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 15px 30px;
    background: #2a134a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__login {
        width: 100%;
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0;
        display: block;
        & span {
            display: inline-block;
            max-width: calc( 100% - 20px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & svg {
            width: 8px;
            height: 12px;
            fill: #fff;
            margin-bottom: 6px;
            margin-left: 10px;
        }
    }
    &__icon {
        margin: 0 auto;
        width: 110px;
        height: 110px;
        max-width: 100%;
    }
    @include xs {
        padding: 10px 15px;
        &__login {
            font-size: 14px;
            & svg {
                width: 6px;
                height: 8px;
                margin-left: 6px;
                margin-top: 0px;
                vertical-align: middle;
            }
        }
        &__icon {
            width: 88px;
            height: 88px;
        }
    }
    @include l {
        padding: 10px 15px;
        &__login {
            font-size: 14px;
            & svg {
                width: 6px;
                height: 8px;
                margin-left: 6px;
                margin-top: 0px;
                vertical-align: middle;
            }
        }
        &__icon {
            width: 90px;
            height: 90px;
        }
    }
    @include xxs {
        &__icon {
            width: 80px;
            height: 80px;
        }
    }
}