$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.info-page {
  counter-reset: custom-counter;
  padding-bottom: 40px;
  position: relative;

  &:before {
    background: url($cdn + "/alf/info-page-about-pc.jpg ") no-repeat 50% 50%;
    background-size: cover;
    content: "";
    display: block;
    height: 160px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include xs {
      font-size: 18px;
      height: 106px;
    }
  }

  .change-cookies-link-wrapper {
    border: 0;

    .change-cookies-link {
      display: inline-block;
      color: #6443B3;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }
  }

  h1 {
    align-items: center;
    color: #26d86a;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    height: 160px;
    line-height: 1.2em;
    text-transform: uppercase;
    margin: 0 auto;
    max-width: 850px;
    padding-left: 30px;

    @include xs {
      font-size: 18px;
      height: 106px;
      margin: 0 auto;
      max-width: calc(100% - 32px);
      padding-left: 0;
    }
  }

  & > div {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 30px 0 0;
    max-width: 850px;

    @include s {
      max-width: calc(100% - 60px);
    }

    @include xs {
      max-width: calc(100% - 32px);
    }

    & > p {
      margin: 0;
      padding: 0;

      span {
        font-weight: 400;
      }
    }
  }

  section, div {
    border-bottom: solid 2px #E9E9E9;

    & > div > div {
      max-width: 100%;
      margin-bottom: 20px;
      overflow-x: auto;

      &::-webkit-scrollbar-track {
        background: #E9E9E9;
      }

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6443B3;
        border-radius: 4px;
      }

      table {
        color: #666;
        display: table;
        font-size: 12px;
        margin: 0 0 5px 0;
        border-collapse: collapse;
        min-width: 540px;
        width: 100%;

        thead {
          th, td {
            color: #353535;
            font-weight: 700;
            padding: 8px;

            @include xs {
              font-size: 12px;
            }
          }
        }

        td {
          font-size: 12px;
          padding: 8px;
        }

        tr:nth-child(odd) td {
          background: #FAFAFF;
        }
      }
    }
  }

  p, ul {
    padding-bottom: 20px;
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
    text-align: justify;

    @include xs {
      font-size: 12px;
    }
  }

  p {
    span {
      display: inline-block;
      font-weight: 700;
      min-width: 34px;
      padding-right: 12px;
    }
  }

  ul {
    padding-left: 24px;

    li {
      list-style-type: disc;
    }
  }

  h2 {
    min-height: 60px;
    color: #545454;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0;
    padding: 20px 40px 20px 0;
    box-sizing: border-box;

    @include xs {

    }
  }

  a {
    display: contents;
    text-decoration: underline;
    color: #6443B3;
  }

  &.accordion {
    h2 {
      display: flex;
      position: relative;
      cursor: pointer;

      &:before {
        counter-increment: custom-counter;
        content: counter(custom-counter) ".";
        display: inline-block;
        min-width: 36px;
        padding-right: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 23px;
        width: 24px;
        height: 24px;
        background: url('/img/arr-r-b.svg') 50% 50%;
        transition: 0.3s;
        transform: rotate(90deg);
        background-size: contain;
        background-repeat: no-repeat;
      }

      & ~ * {
        display: none;
      }

      &.open {
        &:after {
          transform: rotate(270deg);
        }

        & ~ * {
          display: block;
        }

        & ~ p {
          display: flex;
        }
      }
    }
  }

  &.no-accordion {
    & > div > div {
      align-items: center;
      display: flex;
      justify-content: space-around;
      padding: 10px 0 30px;

      @include xs {
        flex-direction: column;
      }

      & > a {
        align-items: center;
        background-color: #ecebf6;
        border-radius: 8px;
        cursor: default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 0;
        text-decoration: none;
        width: 47%;

        @include xs {
          padding: 15px 0;
          width: 100%;

          &:first-child {
            margin-bottom: 20px;
          }

        }

        & > img {
          filter: invert(50%) sepia(45%) saturate(3000%) hue-rotate(117deg) brightness(90%) contrast(100%);
          width: 34px;
        }

        & > div {
          font-size: 18px;
          text-align: center;

          @include xs {
            font-size: 14px;
          }

          & > div:first-child {
            color: #292929;
            font-weight: 700;
            line-height: 25px;
            padding: 4px 0;
          }

          & > div:last-child {
            color: #09af48;
            cursor: pointer;
            font-weight: 500;
            text-decoration: underline;
          }
        }

        &:first-child {
          img {
            display: none;
          }

          & > div:before {
            background: url('/img/live_chat.svg') no-repeat 0% 0%;
            content: "";
            display: inline-block;
            height: 27px;
            width: 30px;
          }
        }
      }
    }
  }
}

