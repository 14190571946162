@mixin hover() {
    &.ng-tap--start:not(.ng-tap--move) {
        @content;
    }
}

@mixin breakpoint($width) {
    @media (min-width: #{$width}) {
        @content;
    }
}