$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.no-favorite {
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__content {
    width: 360px;
    padding-right: 20px;
    max-width: 50%;
    box-sizing: border-box;
  }
  &__img {
    width: 500px;
    height: 500px;
    background: url($cdn + '/alf/no-favorite.png') no-repeat 50% 50%;
    background-size: contain;
    max-width: 50%;
    box-sizing: border-box;
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: 48px;
    color: #292929;
  }
  &__text {
    padding-top: 50px;
    color: #656565;
    font-size: 24px;
    font-weight: 400;
  }
  @include l {
    &__title {
      font-size: 24px;
      line-height: 1.5em;
    }
    &__text {
      font-size: 17px;
      line-height: 1.5em;
    }
  }
  @include s {
  }
  @include xs {
    display: block;
    &__content {
      width: 100%;
      max-width: 100%;
    }
    &__title {
      width: 200px;
      font-size: 18px;
    }
    &__text {
      font-size: 14px;
      padding-top: 20px;
      max-width: 400px;
    }

    &__img {
      margin-top: 50px;
      height: 300px;
      max-width: 100%;
      width: 100%;
    }
  }
}
