$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
@keyframes btn-center {
  100% {
    right: 50%;
    transform: translateX(50%);
  }
}

@keyframes planet_1 {
  0% {
    transform: scale(0);
    left: -30vw;
    top: -15vw;
  }

  50% {
    top: 0;
    left: 50%;
  }

  100% {
    left: -110px;
    width: 570px;
    height: 355px;
    top: 20px;
    transform: scale(1);
  }
}

@keyframes banner-out {
  0% {

  }
  100% {
    opacity: 0;
    //top: 200px;
    //transform: rotate(0) translateX(-600px);
  }
}

@keyframes banner-in {
  0% {

  }
  100% {
    left: 0;
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rotate-sun {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(100deg);
  }
}

@keyframes rotate {
  0% {
    //opacity: 1;
  }
  25% {
    //opacity: .5;
    transform: scale(.8);
  }
  50% {
    //opacity: 1;
    //transform: scale(1.3);


  }
  100% {
    transform: scale(1);
  }
}

@keyframes planet-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 16000px 0;
  }
}

@keyframes planet-animation-custom {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 18000px 0;
  }
}

@keyframes planet-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20160px 0;
  }
}

@keyframes orbit {
  0% {
    top: 40%;
    left: 60%;
    z-index: 5;
    width: 90px;
    height: 90px;
  }
  20% {
    top: 60%;
    left: 0;
    width: 60px;
    height: 60px;
    z-index: 0;
  }
  40% {
    top: 45%;
    z-index: -2;
    width: 50px;
    height: 50px;
  }
  60% {
    z-index: -1;
    top: 30%;
    left: 50%;
  }

  80% {
    top: 25%;
    left: 85%;
    width: 50px;
    height: 50px;
    z-index: -1;
  }

  100% {
    top: 40%;
    left: 60%;
    width: 90px;
    height: 90px;
    z-index: 5;
  }
}

@keyframes orbit-xs {

  0% {
    top: 40%;
    left: 60%;
    z-index: 4;
    width: 90px;
    height: 90px;
  }
  20% {
    top: 70%;
    left: -25%;
    width: 60px;
    height: 60px;
    z-index: 4;
  }

  40% {
    z-index: -1;
    width: 50px;
    height: 50px;
    top: 65%;
    left: -65%;
  }

  60% {
    z-index: -1;
    top: 30%;
    left: 50%;
  }

  80% {
    top: -5%;
    left: 140%;
    width: 50px;
    height: 50px;
    z-index: 0;
  }

  100% {
    top: 40%;
    left: 60%;
    width: 90px;
    height: 90px;
    z-index: 1;
  }
}

@keyframes orbit-m {
  0% {
    top: 40%;
    left: 60%;
    z-index: 4;
    width: 90px;
    height: 90px;
  }
  20% {
    top: 70%;
    left: -35%;
    width: 60px;
    height: 60px;
    z-index: 4;
  }
  25% {
    top: 65%;
    left: -65%;
  }
  30% {
    top: 45%;
    z-index: -1;
    width: 50px;
    height: 50px;
  }
  45% {
    z-index: -1;
    top: 30%;
    left: 50%;
  }
  50% {
  }
  74% {
    z-index: -1;
    top: 0;
  }
  75% {
    top: 10%;
    left: 130%;
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  100% {
    top: 40%;
    left: 60%;
    width: 90px;
    height: 90px;
    z-index: 1;
  }
}

@keyframes welcome-bonus-desk {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hide-first-banner-animation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.3) translateX(-500vw);
  }
}

@keyframes scale_planet {
  0% {
    transform: scale(0);
    filter: blur(4px);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
}

@keyframes sattelite_1 {
  0% {
    top: 60px;
    left: 210px;
    transform: scale(1.3);
  }

  20% {
    top: 120px;
    left: -200px;
    transform: scale(1.1);
  }

  30% {
    transform: scale(.9);
    top: 100px;
    left: -220px;
  }

  40% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  60% {
    top: -40px;
    left: 200px;
    transform: scale(0);
  }

  80% {
    top: -20px;
    left: 300px;
    transform: scale(0.9);
  }
  100% {
    top: 60px;
    left: 210px;
    transform: scale(1.3);
  }
}

@keyframes sattelite_2 {
  0% {
    top: 40px;
    left: 130px;
    transform: scale(1.3);
  }

  10% {
    top: 60px;
    left: 0;
    transform: scale(1.2);
  }

  20% {
    top: 75px;
    left: -110px;
    transform: scale(.7);
  }

  30% {
    top: 65px;
    left: -120px;
    opacity: 1;
    transform: scale(.4);
  }

  40% {
    top: 25px;
    left: -80px;
    opacity: 0;
    transform: scale(0);
  }

  50% {
    top: -35px;
    left: 190px;
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    top: -20px;
    left: 250px;
    opacity: 1;
    transform: scale(.9);
  }

  70% {
    top: 0;
    left: 200px;
    transform: scale(1.1);
  }

  100% {
    top: 40px;
    left: 130px;
    transform: scale(1.3);
  }
}
