$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-wrap{
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
}
