$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.grid {
  --col: 3;
  --gap: 10px;


  display: grid;
  grid-template-columns: repeat(var(--col), 1fr);
  grid-gap: var(--gap);

  &__item-full {
    grid-column-start: 1;
    grid-column-end: -1;
    justify-items: center;
  }
}