$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.easter-box {
	&__panel-title {
		margin: 0;
	}

	&__panel-info {
		margin-bottom: 0;
	}
}