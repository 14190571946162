$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.staff{
  &__wrap{
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin:0 -10px;
  }
  &__list {
    display: contents;
  }
  &__item{
    width:calc(25% - 20px);
    margin:10px;
    &_big{ width:calc(50% - 20px); }
  }
  &__item-promos{
    &.hide{ display:none;}
  }
  &__bonus{
    position:relative;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    padding:30px 20px;

  }
  @include l{
    &__wrap{
      width:560px;
      max-width: 100%;
      margin:0 auto;
    }
    &__item{
      width:calc(50% - 20px);
      &_big{ width:calc(100% - 20px); }
    }
    &__item-promos_big{ width:calc(100% - 20px); }

  }
  @include xs{
    display:block;
    &__item{
      width:100%;
      margin:10px 0;
      &_big{ margin:10px 0; }
    }
    &__bonus{ padding:30px; }
  }
}

.staf-card{
  &__top{
    padding:30px 30px 20px;
    height:250px;
    background-color: $violetD;

    border-radius: 5px 5px 0 0;
  }
  &__fl{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-top:10px;
  }
  &__left{
    width:211px;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  &__login{
    color: #ffffff;
    font-size: 21px;
    font-weight: 400;
    padding-bottom:5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width:211px;
    text-align: center;
  }

  &__table{
    width:211px;
    max-width: 100%;
    &-item{
      display:flex;
      justify-content: space-between;
      padding:20px 0 10px;
      border-bottom:solid 1px #4c3d63;
    }
    &-val{
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
    &-key{
      color: #9a85cd;
      font-size: 12px;
      max-width:calc(100% - 50px);
    }
  }
  &__ava{
    width: 120px;
    height: 120px;
    & .grogress__str{ stroke:#716289 !important; }
    & .profile-ava__level{ font-size: 20px; }
  }
  &__points{
    min-height: 200px;
    background: #fff;
    border-radius:0 0 5px 5px;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    overflow: hidden;
  }
  @include l{
    &__top{ padding:20px 30px 20px;}
  }
  @include s{
    &__login{font-size:19px;}
  }
  @include xs{
    &__top{ height:200px;}
    &__fl{ padding:0;}
    &__login{padding-right: 30px;}
    &__left{ width:50%; min-width: 50%; padding-right: 30px;}
    &__right{ width:50%; min-width: 50%;}
    &__ava{
      & .profile-ava__level{ font-size: 14px; }
    }
    &__login{
      width:50%;
      font-size: 14px;
    }
  }


}

.profile-bonus{
  &__wrap{
    flex-grow: 1;

  }
  //border: solid 1px red;
  &__list{
    flex-grow: 1;
    display:flex;
    flex-wrap: wrap;
    position:relative;
    min-height:400px;
  }
  &__item{
    width:calc( 50% - 20px);
    //height: 252px;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    margin:10px;
    padding:40px 20px 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position:relative;
    box-sizing: border-box;
  }
  &__label{
    position:absolute;
    top:-10px;
    right:-10px;
    width:45px;
    height:45px;
    background: #fff;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    & svg{ width:35px;}
  }
  &__name{
    color: #292929;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  &__sum{
    padding:30px 0;
    color: #6443b3;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    & sup{
      font-size: 15px;
      display: inline-block;
      margin-top: 10px;
      line-height: 22px;
      vertical-align: top;
    }
  }
  &__link{
    padding-top:10px;
    text-align: center;
    & a{
      color: #545454;
      font-size: 12px;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__btns{
    text-align: center;
  }
  &__params{
    padding:20px 0;
    width:100%;
  }
  &__params-item{
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom:solid 1px #dadada;
    padding:7px 0;
  }
  &__key{
    width:60%;
    min-width: 60%;
    color: #838383;
    font-size: 12px;
    font-weight: 400;
  }
  &__val{
    width:40%;
    min-width: 40%;
    text-align: right;
    color: #353535;
    font-size: 12px;
    font-weight: 500;
  }

  @include m{
    &__list{
      width:555px;
    }
  }
  @include xs{
    &__list{
      width:290px;
    }
    &__item{
      width:100%;
      margin:10px 0;
    }
  }
}


