$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.contact-bar {
  background: #ecebf6;
  //width:calc(100% - 45px);
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 5px;
  display: block;
  margin-top: 20px;

  &__email {
    @include min-s {
      margin-left: 24px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;

    @include min-s {
      justify-content: unset;
      padding: 0 24px;
    }
  }

  &__address {
    margin-left: auto;
  }

  &__item {
    display: flex;
    //margin: 0 10px;
    white-space: nowrap;
  }

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #3F3F3F;

    &_nopadding {
      padding: 0;
    }
  }

  &__link {
    color: #3f3f3f;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    line-height: 18px;
  }

  @include l {
    padding: 10px 15px;
    &__wrap {
      //margin: 0 -10px;
    }
    &__link {
      font-size: 11px
    }
    //&__label {
    //  font-size: 9px;
    //}

  }
  @include s {
    //width:calc(100% - 35px);
    padding: 10px;
    //&__wrap{ flex-wrap: wrap; }
    &__address {
      //width: 100%;
      margin-left: auto;
      justify-content: center;
    }
    //&__label {
    //  font-size: 9px
    //}
    //&__item {
    //  margin: 0 5px;
    //}
  }
  @include xs {
    &__item {
      margin: 5px 10px;
    }
    &__wrap {
      justify-content: space-around;
    }
    &__address {
      display: none;
    }
    &__label {
      display: none;
    }
  }
}

