$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
body.linda-has-user .show-guest {
	display: none;
}

body:not(.linda-has-user) .show-user {
	display: none;
}

body:not(.linda-has-remembered-login) {
	& .btn-was-loged {
		display: none;
	}

	& .btn-never-loged {
		display: inline-flex;
	}
}

body.linda-has-remembered-login {
	& .btn-was-loged {
		display: inline-flex;
	}

	& .btn-never-loged {
		display: none;
	}
}
$margin-list: 0 2 4 5 6 8 10 12 14 15 16 17 18 19 20 22 24 25 26 28 30 32 36 38 40 48 50 56 62 64 80 88;

@for $i from 1 through length($margin-list ) {
    .has-margin-bottom-#{nth( $margin-list, $i )} {
        margin-bottom: #{nth( $margin-list, $i )}px !important;
    }

    .has-margin-top-#{nth( $margin-list, $i )} {
        margin-top: #{nth( $margin-list, $i )}px !important;
    }
}