$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;

  max-height: 100%;

  overflow-y: auto;
  background: rgba(56, 32, 95, 0.8);

  &__wrap {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
  }

  &__box {
    position: relative;
    margin: 50px 0;
    background: #fff;
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.3);
    border-radius: 5px;
    padding: 40px 40px 30px;
    box-sizing: border-box;
    overflow: hidden;
    width: 320px;
    max-width: calc(100% - 20px);

    .is-fullscreen & {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 540px;
      border-radius: 0;
      margin: 0;
      text-align: center;

      @include min-xs {
        margin: auto;
        height: auto;
        border-radius: 5px;
      }
    }
  }

  &__content {
    width: 220px;
    margin: 0 auto;

    .is-fullscreen & {
      width: auto;
      max-width: 280px;
    }
  }

  &__title {
    color: #666666;
    font-size: 21px;
    font-weight: 400;
    padding-bottom: 14px;

    &.is-dark {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      padding-bottom: 2px;
      color: #292929
    }
  }

  &__text {
    padding-top: 14px;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: #292929;

    &.is-center {
      text-align: center;
    }

    &.is-fs-14 {
      font-size: 14px;
    }

    & strong {
      font-weight: 500;
    }

    &_justify {
      text-align: justify;
    }
  }

  &__sums {
    padding-top: 10px;
  }

  &__sum {
    padding: 5px 0;
    color: #6443b3;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1em;
    text-align: center;

    & sup {
      font-size: 14px;
      vertical-align: middle;
      margin-top: -5px;
      display: inline-block;
    }
  }

  &__btns {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &_vertical {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__btns-item {
    margin: 0 auto;
  }

  & .g-btn {
    padding: 0 35px;
    margin: 5px 0;
  }

  &__btn {
    min-width: 260px;
    font-weight: 700;
  }

  &__link {
    margin-top: 15px;
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #666666;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__img-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__img {
    height: 38px;
    margin: 0 6px;

    &.is-lang {
      width: 52px;
    }

    &.is-zimpler {
      width: 124px;
    }
  }
}