$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item-touch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: 0.2s;

  display: flex;
  flex-direction: column;

  &.active {
    opacity: 1;
  }

  &__item,
  &__show-guest {
    min-height: 50%;
    flex-grow: 1;
    display: none;

    .active & {
      display: flex;
    }
  }

  &__play {
    background: $green;
    font-size: 28px;
    font-weight: 700;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;

    & span {
      color: #fff;
    }

    & svg {
      width: 15px;
      height: 20px;
      vertical-align: middle;
      margin-left: 10px;
      margin-top: 2px;
      fill: #fff;
    }
  }

  &__demo {
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(154, 133, 204, 0.9);
  }
}