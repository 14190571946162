$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar {
  display: block;

  &__bg {
    background: rgba(41, 41, 41, 0.95);
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 991;
    opacity: 0;
    transition: opacity 0.3s;
    .menu-open & {
      left: 0;
      opacity: 1;
    }
  }

  &__wrap {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 120px;
    height: 100%;
    background-image: linear-gradient(to top, #2f1e4a 0%, #39205f 100%);
    transition: 0.3s;
    z-index: 992;
    overflow: hidden;
    .menu-open & {
      width: 250px;
    }
  }

  &__scroll {
    height: 100%;
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__top {
    position: relative;
    padding-top: 25px;
  }
  &__logo {
    text-align: center;
    & img {
      width: 80px;
      height: 36px;
    }
  }
  &__close {
    display: block;
    position: absolute;
    top: 25px;
    right: 15px;
    width: 36px;
    height: 36px;
    padding: 8px;
    box-sizing: border-box;
    transition: right 0.3s, opacity 0.2s;
    opacity: 1;
    & svg {
      fill: #fff;
      width: 100%;
      height: 100%;
    }
    @include min-m {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &__burger {
    display: none;
  }
  &__chat {
    margin-top: auto;
    height: 50px;
    min-height: 50px;
    padding: 10px 0;
    background-color: #352154;
    cursor: pointer;
    text-align: center;

    & svg {
      width: 30px;
      height: 28px;
      fill: #fff;
      vertical-align: top;
    }
    & span {
      color: #ffffff;
      font-size: 12px;
      line-height: 28px;
      padding-left: 16px;
      transition: 0.3s;

      max-width: 200px;
      display: inline-block;
      overflow: hidden;
    }
    @include min-m {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  @include xs {
    &__wrap {
      left: -250px;
      width: 250px;
      overflow: hidden;
      height: auto;
      .menu-open & {
        left: 0;
      }
    }
    &__scroll {
      height: 100%;
      overflow: auto;
      width: calc(100% + 20px);
    }
    &__container {
      width: 250px;
    }
  }
}
@include min-s {
  html:not(.menu-open) {
    & .sidebar {
      &__close {
        right: -45px;
        opacity: 0;
      }
      &__burger {
        display: block;
        padding: 15px 0 10px;
        text-align: center;
        & a {
          padding: 5px;
          display: inline-block;
        }
        & svg {
          width: 30px;
          height: 20px;
          fill: #fff;
        }
        @include min-m {
          &:hover {
            opacity: 0.7;
          }
        }
      }
      &__chat span {
        max-width: 0;
        padding: 0;
      }
    }
    & .hide-small {
      display: none;
    }
  }
}
