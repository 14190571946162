$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-filter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 55;

  &.fi_style {
    margin-top: -35px;

    @include min-xs {
      margin-top: -10px;
    }

    @include min-m {
      margin-top: -150px;
    }
  }

  @include min-l {
    flex-direction: row;
    z-index: 800;
    align-items: center;
  }

  &__cat {
    flex-grow: 1;
    overflow-x: auto;
  }

  &__side {
    position: relative;
    min-width: 265px;
    width: 265px;
    display: flex;
    margin: 0 auto 20px;
  }

  &__side-item {
    height: 42px;
    position: relative;

    &:first-child {
      width: 42px;
      height: 42px;
    }

    &:last-child {
      width: 218px;
      height: 42px;
      margin-left: 5px;
    }
  }
}
