$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.pays-bankid {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 86px;
    height: 23px;
    margin: 0 10px;
  }
  & img {
    width: 100%;
    height: 100%;
  }
  @include s {
    &__item {
      width: 68px;
      height: 18px;
    }
  }
  @include xs {
    height: 18px;
    overflow: hidden;
    &__list {
      justify-content: center;
    }
    &__item {
      margin: 0 4px;
    }
  }
}