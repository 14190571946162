$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.gamehall-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__name {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #666666;
    padding-right: .7em;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #686b6c;
    text-transform: capitalize;
  }

  &__amount {
    margin-left: 4px;
    color: #6443b3;
  }

  &__container {
    display: flex;
    overflow-x: auto;
  }
}