$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-notification {
    position: relative;
    width: 100%;
    min-height: 64px;
    display: flex;
    padding: 12px;
    background: $greenD;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $white;
    }
    &__text-item {
        margin-right: 4px;
        &:last-child {
            margin-right: initial;
        }
    }
    &__toggle {
        flex: 0 0 50px;
        height: 26px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $white;
        border: 1px solid $white;
        border-radius: 24px;
        margin-left: 16px;
        &::before {
            content: '';
            width: 24px;
            height: 24px;
            background: $violetGrey;
            border-radius: 50%;
        }
    }
    @include min-s {
        min-height: 50px;
    }
}