$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-baner {
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    transition: 0.3s;
    background-size: cover;
    @supports ( display: grid) {
        background-size: auto 100% !important;
        &:hover {
            background-size: auto 110% !important;
        }
    }
    &_1 {
        background: url($cdn + "/alf/discover-baner-1.jpg") no-repeat 50% 50%;
    }
    &_2 {
        background: url($cdn + "/alf/discover-baner-2.jpg") no-repeat 50% 50%;
    }
    &__title {
        color: #ffffff;
        font-size: 20px;
        line-height: 1.6em;
        font-weight: 700;
    }
    @include xxs {
        &__title {
            font-size: 16px;
            line-height: 1.6em;
        }
    }
}