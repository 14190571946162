$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-nav {
  &__in {
    display: none;
  }

  &__item:not(:last-child) {
    margin-bottom: 1px;
  }

  &__link {
    position: relative;
    display: block;
    background: #342151;
    height: 70px;
    overflow: hidden;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      transition: 0.5s;
      z-index: 301;
    }

    @include min-m {
      &:hover {
        &:after {
          left: -400px;
          top: -400px;
          width: 800px;
          height: 800px;
        }
      }

      &_profile:hover {
        &:after {
          left: -25px;
          top: -25px;
          width: 50px;
          height: 50px;
        }
      }
    }

    &.active {
      &:after {
        left: -800px;
        top: -800px;
        width: 1600px;
        height: 1600px;
      }
    }

    &_profile.active {
      &:after {
        left: -25px;
        top: -25px;
        width: 50px;
        height: 50px;
      }
    }

    &_green {
      color: $light;

      &:after {
        background: $light;
      }
    }

    &_blue {
      color: $blue;

      &:after {
        background: $blue;
      }
    }

    &_violet {
      color: $violet;

      &:after {
        background: $violet;
      }
    }

    &_yellow {
      color: $yellow;

      &:after {
        background: $yellow;
      }
    }

    &.active .main-nav__text {
      color: $black;
    }
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    font-size: 14px;
    font-style: italic;
    z-index: 302;

    transition: 0.3s;
    @include min-m {
      &:hover {
        color: $black;
      }
    }
  }

  &.is-authorized .main-nav {
    &__in {
      display: block;
    }

    &__out {
      display: none;
    }

    &__link {
      height: 40px;

      &_support {
        height: 70px;
      }
    }

    &__profile {
      height: 130px;
      background: #342151;

      & .header-nav__text {
        justify-content: center;
      }
    }
  }
}
