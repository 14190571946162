$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item-full {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}
