$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.hero-slider{
  width:100%;
  height:100%;

  display:flex;
  align-items: center;
  justify-content: center;

  &__wrap{
    position:relative;
    width:100%;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }


  &__slick{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    //background: black;

    & .slick-arrow{
      position:absolute;
      top:45%;

      border:none;
      width: 40px;
      height: 40px;
      box-shadow: 0 0 10px rgba(15, 4, 83, 0.25);
      background-color: #ffffff;
      border-radius: 50%;
      z-index:50;
      font-size: 0;
      color:#fff;
      cursor:pointer;
      &:hover{
        box-shadow: 0 0 10px rgba(15, 4, 83, 0.45);
      }

    }
    & .slick-next{
      right:-20px;
      background: #fff url('/img/arr-r-slick.svg') 55% 50% no-repeat;
      background-size: 15px;
    }
    & .slick-prev{
      left:-20px;
      background: #fff url('/img/arr-l-slick.svg') 45% 50% no-repeat;
      background-size: 15px;
    }

    @include xs{
      & .slick-arrow{
        width:35px;
        height:35px;
      }
      & .slick-prev{ left:-10px;}
      & .slick-next{ right:-10px;}
    }
  }

}