$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-item {
	--img-size: 120px;

	&:not(:last-child) {
		margin-bottom: 20px;

		@include min-xs {
			margin-bottom: 24px;
		}
	}

	&__inner {
		display: grid;
		grid-template: repeat(2, auto) / repeat(2, auto);
		padding: 8px 8px 20px;
		box-shadow: 0px 0px 20px 0px #290B2940;
		border-radius: 5px;

		@include min-xs {
			min-height: var(--img-size);
			padding: 0;
		}

		@include min-m {
			grid-template-columns: auto 1fr;
		}

		.promo-item:nth-child(even) & {
			@include min-m {
				grid-template-columns: 1fr auto;
			}
		}
	}

	&__back {
		grid-column: 1/2;
		grid-row: 1/2;
		width: var(--img-size);
		min-height: var(--img-size);
		display: flex;
		padding: 10px;
		background-image: var(--promo-img);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 5px;
		align-self: start;

		@include min-xs {
			--img-size: 264px;
			height: 100%;
			grid-row: 1/3;
			border-radius: 5px 0 0 5px;
		}

		@include min-m {
			--img-size: 202px;
		}

		.promo-item:nth-child(even) & {
			grid-column: 2/3;

			@include min-xs {
				border-radius: 0 5px 5px 0;
			}
		}
	}

	&__offer {
		margin: auto;
		text-align: center;
		color: #fff;
		font-size: 14px;
		line-height: 17px;
		font-weight: 900;
		text-shadow: 0px 0.14em 1px #0F1F4E;

		@include min-xs {
			font-size: 28px;
			line-height: 34px;
		}

		@include min-m {
			font-size: 22px;
			line-height: 26px;
		}
	}

	&__text {
		grid-column: 2/3;
		grid-row: 1/2;
		padding: 4px 2px 0 10px;
		text-align: left;

		@include min-xs {
			padding: 20px 24px 0;
			text-align: center;
		}

		@include min-m {
			padding-top: 24px;
			text-align: left;
		}

		.promo-item:nth-child(even) & {
			grid-column: 1/2;
		}
	}

	&__title {
		margin: 0 0 8px;
		color: #666;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.2;

		@include min-xs {
			font-size: 16px;
			margin-bottom: 22px;
		}

		@include min-s {
			font-size: 18px;
			margin-bottom: 12px;
		}
	}

	&__descr {
		color: $black;
		font-size: 10px;
		line-height: 1.4;

		@include min-s {
			font-size: 12px;
		}
	}

	&__controls {
		grid-column: 1/3;
		grid-row: 2/3;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: end;
		padding-top: 22px;

		@include min-xs {
			grid-column: 2/3;
			grid-row: 2/3;
			padding-bottom: 20px;
		}

		@include min-m {
			flex-direction: row;
			padding-left: 24px;
			padding-top: 0;
			padding-bottom: 24px;
		}

		.promo-item:nth-child(even) & {
			@include min-xs {
				grid-column: 1/2;
			}
		}
	}

	&__btn {
		font-weight: 700;
	}

	&__link {
		display: inline-flex;
		align-items: center;
		margin-top: 8px;

		@include min-m {
			margin-top: 0;
			margin-left: 20px;
		}
	}

	&__link-text {
		font-size: 12px;
		font-weight: 700;
		line-height: 133%;
		letter-spacing: 0.1px;
		text-align: center;
	}

	&__link-arrow {
		flex-shrink: 0;
		margin-left: 8px;
		width: 9px;
		height: 9px;
		fill: #927ec1;
	}
}