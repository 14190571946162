$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.reg-modal {
  &__section {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.64;
    color: #545454;
    padding-bottom: 20px;
  }
  &__section-title_id {

    display: flex;

    &::before {
      flex-shrink:0;
      margin-right: 7px;
      width: 17px;
      height: 17px;
      display: block;
      content: '';
      background: url('/img/icon-done.svg') center no-repeat;
    }

  }

  &__section + &__section {
    padding: 20px 0;
    border-top: 1px solid #e9e9e9;
  }

  &__bank-id {
    text-align: center;
    margin: 10px 0 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #666666;
  }

  &__link {
    cursor: pointer;
    width: 100%;
    padding: 0;
    margin-top: 10px;
    border:none;
    background:none;
    display: block;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: #666666;
    text-decoration: underline;
  }

  &__datepicker {
    transform: translateX(-40px);
  }

  & .g-check-text a {

    color: #9a85cc;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &__flex-row {
    display: flex;
    height: 34px;
    align-items: center;
  }

  &__item-50  {
    width: 50%;
  }

  &__btn {
    min-width: 180px;
    display: block;
    margin: 0 auto;
  }

  &__accept {
    display: none;
  }
  &__btns-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    align-items: center;
  }
  &__back {
    padding: 0 25px;
    border-radius: 100%;
    height: 50px;
    &::after {
      left: 17px;
    }
  }
  &__box {
    margin: 50px auto 0;
  }
  /*-------Hero slider(step-1)---------*/
  &__slider {
    background: url(https://joxi.imgsrcdata.com/alf/registration_1800.jpg) 50% 50% no-repeat;
    border-radius: 0 0 5px 5px;
    padding-bottom: 20px;
  }
  &__slider-text {
    border-radius: 5px 5px 0 0;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.21px;
    color: #666666;
    padding: 20px 40px;
    background-color: #fff;
  }
  &__slides {
    height: 271px;
  }
  &__slider-btn-wrap {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__slider-btn {
    font-size: 13px;
    background-color: #ff7c54;
    font-weight: 700;
    color: #fff;
    min-width: 180px;
    text-align: center;
    box-sizing: border-box;
    &:hover {
      background-color: #fd8976;
    }
  }

  /*-------------Bottom pagination--------------*/

  &__steps {
    display: flex;
    justify-content: center;
    margin: 20px 0 40px;
  }
  &__step {
    width: 12px;
    height: 12px;
    background-color: #FFFFFF;
    opacity: 0.5;
    border-radius: 100%;
    margin: 0 4px;
    &_2 {
      opacity: 1;
    }
  }
}

.reg-modal.step-1 {
  .modal__box {
    padding: 0 20px;
    background: transparent;
    box-shadow: none;
    width: 336px;
  }
  .reg-modal__step_1 {
    opacity: 1;
  }
  .reg-modal__step_2 {
    opacity: 0.5;
  }
}