$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-field {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #292929;

    display: flex;
    align-items: center;
    justify-content: center;

    & .preloader {
      background: none;
    }

    &__container {
      width: 100px;
    }

    &.ng-hide-add {
      transition: all 0.3s linear;
      opacity: 1;
    }

    &.ng-hide {
      opacity: 0;
    }
  }

  &__frame {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
  }
}