$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.discover{
  &__list{
    display:flex;
    flex-wrap: wrap;
    margin:0 -10px;
  }
  &__section{
    width: calc(25% - 20px);
    height:250px;
    position:relative;
    margin:10px;
  }
  @include s{
    &__section{
      width: calc(33.334% - 20px);
      height:210px;
    }
  }
  @include xs{
    &__section{
      width: calc(50% - 20px);
    }
  }
  @include xxs{
    &__list{margin:0;}
    &__section{
      width: calc(100%);
      margin:10px 0;
    }
  }
}

/* autoprefixer: off */
@supports ( display: grid )  {
  .discover {
    &__list{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 20px;
      margin:0 !important;
    }
    &__section {
      position: relative;
      width: 100%;
      margin:0 !important;
      overflow:hidden;
      height:auto !important;
      &_small{
        padding-top: calc( 100% - 20px);
      }
      &_1{
        /* autoprefixer: off */
        grid-row-start: 1;
        grid-row-end: 3;
        /* autoprefixer: off */
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &_6{
        /* autoprefixer: off */
        grid-row-start: 2;
        grid-row-end: 3;
        /* autoprefixer: off */
        grid-column-start: 4;
        grid-column-end: 6;
      }
      &_7{
        /* autoprefixer: off */
        grid-row-start: 3;
        grid-row-end: 4;
        /* autoprefixer: off */
        grid-column-start: 1;
        grid-column-end: 3;
      }
      &_12{
        /* autoprefixer: off */
        grid-row-start: 3;
        grid-row-end: 5;
        /* autoprefixer: off */
        grid-column-start: 4;
        grid-column-end: 6;
      }
    }

    &__item{
      //padding-top: calc( 100% - 20px);
    }


    @include l{
      &__list{ grid-gap:10px; }
      &__section_small{ padding-top: calc( 100% - 10px); }
    }

    @include s{
      padding-top:30px;
      &__list{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 380px repeat(4, 1fr) 380px;
        grid-gap:20px;
      }
      &__section {
        &_1 {
          /* autoprefixer: off */
          grid-row-start: 1;
          grid-row-end: 2;
          /* autoprefixer: off */
          grid-column-start: 1;
          grid-column-end: 4;
        }
        &_6 {
          /* autoprefixer: off */
          grid-row-start: 3;
          grid-row-end: 4;
          /* autoprefixer: off */
          grid-column-start: 2;
          grid-column-end: 4;
        }
        &_7 {
          /* autoprefixer: off */
          grid-row-start: 4;
          grid-row-end: 5;
          /* autoprefixer: off */
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &_12 {
          /* autoprefixer: off */
          grid-row-start: 6;
          grid-row-end: 7;
          /* autoprefixer: off */
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
      &__section-small{  padding-top: calc( 100% - 20px); }
    }
    @include xs{
      &__list{
        grid-template-rows: 320px repeat(4, 1fr) 320px;
      }
      &__list{ grid-gap:10px;}
      &__section-small{  padding-top: calc( 100% - 10px); }
    }
    @include xxs{
      &__list{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 370px 1fr 1fr 320px;
      }
      &__section{
        &_small{ display:none; }
        /* autoprefixer: off */
        grid-column-start: 1 !important;
        grid-column-end: 2 !important;

        &_wide{
          padding-top:calc( 50% - 10px);
        }
        &_1 {
          /* autoprefixer: off */
          grid-row-start: 1;
          grid-row-end: 2;
        }
        &_6 {
          /* autoprefixer: off */
          grid-row-start: 2;
          grid-row-end: 3;
        }
        &_7 {
          /* autoprefixer: off */
          grid-row-start: 3;
          grid-row-end: 4;
        }
        &_12 {
          /* autoprefixer: off */
          grid-row-start: 4;
          grid-row-end: 5;
        }

      }

    }
  }
}




/*============================
 === -- DISCOVER BLOCKS -- ===
 ===========================*/

.discover__section_small .discover__item{
  position:absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@include xxs{
  .discover__section_wide .discover__item{
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.discover{
  &__item{
    width: 100%;
    height: 100%;
    border-radius:10px;
    overflow:hidden;
    background:#eee;
    color:#fff;
    background-size: cover !important;
    transition:0.3s;
    & a:not(.g-btn){ color:#fff;}
    &_dark{
      color:#000;
      & a:not(.g-btn){ color:#000;}
    }
    &_link:hover{ opacity:0.8;}
    &_2{ background: url('/img/discover_1.svg') 50% no-repeat; }
    &_4{ background: url('/img/discover_2.svg') 50% no-repeat; }
    &_8{ background: url('/img/discover_3.svg') 50% no-repeat; }
    &_10{ background: url('/img/discover_4.svg') 50% no-repeat; }
    &_baner-1{ background: url( $cdn + '/alf/discover-baner-1.jpg') 50% no-repeat; background-size: cover; }
    &_baner-2{ background: url( $cdn + '/alf/discover-baner-2.jpg') 50% no-repeat; background-size: cover;}

    /* autoprefixer: off */
    @supports ( display: grid )  {
      &_baner-1,
      &_baner-2{
        background-size: auto 100% !important;
        &:hover{ background-size: auto 110% !important; }
      }
      @include s{
        &_baner-1,
        &_baner-2{
          background-size:  110% auto !important;
          &:hover{ background-size: 120% auto !important; }
        }
      }
      @include xs{
        &_baner-1,
        &_baner-2{
          background-size:  130% auto !important;
          &:hover{ background-size:  130% auto !important; }
        }
      }
      @include xxs {
        &_baner-1,
        &_baner-2{
          background-size:  170% auto !important;
          &:hover{ background-size:  170% auto !important; }
        }
      }
    }
  }
  &__content{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  &__name{
    padding:25px 15px 0 15px;
    text-align: center;
    //-color: #000000;
    font-size: 20px;
    font-weight: 700;
    //-&_light{ color:#fff;}
  }
  &__text{
    font-size: 9px;
    font-weight: 300;
    text-align:center;
    & a{ text-decoration: underline;}
  }
  &__middle{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:0 15px;
  }
  &__bottom{
    margin-top:auto;
    padding: 0  20px 20px;
    max-width: 100%;
    &_btn{ padding: 0  20px 10px; }
  }
  &__fs{
    font-weight: 900;
    font-size: 18px;
    & sup{
      font-weight:400;
      vertical-align: middle;
      font-size: 16px;
    }
  }
  &__btn{
    padding:0 30px;
    max-width:100%;
    white-space: nowrap;
    overflow: hidden;

  }
  &__baner-title{
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    padding: 20px 30px;
    width:100%;
  }
  @include l{
    &__btn{
      padding:0 20px;
    }
    &__name{ font-size:16px;}

  }
}