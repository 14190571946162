$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.rest-popup{
  width:300px;

  &__wrap{
    width:220px;
    margin: 0 auto;
  }
  &__form-item{
    padding-top:20px;
  }
  &__btns{
    padding-top:30px;
    text-align: center;
  }
  &__links{
    padding-top:30px;
    text-align: center;
    & a{
      font-size:12px;
      text-decoration: underline;
      font-weight:700;
      color: #545454;
    }
  }
  &__p{
    font-size:12px;
    font-weight:600;
    padding:15px 0;
  }
  & .g-input {
    padding-right: 15px;
  }
  & input:placeholder-shown {
    text-overflow: ellipsis;
  }
}