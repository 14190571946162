$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.landing-jackpot{
  padding:90px 0 80px;
  display:flex;
  background: url( $cdn + '/alf/home-jackpots_1800.jpg') 50% 50% no-repeat;

  &__wrap{
    margin:auto;
    height:300px;
    width:600px;
    //-max-width: calc(100% - 100px);
    background: url('/img/home-jackpot.svg') 50% 50% no-repeat;
    background-size: cover;
    border-radius:30px;
    box-shadow: 0 0 10px rgba(15, 4, 83, 0.25);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding:15px 20px;
  }
  &__title{
    color: $yellow;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  &__sum{
    text-shadow: 0 0 30px rgba(254, 231, 165, 0.4);
    color: #ffffff;
    font-size: 54px;
    line-height: 1;
    font-weight: 800;
  }


  @include s{
    background: url( $cdn + '/alf/home-jackpots_904.jpg') 50% 50% no-repeat;
    padding:50px 0;
    &__wrap{
      height:225px;
      width:400px;
      padding:10px 15px;
    }
    &__title{ font-size: 19px; line-height: 1.2em; }
    &__sum{ font-size: 30px; }
  }
  @include xxs{
    background: url( $cdn + '/alf/home-jackpots_540.jpg') 50% 50% no-repeat;
    &__wrap{  width:calc( 100% - 30px); }
    &__sum{   font-size: 26px; }
    &__title{ font-size: 16px; }
  }
}

/*
.landing-jackpot{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: url('/img/bg-jackpot2.svg') 50% 50% no-repeat;
  background-size:auto 80%;
  text-align: center;


  &__wrap{

    min-width:400px;
    margin:0 auto;
    height:100%;
    padding:40px;


    display:inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius:50px;
    box-shadow: 0 0 20px rgba(41, 41, 41, 0.2);
    //background:  url( $cdn + "/alf/newbg-jackpot-wrap2-min.jpg") 50% 50% no-repeat;
    //background-image: linear-gradient( 49deg, #9b7cd7 0%, #5580e4 100%);
    background: url('/img/bg-jackpot-box.svg') 50% 50% no-repeat;
    background-size:cover;
  }
  &__title{
    color: #b1f9dc;
    font-size: 36px;
    font-weight: 400;

  }
  &__sum{
    color: #ffffff;
    font-size: 80px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 65px;
    padding:40px 0;
  }
  &__btn{
    padding:20px 0;
  }
  @include s{
    &__title{
      font-size:24px;
      line-height: 1.3em;
    }
    &__sum{
      font-size: 48px;
      padding:20px 0;
    }
  }
  @include xxs{
    &__sum{
      font-size: 30px;
      line-height:1em;
    }
    &__wrap{
      min-width:auto;
      width:calc(100% - 30px);
    }
    &__btn{ padding:5px 0;}
  }
} */