$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;


.profile-ava{
  width:100%;
  height:100%;
  position:relative;
  &__ico{
    position:absolute;
    inset: 0;
    border-radius: 50%;
    background: lightblue;
    overflow: hidden;
  }
}