$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.login-bank{
  width:620px;
  padding:0;
  display:flex;
  background:#fff url('/img/bg_login.svg') no-repeat right 200px;
  &__left{
    position:relative;
    min-width: 50%;
    &::after{
      content: '';
      position:absolute;
      top:40px;
      right:0;
      width:1px;
      height:calc(100% - 110px);
      background: #d8d0ec;
    }

  }
  &__right{
    min-width: 50%;
    padding: 30px 40px 30px 50px;
    display:flex;
    flex-direction: column;
  }

  &__left-inner {
    padding: 30px 50px 0 40px;
  }
  &__left-title {
    max-width: 100%;
  }

  &__desc{
    padding-top:10px;
  }
  &__desc-item{
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    line-height:2em;
    letter-spacing: -0.24px;
  }
  &__subtitle{
    padding-top:5px;
    color: #292929;
    font-size: 12px;
    font-weight: 700;

  }
  &__btns{
    padding-top:20px;
    text-align: center;
  }
  &__noid{
    padding-bottom: 30px;
    padding-top:15px;
    text-align: center;
    color: #545454;
    font-size: 12px;
    display:flex;
    justify-content: center;
    & a{
      color: #545454;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  &__noid-item{
    padding:10px 6px;
  }
  &__close-mob{
    display:none;
  }
  &__select.ng-valid {
    color: #2c2e2e;
  }


  .login-bank-login-form {
    flex-grow: 1;
    display: flex;
    .login-popup__form-flex {
      display: flex;
      flex-direction: column;
      height: 100%;

      .g-formbox-item {
        width: 252px;

        .g-input {
          width: 252px;
        }
      }
    }
    .g-formbox-item:first-of-type, .login-popup__btn-box {
      margin-top: auto;
    }
  }


  @include xs{
    display:block;
    width:300px;
    background:#fff;
    &__left{
      width:100%;
      background: url('/img/bg_login-reverse.svg') no-repeat right -100px;
      &:after{
        content: '';
        position:absolute;
        top:100%;
        right:40px;
        width:220px;
        height:1px;
        background: #d8d0ec;
      }
    }
    &__right{
      width:100%;
      padding:30px 24px;
      background:#fff url('/img/bg_login.svg') no-repeat right 140px;
    }
    &__left-inner {
      padding: 30px 40px 0;
    }
    &__left-title {
      max-width: calc( 100% - 24px);
    }
    &__right-title {
      max-width: 100%;
    }
    .login-bank__close-mob{ display:flex; }
    .login-bank__close-pc{ display:none; }
  }
}



.login-bank-frame {
  width: 700px;
  max-width: 700px;
  padding: 0;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  &.touch-device {
    height: calc( 100% - 100px);
  }
  &__iframe {
    height: 445px;
    margin: 0 40px;
    background-clip: content-box;
    position: relative;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    iframe {
      position: relative;
      z-index: 2005;
    }
    .preloader {
      background-color: rgba(255, 255, 255, 0.2);
    }
    &.touch-device {
      overflow: scroll;
      height: 100%;
    }
  }
  &__close {
    align-self: flex-end;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;

    svg {
      width: 14px;
      height: 14px;
      fill: #666666;
    }
  }

  @include xs {
    width: 100%;
    height: calc( 100% - 50px);
    margin: 25px 0;
    &.touch-device {
      height: calc( 100% - 50px);
    }
    &__iframe {
      margin: 0 20px;
      height: 100%;
      overflow: scroll;
    }
    &__close {
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}




