$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    flex-grow: 1;
    &__wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        flex-grow: 1;
    }
    &__field {
        position: relative;
        flex-shrink: 1;
        flex-grow: 1;
    }
    @media (orientation: landscape) {
        flex-direction: row;
    }
}