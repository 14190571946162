$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-app {
    background: #ecebf6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    padding: 25px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &__title {
        color: #838383;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        padding: 0 30px;
        max-height: 60px;
        overflow: hidden;
        & svg {
            width: 7px;
            height: 11px;
            fill: $violetL;
            margin-left: 7px;
        }
    }
    &__icons {
        display: flex;
        justify-content: center;
    }
    &__icon {
        width: 70px;
        height: 70px;
        background: #fff;
        border-radius: 15px;
        margin: 0 6px;
        &:first-child {
            padding: 8px 16px 16px;
        }
        &:last-child {
            padding: 15px 19px;
        }
        & svg {
            width: 100%;
            height: 100%;
            fill: #b7b3dc;
        }
    }
    @include l {
        padding: 20px 0;
        justify-content: center;
        &__title {
            font-size: 15px;
            line-height: 23.1px;
            max-height: 47px;
            padding: 0 20px;
            text-align: center;
        }
        &__icons {
            padding-top: 10px;
        }
        &__icon {
            width: 54px;
            height: 54px;
            &:first-child {
                padding: 7px 11px 11px;
            }
            &:last-child {
                padding: 11px 13px;
            }
        }
    }
    @include s {
        &__title {
            font-size: 18px;
            line-height: 27px;
            max-height: 54px;
        }
        &__icon {
            width: 65px;
            height: 65px;
            &:first-child {
                padding: 8px 15px 15px;
            }
            &:last-child {
                padding: 13px 17px;
            }
        }
    }
    @include xs {
        &__title {
            font-size: 15px;
            line-height: 22px;
            max-height: 44px;
        }
        &__icon {
            width: 55px;
            height: 55px;
            &:first-child {
                padding: 6px 10px 12px;
            }
            &:last-child {
                padding: 11px 14px;
            }
        }
    }
    @include xxs {
        &__title {
            font-size: 14px;
            line-height: 20px;
            max-height: 40px;
        }
        &__icon {
            width: 50px;
            height: 50px;
            &:first-child {
                padding: 7px 11px 11px;
            }
            &:last-child {
                padding: 11px 15px;
            }
        }
    }
}