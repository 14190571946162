.easter-slider {
    overflow: hidden;
    min-height: 730px;

    &__inner {
        flex-direction: column;
        box-sizing: border-box;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 450px;
        padding: 0;
        transition: opacity .2s ease;
        position: relative;

        @include breakpoint($bp1) {
            min-height: 550px;
        }

        .easter-box {

            &::before,
            &::after {
                content: '';
                display: block;
                width: 10px;
                margin: 0 auto;
                flex-grow: 1;
                background-color: rgba($c-brown1, 0.3);
                pointer-events: none;
            }
        }

        &:first-child {
            .easter-box::before {
                opacity: 0;
            }
        }

        &:last-child {
            .easter-box::after {
                opacity: 0;
            }
        }

        &.swiper-slide-active {

            .easter-box__inner {
                opacity: 1;
            }
        }

        &.is-today {
            min-height: 630px;
        }

        &.is-next,
        &.is-future {
            min-height: 400px;
        }

        &.is-today,
        &.is-yesterday,
        &.is-previous {
            .easter-box__gift-img {
                filter: none;
            }

            .easter-box__gift-shine {
                display: block;
            }
        }
    }
}