$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-banner-bg {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  @media (max-width: 1919px) {
    background-size: 1800px 625px;
    background-position: center top;
  }

  @include xs {
    background-size: 768px 580px;
    background-position: center -35px;
  }
}