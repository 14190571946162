$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.not-found{
  background: url( $cdn + "/alf/404-1800.png") no-repeat center bottom ;
  background-size: cover;
  width: 100%;
  height:559px;


  &__wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 392px;
  }
  &__title{
    color: #ffffff;
    font-size: 140px;
    font-weight: 700;
    letter-spacing: 2.22px;
  }
  &__subtitle{
    color: #f0f3f4;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 5.14px;
  }
  &__desc{
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.41px;
    margin-top: 50px;
  }

  @include xs{
    background: url( $cdn + "/alf/404-768.png") no-repeat center bottom ;
    background-size: cover;
  }
  @include xs{
    &__title{
      font-size: 70px;
    }
    &__subtitle{
      font-size: 18px;
    }
    &__desc{
      font-size: 12px;
    }
  }
}
.not-found-games{
  padding-bottom: 20px;
}

/*
.not-found{

  width:100%;
  min-height:700px;
  display:flex;
  align-items: center;
  justify-content: center;
  background: $violetD;
  background: url( $cdn + "/alf/404.jpg") no-repeat 50% 50%  ;
  &__wrap{
    width:400px;
    text-align: center;
    color:#fff;
    max-width: calc(100% - 30px);
    //background: lightgoldenrodyellow;
  }

  &__title{
    font-size: 140px;
    font-weight: 700;

  }
  &__subtitle{
    color: #f0f3f4;
    font-size: 36px;
    font-weight: 400;
    padding-top:10px;
  }
  &__desc{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-top:90px;
  }

  @include s{
    &__title{  font-size: 100px;}
    &__subtitle{ font-size: 30px;}
    &__desc{ font-size: 14px; padding:50px 30px 0 ;}
  }
  @include xs{
    min-height:calc(100vh - 80px);
  }
}

*/
