$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
/*========== MAIN SEARCH ==========*/
.main-search{
  display:block;
  width:100%;
  height:100%;

  &__wrap{
    position:absolute;
    width:100%;
    //height:42px;
    top:0;
    left:0;
    transition:0.2s;
    z-index:50;
    background-color: $violetEEL;
    border-radius: 10px;
    overflow:hidden;
    &:hover,
    .search-open &{
      box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
      background-color: #ffffff;
    }
    .search-open &{ width:265px; }
  }


  &__input{
    width:100%;
    box-sizing: border-box;
    height:42px;
    line-height:42px;
    border-radius: 10px;
    border:none;
    background: rgba(255,255,255,0) url('/img/search_v.svg') 10px 50% no-repeat;
    background-size:22px;
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    padding:0 50px 0 40px;

    .search-open &{

    }
  }
  &__list{
    display:none;
    .search-open &{ display:block;}
    padding:0 30px 0px;
  }
  &__item{
    padding-top:20px;
    &:last-child{
      padding-bottom:30px;
    }
  }
}

.game-page-search {

  .main-search__wrap {
    top:-12px;
  }

  .main-search__input {
  padding: 0 50px 0 25px;
  }

  .game-panel__search-box.open & {


    .main-search__list {
      display: block;
      padding: 0 25px;
    }


    .main-search__item:first-child {
      padding-top: 7px;
    }
    .main-search__item:last-child {
      padding-bottom:25px;
    }
  }

  .main-search {
    &__input {
      background: rgba(255,255,255,0) url('/img/search_v.svg') 187px 50% no-repeat;
      background-size:18px;
    }
  }

}
