$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-banner {
  height: 580px;
  position: relative;

  &__wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:100;
    display:flex;
    justify-content: center;
  }
  &__container {
    width: 420px;
    max-width: calc( 100% - 40px);
    height: 350px;
    text-align: center;
    color: #fff;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    & a { color:#fff; }
  }
  &__title {
    font-size: 14px;
    font-weight: 700;
  }
  &__subtitle {
    padding:30px 0;
  }
  &__str {
    display: inline;
    font-size: 28px;
    font-weight: 700;
    line-height: 48px;
    padding:0 3px;
    & span { font-size: 36px; }
  }
  &__crypto-icons {
    cursor: pointer;
    width: 88px;
    height: 24px;
  }
  &__reg-id {
    box-sizing: border-box;
    min-width: 258px;
    max-width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background-color: #ff7c54;
    font-weight: 700;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    svg {
      width: 30px;
      height: 25px;
      fill: #fff;
      margin-right: 10px;
    }
    &:hover {
      background-color: #fd8976;
    }
  }

  &__link a {
    font-size: 12px;
    font-weight: 700;
    line-height: 65px;
    text-decoration: underline;
  }

  &__app-bg {
    position: absolute;
    border-radius: 100%;
    background: #ecebf6;
    width: 1100px;
    height: 400px;
    bottom: 40px;
    left :calc( 50% - 550px);
    z-index: 98;
  }
  @include xxs {
    &__container {
      margin-top: 30px;
    }
    &__app-bg {
      bottom: 20px;
    }
    &__str {
      font-size: 21px;
      & span {
        font-size: 27px;
      }
    }
  }

}

