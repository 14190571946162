$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-banner.is-ja-lang .is-hidden-for-ja {
  display: none !important;
}
.main-banner:not(.is-ja-lang) .is-shown-for-ja {
  display: none !important;
}

body.linda-has-user .show-guest{ display:none;}
body:not(.linda-has-user) .show-user{ display:none;}


