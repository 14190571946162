$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.tournaments-games {
  width: 720px;
  max-width: calc(100% - 20px);

  &__grid {
    max-height: 460px;
    overflow-y: scroll;
    padding-right: 16px;

    grid-template-columns: repeat(auto-fill, minmax(199px, 1fr));

    @include xs {
      width: calc(100% + 6px);
    }
  } 
}