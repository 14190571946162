$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.nav {
  position: relative;
  min-height: 113px;

  &__list {
    display: flex;
    width: fit-content;
    margin: auto;

    &_popup {
      flex-wrap: nowrap;
    }

    .nav_click.nav_open & {
      max-height: 1100px;
    }
  }

  &__btn {
    width: 115px;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & span {
      color: #292929;
      font-size: 12px;
      font-weight: 500;
      max-width: calc(100% - 20px);
    }

    & svg {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      fill: $violet;
      margin-bottom: -1px;
      transition: 0.3s;
      transform: rotate(90deg);

      .nav_click.nav_open & {
        transform: rotate(-90deg);
      }
    }
  }

  &__item {
    margin-right: 18px;
    margin-top: 10px;
    min-width: 85px;

    &:last-child {
      margin-right: 0;
    }

    @include min-m {
      margin-right: 0;
    }
  }

  &__ico {
    width: 51px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    margin: 0 auto;
    background-size: 56px;
    background-position: center;
    background-repeat: no-repeat;

    & svg,
    & img {
      display: block;
      transition: 0.2s;
      width: 56px;
      height: 56px;
      max-width: 56px;
      max-height: 56px;
    }
  }

  &__text {
    color: #292929;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    padding-top: 12px;
    height: 30px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item.active .nav {
    &__text {
      color: #666;
    }

    &__ico {
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: 0 0 20px rgba(100, 67, 179, 0.5);

      & svg,
      & img {
        max-width: 43px;
        max-height: 43px;
      }
    }
  }

  @include xxs {
    &_click {
      display: block;
    }
    &__list {
      width: 100% !important;
      margin: 0;
    }
    &__btn {
      margin: 5px auto 20px;
    }
  }
  @include min-m {
    &__item:hover .nav {
      &__text {
        color: #666;
      }

      &__ico {
        border-radius: 15px;
        background-color: #ffffff;

        & svg,
        & img {
          max-width: 43px;
          max-height: 43px;
        }
      }
    }
  }

  /*page menu*/

  &.nav-manu {
    min-height: 90px;
  }

  .nav-manu & {
    &__item {
      max-width: 120px;
      margin: 10px 0;
      width: 25%;
    }

    &__text {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include xs {
        font-size: 10px;
      }
    }

    @include xs {
      &__ico {
        width: 45px;
        height: 45px;

        & svg {
          transition: 0.2s;
          width: 45px;
          max-width: 45px;
          max-height: 45px;
        }
      }

      &__item.active .nav {
        &__ico {
          & svg {
            max-width: 36px;
            max-height: 36px;
          }
        }
      }
    }
  }
}
