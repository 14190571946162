$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cpass-form{
  &__item{
    width:220px;
    @include l{ width:195px;}
  }
  &__btns{
    padding-top:15px;
    text-align: center;
  }
  @include s{
    width:480px;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    margin:0 -10px;
    &__item{
      width:calc(50% - 20px);
      margin:0 10px;

    }
  }
  @include xs{
    flex-direction: column;
    width:220px;
    &__item{
      width:100%;
      margin:0;
    }
  }


}
