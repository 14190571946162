$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.activation-rules {
    @media( max-width: 767px ) {
        background-color: #ffffff;
    }

    .modal__box {
        padding: 40px 20px;

        .activation-rules__content {
            width: 100%;
        }

        .activation-rules__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #333333;
            padding-bottom: 0;
            margin-bottom: 16px;
        }
    }

    &__wrap {
        @media( max-width: 767px ) {
            align-items: center;
        }
    }
    &__box {
        width: 540px;
        padding: 40px;
        @media( max-width: 767px ) {
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            max-width: none;
            width: 100%;
            max-width: 540px;
            padding: 40px 20px;
        }
    }

    &__list {
        padding: 0;
        margin: 0 0 15px 0;
        list-style-type: none;
        counter-reset: number;

        li {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4em;
            color: #333333;

            &:before {
                content: counter(number) '. ';
                counter-increment: number;
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.4em;
                color: #E77C3E;
                min-width: 20px;
                margin-right: 4px;
            }
            & + li {
                margin-top: 8px;
            }
        }
    }

    &__btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__btn {
        width: 100%;
        max-width: 260px;
    }
    &__close {
        font-weight: 700;
        font-size: 12px;
        line-height: 1.35em;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.12px;
        text-decoration-line: underline;
        color: #666666;
        margin-top: 20px;
        text-align: center;
    }
}