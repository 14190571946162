$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-banner-app {
  position:absolute;
  width: 800px;
  height: 70px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    box-sizing: border-box;
    width: 67px;
    height: 67px;
    background: #fff;
    border-radius: 15px;
    margin-left: 15px;
    display: flex;
    padding: 10px;
    & svg {
      height: 100%;
      margin: auto;
      fill: $violetEL;
    }
    //&_apple svg { margin-top: -4px; }
    &_android { padding: 13px;}
  }
  &__text {
    max-width: 400px;
    text-align: right;
  }
  &__link {
    color: #838383;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-decoration: underline;
    letter-spacing: 1px;
  }
  &__text-svg {
    width: 6px;
    height: 9px;
    fill: $violetL;
    margin-left: 5px;
  }

  @include xxs {
    width: 100%;
    height: 100px;
    flex-wrap: wrap;
    &__text {
      min-width:100%;
      text-align: center;
      padding-bottom:10px;
    }
    &__icon { margin:0 8px; }
  }
}