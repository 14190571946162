$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.profile-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  width: 480px;

  &__item {
    width: calc(50% - 20px);
    margin: 0 10px;

    input:disabled,
    textarea:disabled {
      opacity: 1;
      color: #2c2e2e;
      -webkit-text-fill-color: #2c2e2e;
    }
  }

  &__gender {
    display: flex;
    justify-content: space-between;
  }

  &__btns {
    text-align: center;
  }

  &__pp {
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: #545454;

    & a {
      text-decoration: underline;
      font-weight: 600;
      color: #545454;
      margin-right: 7px;
      display: inline-block;
    }
  }

  @include l {
    width: 430px;
  }
  @include s {
    width: 480px;
  }
  @include xs {
    width: 220px;
    display: block;
    margin: 0;
    &__item {
      margin: 0;
      width: 100%;
    }
  }
}
