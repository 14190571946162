$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-demo{
  //background:  #e9e9e9;
  padding:30px 0;

  & .game-box__block{ display:none !important;}
  & .more-game{ display:none !important;}

  &__title{
    color: #525252;
    font-size: 24px;
    font-weight: 700;
    padding-bottom:20px;
  }
  &__games{
    max-width:100%;
    overflow-x:auto;

    & .game-item{
      width: 200px;
    }
  }
  &__content{ padding-top:10px; }
}