$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.hero-desc {
  &__title {
    padding: 20px 0;
    text-align: center;
    color: #545454;
    font-size: 18px;
    font-weight: 700;

    & span {
      font-size: 24px;
    }

    @include s {
      font-size: 14px;
      & span {
        font-size: 18px;
      }
    }
  }

  &__text {
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    line-height: 2em;
    text-align: justify;
    max-height: 144px;
    overflow-y: auto;
    height: 140px;
    padding-right: 15px;

    &_rp {
      padding-right: 19px;
      margin-right: -20px;
    }

    @include s {
      font-size: 10px;
    }
  }
}
