$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.event-promo {
  text-align: center;
  color: $black;

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: $blackH;
  }

  &__content {
    width: 100%;
    max-width: 460px;
    margin: 20px auto;
    border-radius: 16px;
    background: $white;
    box-shadow: $box-shadow;
    padding: 12px 16px;
  }

  &__info-text {
    font-size: 32px;
    font-weight: 700;
    color: $violet;
    margin-block: 0 12px;
  }

  &__phrase {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin-block: 0 24px;
  }

  &__img {
    display: block;
    width: 100%;
    height: 180px;
    border-radius: 10px;
    object-fit: contain;
    margin-block: 0 24px;
  }

  &__btn {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1px;
    padding: 0;
  }

  &__details {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.12px;
    color: $grey22;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .popup__content {
    padding: 30px 20px;
  }
}