$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.freespin-item{

  box-sizing: border-box;
  position:relative;
  text-align: center;
  width:100%;
  height:100%;
  display:block;
  &_empty{
    background: url('/img/empty-dashboard-1.svg');
    & * { display:none; opacity:0;}
  }
  &__wrap{
    overflow:hidden;
    width:100%;
    height:100%;

    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__status{
    position:absolute;
    top:10px;
    right:10px;
    width:40px;
    height:40px;
    .bonus-item_popup &{
      top: -20px;
      right: -20px;
    }
    & svg{ width:40px; height:40px; }
  }
  &__amount{
    width:100%;
    padding:10px 0 0;
    color: #6443b3;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.37px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;

    & sup{
      font-size:13px;
      vertical-align: middle;
      line-height: 1px;
    }
  }
  &__str{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
    margin: 10px 0 9px;
    height:20px;
    font-size:14px;

  }
  &__game{
    max-height: calc(100% - 66px);
    width: 100%;
    & .game-item{ width:100%; margin:0;}
    & .game-item__pic:before{ padding-top:60%;}
    & .game-item__play { font-size:20px;}
  }
  @include l{
    &__status{
      top:5px;
      right:5px;
      width:30px;
      height:30px;
      & svg{ width:30px; height:30px; }
    }
  }
  @include s{
    &__str{ margin: 0 0 9px; }
  }
  @include xs{

    &__amount{
      padding:0;
      & sup{ font-size:12px;}
    }
    &__str{ margin-bottom:5px; font-size:12px; height:16px;}
    &__game{ max-height: calc(100% - 50px); }
  }
}

.autobonus .freespin-item__status{ top:-30px; right:-30px;}