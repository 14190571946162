$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.table-filter {
  &__title {
    padding: 30px 0 15px;
    color: #545454;
    font-size: 18px;
    font-weight: 500;
  }

  &__wrap {
    padding-top: 15px;
    display: flex;
    margin: 0 -10px;
  }

  &__item {
    width: 220px;
    max-width: calc(33.3334% - 20px);
    margin: 0 10px;
    box-sizing: border-box;
  }

  &__select {
    width: 100% !important;
    max-width: 100% !important;
  }

  @include xs {
    &__wrap {
      flex-wrap: wrap;
    }
    &__item {
      &:not(:first-child) {
        margin: 20px 10px;
        width: calc(50% - 20px);
        max-width: calc(50% - 20px);
      }

      &:first-child {
        width: calc(100% - 20px);
        max-width: calc(100% - 20px);
      }
    }
    &__input {
      width: 100% !important;
    }
  }
}
