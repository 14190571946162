$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar-nav {
  width: 185px;
  margin: 0 auto;

  &__item {
    width: 100%;

    &:not(:first-child) {
      border-top: solid 1px rgba(255, 255, 255, 0.25);
    }

    //Remove after Santa's slope event
    &:nth-child(2){
      border-top: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 15px 0;
    box-sizing: border-box;
    width: calc(100% + 20px);
    margin-left: -20px;
    position: relative;

    &.active {

      .is-easter {
        filter: $filterWhite;
      }

      .sidebar-nav__icon,
      .sidebar-nav__text,
      .sidebar-nav__arr {
        opacity: 1;
      }
    }

    &.is-holidays {
      text-transform: capitalize;

      &::after {
        content: 'New';
        min-width: 27px;
        height: 16px;
        background: $redDark;
        position: absolute;
        display: block;
        border-radius: 9px;
        color: #fff;
        font-weight: 700;
        font-size: 7px;
        line-height: 9px;
        letter-spacing: 0.24px;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__icon,
  &__text,
  &__arr {
    opacity: .25;
    transition: 0.3s;
  }

  &__icon {
    position: relative;
    min-width: 30px;

    & svg {
      fill: #fff;
      width: 24px;
      height: 24px;
    }

    &.is-easter {
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      filter: $filterGray;
    }
  }

  &__count {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    height: 12px;
    padding: 0 5px;
    background-color: #09af48;
    color: #fff;
    border-radius: 6px;
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
  }

  &__text {
    color: #fff;
    max-width: calc(100% - 40px);
    padding-left: 10px;
    padding-right: 35px;
    font-size: 14px;
  }

  &__arr {
    margin-left: auto;
    min-width: 9px;

    & svg {
      width: 9px;
      height: 12px;
      fill: #fff;
    }
  }
}

@include min-s {
  html:not(.menu-open) {
    .sidebar-nav {
      width: 55px;

      &__item {
        border: none;
        padding: 5px 0;
      }

      &__link {
        background: #2a134a;
        border-radius: 15px;
        width: 55px;
        height: 55px;
        margin: 0;
        justify-content: center;
        padding: 0;

        &.active,
        &:hover {
          padding: 0;

          .sidebar-nav__icon,
          .sidebar-nav__text,
          .sidebar-nav__arr {
            opacity: 1;
          }
        }

        &.is-holidays {
          &::after {
            content: 'New';
            width: 100%;
            height: 14px;
            background: $redDark;
            position: absolute;
            display: block;
            border-radius: 0;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.24px;
            right: 0;
            bottom: 0;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
          }
        }
      }

      &__icon,
      &__text,
      &__arr {
        opacity: 0.75;
      }

      &__icon {
        min-width: 20px;

        &.is-holidays-icon {
          min-width: 23px;
          background-size: 128%;
          background-position: center bottom -6px;
        }

        & svg {
          vertical-align: middle;
        }
      }
    }
  }
}
