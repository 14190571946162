@keyframes scaleInOut {

    from,
    to {
        transform: scale(1);
        opacity: 1;
    }

    25%,
    50%,
    75% {
        transform: scale(1.4);
        opacity: 0;
    }
}

@keyframes hideBlock {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0);
        opacity: 0;
        position: absolute;
    }
}

@keyframes showBlock {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
        position: relative;
    }
}