$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.payment-system {
  &__title {
    text-align: center;
    color: #353535;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    padding-bottom: 12px;
    @include min-s {
      padding-top: 10px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #353535;
    text-align: center;
    padding-bottom: 16px;
  }

  &__select {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    max-width: 100%;
    width: 333px;
    margin: 10px auto 24px;
    position: relative;

    @include min-s {
      &:before {
        position: absolute;
        content: '';
        width: calc(100% + 30px);
        height: 1px;
        background: #edeaf8;
        left: -16px;
        bottom: -20px;
      }
    }
  }

  &__selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 34px;

    & img {
      word-break: break-all;
    }
  }

  &__back {
    position: absolute;
    left: 16px;
    top: 40%;
    transform: translateY(-50%);
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(57, 32, 95, 0.28);
    border-radius: 38px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    box-sizing: border-box;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;

    & svg {
      width: 9px;
      height: 11px;
      margin: 0 0 -1px 5px;
    }
  }

  &__wrap {
    position: relative;
    top: -142px;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 200;
    box-sizing: border-box;
    max-width: 408px;
    margin: 0 auto;

    box-shadow: 0 4px 12px rgba(190, 179, 230, 0.57);
    border-radius: 8px;
    padding: 16px 20px 20px;

    @include min-s {
      top: -140px;
    }
  }

  &__list {
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  &__item {
    position: relative;
    margin: 2px;
    box-shadow: 0 1px 4px rgba(146, 127, 191, 0.4);
    border-radius: 4px;
    width: 100%;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6px;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px;

    & img {
      max-height: 40px;
      max-width: 100%;
      font-size: 10px;
      word-break: break-all;
    }
  }

  @include l {
    &__wrap {
      left: 0;
    }
  }
}
