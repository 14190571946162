$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.christmas-popup-wrap {
	display: block;
	padding: 50px 12px 0;

	.christmas-popup__text {
		margin: 0;
	}
}