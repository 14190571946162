$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.cash-crab-timer{

  position:relative;
  width: 250px;
  height: 250px;
  margin:30px auto;
  border-radius: 50%;

  & svg{
     transform: rotate(-90deg);
  }
  &__str{
    fill: none;
    stroke: #B1F9DC;
    stroke-linecap: round;
  }
  &__str-bg{
    fill: #6443B3;
    stroke: #9A85CC;
  }
  &__content{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#fff;
  }
  &__title{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }
  &__value{
    font-weight: 700;
    font-size: 46px;
    line-height: 56px;
    color: #FFFFFF;
  }
}