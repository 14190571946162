$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.autobonus {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
    color: #292929;
  }
  &__text {
    text-align: center;
    color: #353535;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 100%;
    margin-bottom: 20px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 194px;
    height: 100%;
    min-width: 245px;
    margin-bottom: 20px;
    padding: 12px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(72, 39, 111, 0.26);
    border-radius: 16px;
  }
  &__item-text {
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    color: #292929;
    text-transform: capitalize;
    &.is-uppercase {
      text-transform: uppercase;
    }
    &.is-small {
      font-size: 14px;
    }
  }
  &__item-offer {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #6443b3;
  }
  &__item-game {
    width: 198px;
    height: 132px;
    margin: 12px auto 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 14px;
  }
  &__item-btn {
    margin-top: 12px;
    font-weight: 700;
  }
}
