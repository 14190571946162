$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
/**************/
/*  game-box  */
/**************/




.game-box{
  display:flex;
  flex-wrap: wrap;
  position:relative;
  margin:0 -5px;
  .game-section &{
    flex-wrap: nowrap;
    @include xs{ flex-wrap: wrap; }
  }
  .game-page-demo &{ margin:0 0;}
  &.nowrap{ flex-wrap: nowrap; }

  &__block{
    .game-hall__games &{ display:none !important; }
    .tournament__games &,
    .new-game &{ display:none !important; }
    background:#dbdbdb;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    color: #6443b3;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include m{ font-size: 16px; }
  }
  &__link{
    padding-right:10px;
    color: #292929;
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    background:url('/img/arr-r-v.svg') 100% 50%  no-repeat ;
    @include m{ font-size: 11px; }
  }
}


.more-game{
  width:100%;

  padding:20px 0;
  text-align: center;
  &__btn{
    margin:0 auto;
    color: #292929;
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;

    border:none;
    background: none;
    cursor:pointer;
    text-decoration: underline;
  }
  & svg{ display:none;}
  .tournament__games &,
  .game-page-demo &{

    margin:5px;
    padding:0;
    width:auto;



    &__btn{
      min-width: 150px;
      background-color: #dbdbdb;
      //background:#eee;
      display:flex;
      justify-content: center;
      align-items: center;

      width:150px;
      height:calc( 100% - 5px);

      color: #292929;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-decoration: none;
      box-sizing: border-box;
      padding:0 15px;
      & svg{
        width:8px;
        height:10px;
        fill:$violet;
        margin:0 0 -2px 5px;
        display:inline;

      }
    }

  }
}

.game-box-home .game-box__block{ display:flex;}
.game-box-home .more-game{ display:none;}
.new-game .more-game{ display:none;}

.preloader-gamebox{
  width:100%;
}