$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.funid-g-or {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #C3C3C3;
  margin: 6px auto 16px;
  max-width: 173px;

  & span {
    padding: 0 10px;
  }

  &:before, &:after {
    content: " ";
    width: 65px;
    height: 1px;
    background-color: rgba(208, 208, 208, 0.5);
  }
}
