$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.gdpr-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 370px;

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #666666;
    padding-bottom: 36px;
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.21px;
    color: #353535;
    padding-bottom: 26px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url($cdn + "/alf/registration-page-bg/close_black.svg");
  }

  &__btns-item {
    background: #09AF48;
    border-radius: 80px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    width: fit-content;
    min-width: 192px;
    padding: 16px 30px;
    margin-bottom: 20px;
  }

  &__btns {
    margin-top: auto;
  }

  &__btns-item-no {
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.21px;
    color: #949494;
  }
}

.no-bonnus {
  min-height: 422px;
  width: 336px;
}