$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.sidebar-tab-container {
  flex-grow: 1;
  max-height: calc(100% - 569px);
  display: flex;
  overflow: hidden;
  &.user {
    max-height: calc(100% - 580px);
  }
  &__item {
    box-sizing: border-box;
    min-width: 100%;
    max-height: 100%;
    overflow: auto;
    &:first-child {
      transition: 0.3s;
      .sidebar-tab-container_last & {
        margin-left: -100%;
      }
    }
  }
  @media screen and (max-height: 680px) {
    display: none !important;
  }
}
