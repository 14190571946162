$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.footer-nav{
  display:block;
  min-width:300px;
  padding-top:10px;
  &__list{
    display:flex;
    flex-wrap: wrap;
    margin:0 -20px;
  }
  &__item{
    margin:0 20px;
    padding:6px 0;
  }
  &__link{
    color: #292929;
    font-size: 13px;
    font-weight: 500;

    &:hover,
    &-active{
      opacity:0.7;
    }
  }

  @include s{

  }
  @include xs{
    &__list{ margin:0 -10px;}
    &__item{ margin:0 10px;}
    &__link{ font-size:12px;}
  }

}