$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.page-popup-side {
  display: flex;
  &__menu {
    width: 190px;
    min-width: 190px;
    &-item {
      overflow: hidden;
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      border-bottom: solid 1px #e9e9e9;

      font-size: 13px;
      font-weight: 500;
      line-height: 40px;
      color: #292929;
      background: url('/img/arr-r-b.svg') 100% 50% no-repeat;
      transition: 0.3s;
      background-size: 6px 9px;
      padding-right: 15px;
      & span {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active {
        background: url('/img/arr-r-slick.svg') 100% 50% no-repeat;
        background-size: 6px 9px;
        padding-left: 20px;
        color: $violetD;
      }
    }
  }
  &__content {
    margin-left: 30px;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 5px;
    background-color: #ffffff;
    flex-grow: 1;
    max-width: calc(100% - 220px);
    box-sizing: border-box;
    padding: 20px 30px;
  }

  @include s {
    flex-direction: column;
    align-items: center;
    &__menu {
      width: 350px;
      max-width: 100%;
    }
    &__content {
      margin: 20px 0 0;
      width: 100%;
      max-width: 100%;
    }
  }
  @include xs {
    &__content {
      padding: 15px;
    }
  }
}
