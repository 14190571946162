$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-item {
  display: block;
  position: relative;
  margin: 5px;
  box-sizing: border-box;
  width: calc(20% - 10px);
  @include l {
    width: calc(25% - 10px);
  }
  @include s {
    width: calc(50% - 10px);
  }
  @include xxs {
    width: calc(100% - 10px);
  }
  &.is-game-hall {
    flex-shrink: 0;
    width: 168px;
    max-height: 110px;
    @include xxs {
      width: 148px;
      max-height: 98px;
    }
  }

  &_search {
    width: 100% !important;
    margin: 0;
  }

  .game-page-demo & {
    width: 160px !important;
    min-width: 160px !important;
  }

  .grid & {
    width: 100%;
    margin: 0;
  }

  &__pic {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
    &.is-widget {
      background-size: cover;
      background-position: 50% 50%;
    }
  }

  &__over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(154, 133, 204, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s;
    color: #fff;
    &:hover {
      opacity: 1;
    }
  }

  &__play {
    cursor: pointer;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    max-width: 100%;
    width: 100%;
    & span {
      display: inline-block;
      max-width: calc(100% - 50px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & svg {
      width: 17px;
      height: 24px;
      vertical-align: middle;
      margin-left: 5px;
      margin-top: 2px;
      fill: #fff;
    }
  }
  &__demo {
    cursor: pointer;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
  }

  &__m-link {
    @include s {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__label {
    display: none;
    position: absolute;
    .dashboard-bonus & {
      display: none;
    }
    &_new {
      display: flex;
      top: -10px;
      right: -10px;
      width: 41px;
      height: 41px;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 5px;
    }
    &_exclusive {
      display: block;
      top: -1px;
      left: -1px;
      right: unset;
      svg {
        width: 61px;
        height: 61px;
      }
    }
    &_dropAndWins {
      display: block;
      top: 0;
      left: 0;
      width: 54px;
      height: 50px;
      background-image: url($cdn + '/alf/ribbons/drops-wins.svg');
      background-repeat: no-repeat;
      background-size: contain;
      svg {
        display: none;
      }
    }
  }

  // HOME SECTION
  .game-section & {
    margin: 0;
    width: 16.6666667%;
    @include l {
      width: 20%;
      min-width: 20%;
    }
    @include s {
      width: 25%;
      min-width: 25%;
    }
    @include xs {
      width: 50%;
      min-width: 50%;
    }
    &__pic {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
    &__label {
      display: none;
    }
  }
  .new-game & {
    width: 100% !important;
    margin: 0;
    height: 100%;
    &__pic {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }
}
