$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-bnr {
  position: relative;
  min-height: 345px;

  @include min-xs {
    min-height: 315px;
  }

  @include min-s {
    min-height: 370px;
  }

  &.fi_lun {
    background-image: none;
    min-height: 450px;

    @include min-xs {
      min-height: 450px;
    }

    @include min-s {
      min-height: 500px;
    }
    @include min-m {
      min-height: 625px;
    }
  }

  &.ab-class {
    min-height: 500px;
  }

  &__content,
  &__icons {
    position: relative;
  }

  &__content {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 110;

    @include xs {
      padding-top: 0;
    }

    @include min-s {
      padding-top: 25px;
    }

    @include min-l {
      padding: 0;
    }
  }

  &__icons {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 448px;
    z-index: 100;

    @include xxs {
      max-width: 310px;
      bottom: 80px;
    }
  }
}

.bnr-paralax {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 45% 100%;
  z-index: 50;
  background-repeat: no-repeat;
  background-image: url($cdn + "/alf/banner-entrance-page/befor-registration/bg-before-reg-360.png");
  background-size: 540px 472px;

  @include min-xs {
    background-image: url($cdn + "/alf/banner-entrance-page/befor-registration/bg-before-reg-540.png");
    background-size: 768px 410px;
    background-position: 55% 75%;
  }

  @include min-s {
    background-image: url($cdn + "/alf/banner-entrance-page/befor-registration/bg-before-reg-768.png");
    background-size: 1024px 345px;
    background-position: 60% -2px;
  }

  @include min-m {
    background-image: url($cdn + "/alf/banner-entrance-page/befor-registration/bg-before-reg-1024.png");
    background-size: 1280px 375px;
    background-position: 60% -2px;
  }

  @include min-l {
    background-image: url($cdn + "/alf/banner-entrance-page/befor-registration/bg-before-reg-1280.png");
    background-size: 1440px 400px;
    background-position: 60% -2px;
  }

  &.fin-style {
    background-image: url($cdn + "/alf/new-main-banner/fi-banner/bg 360-540.png");
    background-size: cover;
    background-position: center;

    @include min-xs {
      background-image: url($cdn + "/alf/new-main-banner/fi-banner/bg 540-768.png");
      background-size: 768px 477px;
    }

    @include min-s {
      background-image: url($cdn + "/alf/new-main-banner/fi-banner/bg 768-1024.png");
      background-position: 62% 100%;
      background-size: 1024px 516px;
    }

    @include min-m {
      background-image: url($cdn + "/alf/new-main-banner/fi-banner/bg 1024-1440.png");
      background-position: 63% 0;
      background-size: 1440px 500px;
    }
  }

  //@include min-l {
  //  background-position: 53% 0;
  //}

  &__i {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    @include xs {
      visibility: hidden;
    }
  }

  &__in {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__character1 {
    width: 621px;
    height: 412px;
    top: 176px;
    right: calc(50% + 181px);
  }

  &__character2 {
    width: 387px;
    height: 285px;
    top: 136px;
    right: calc(50% + 130px);
  }

  &__character3 {
    width: 387px;
    height: 285px;
    top: 136px;
    left: calc(50% + 158px);
  }

  &__character4 {
    width: 419px;
    height: 433px;
    top: 136px;
    left: calc(50% + 344px);
  }

  &__stub1 {
    width: 203px;
    height: 203px;
    top: -51;
    right: calc(50% + 518px);
  }

  &__stub2 {
    width: 124px;
    height: 124px;
    top: -27px;
    right: calc(50% + 462px);
  }

  &__stub3 {
    width: 709px;
    height: 715px;
    top: -464px;
    left: calc(50% + 365px);
  }

  @include s {
    &__character1,
    &__character4 {
      display: none;
    }
  }
}
