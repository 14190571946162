$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data-popup {
  font-size: 14px;
  line-height: 140%;
  color: #333;
  text-align: center;
  align-items: center;
  padding: 0 30px;

  @include xxs {
    padding: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    max-width: 540px;
    width: 100%;
    border-radius: 7px;
    padding: 40px;

    @include xxs {
      min-height: 100vh;
      justify-content: center;
      border-radius: 0;
      margin: 0;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 115%;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__btn {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__decline {
    font-size: 12px;
    line-height: 140%;
    font-weight: 700;
    text-decoration: underline;
    color: #666;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 0;
  }
}

.missed-data .ng-invalid.ng-touched + label .g-check-block {
  border: 1px solid red;
  background: #ff5d50;
}
