$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.lang-popup{
  width:320px;
  background: #fff;
  & .popup__title{
    width: 180px;
    color: #545454;
    font-size: 24px;
    font-weight: 400;
  }
  &__wrap{ text-align: center}
  &__list{
    justify-content: center;
    display:inline-flex;
    flex-wrap: wrap;
    margin:0 -7px;
  }
  &__item{
    border-radius:50%;
    background: #fff;
    padding:3px;
    border: solid 2px #fff;
    margin:7px;
    cursor:pointer;

    &.active{ border-color: #b7b3dc;}
    &-w{
      box-shadow: 0 0 10px rgba(15, 4, 83, 0.35);
      width:37px;
      height:37px;
      border-radius:50%;
      & .lang-popup__item-w{ box-shadow: none;}
    }
    & img{
      width:37px;
      height:37px;
      font-size: 10px;
      word-break: break-all;
    }
  }
  &__btn-box{
    text-align: center;
    padding-top:20px;
  }
  &__btn{
    min-width:80px;
  }
  /*
  @include xs{
    width:300px;
    //min-height: 100vh;
    &__wrap{
      width:220px;
      max-width: 220px;
      margin: 0 auto;
    }
  }
  */
}