$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.event-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 50px;
  border-radius: 80px;
  background-image: $border-gradient;
  box-shadow: 0 0 30px 0 rgba(229, 135, 255, 0.40);

  &__img {
    position: absolute;
    bottom: -6px;
    left: -21px;
    width: 74px;
    height: 68px;
    background-image: url($cdn + "/alf/christmas/santa-menu-mob.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 132%;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    inset: 2px;
    background-image: linear-gradient(90deg, #7E2274 0%, #BD4445 51.5%, #5B55CC 100%);
    filter: drop-shadow(0px 0px 8px #3F37B9);
    border-radius: 80px;
  }

  &__content {
    max-width: 131px;
    padding-block: 8px;
  }

  &__stub {
    display: block;
    width: 50px;
  }

  &__content,
  &__title,
  &__text {
    display: block;
    text-align: left;
    z-index: 1;
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: $light-grey;
    word-break: break-word;
    hyphens: auto;
  }

  &__text {
    font-size: 14px;
    font-weight: 700;
    text-shadow: 0 0 30px rgba(255, 255, 160, 0.80);
    color: $white;
    word-break: break-word;
    hyphens: auto;
  }
}
