$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.dashboard-new {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: url('/img/discover_1.svg') 50% no-repeat;
    &__title {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        padding: 25px 15px 0;
    }
}