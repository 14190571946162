$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.missed-data {
  background: $violetEEL;
  color: #333;
  font-size: 14px;
  line-height: 140%;

  &__container {
    position: relative;
    z-index: 51;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    min-height: 100vh;
    display: grid;
    grid-template-rows: min-content auto;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;

    @include xs {
      grid-gap: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__logo {
    display: block;
    width: 151px;
    margin: 0 auto;

    @include xs {
      width: 107px;
    }
  }

  &__content {
    position: relative;
    max-width: 720px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 7px;

    @include xs {
      padding: 40px 30px;
    }
  }

  &__terms {
    display: block;
    max-height: 340px;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 165%;
    padding-right: 16px;
    padding-bottom: 70px;
    overflow-y: auto;
    overflow-x: hidden;

    @include s {
      padding-bottom: 50px;
    }

    &.is-open {
      max-height: fit-content;
    }
  }

  &__terms-wrapper {
    position: relative;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 55px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      background-size: cover;
    }
  }

  &__info-section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__info-inner {
    display: block;
    max-height: 135px;
    overflow: hidden;

    @include xs {
      max-height: 120px;
    }

    .is-open & {
      max-height: fit-content;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__head {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 115%;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .g-formbox-item {
    width: 100%;
    max-width: 407px;
    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__btn {
    min-width: 260px;
    margin-bottom: 20px;

    @include xs {
      min-width: auto;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &__decline {
    font-size: 12px;
    line-height: 140%;
    font-weight: 700;
    text-decoration: underline;
    color: #666;

    &:hover {
      text-decoration: none;
    }
  }

  .bnr-paralax {
    overflow: hidden;
    opacity: 0.5;
    background-position: 53% 0;

    &__stub1 {
      width: 400px;
      height: 400px;
      right: 0;
      left: 0;
      margin: auto;
    }

    &__stub2 {
      width: 200px;
      height: 200px;
      top: 10px;
    }
  }

  h1, h2, p {
    margin: 0;
  }

  .info-page {
    padding: 0;

    &__container {
      width: auto;
      max-width: none;
      padding: 0;
    }

    &__section {
      border: none;
    }

    &__header {
      min-height: auto;
      height: auto;
      display: block;
      background: none;
      padding: 0;
    }

    &__block {
      display: block;
    }

    &__content {
      padding: 0;
    }

    &__title {
      padding: 0;
      margin-bottom: 8px;
      font-size: 18px;
      line-height: 115%;
      font-weight: 700;
      color: #333;
      cursor: initial;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::after {
        display: none;
      }
    }

   &__table {
      overflow-x: auto;
    }

    &__text {
      padding: 0;

      td, th {
        padding: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      a {
        color: #333;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__subtitle {
      min-height: auto;
      padding: 0;
      font-size: 16px;
      line-height: 115%;
      font-weight: 700;
      color: #333;
      cursor: initial;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &::after {
        display: none;
      }
    }

    &__text {
      padding: 0;

      a {
        color: #333;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.cookie-banner {
  left: 0;
}

.no-scroll {
  overflow: hidden;
}

.dropdown {
  display: flex;
  width: max-content;
  margin: 0 auto;
  align-items: center;
  font-size: 14px;
  line-height: 180%;
  color: #353535;

  &__btn {
    &:not(:last-child) {
      margin-right: 9px;
    }

    &_less {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_more {
      .is-open & {
        display: none;
      }
    }
  }

  &__arrow {
    width: 8px;
    height: 14px;
    fill: #292929;

    .is-open & {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  display: flex;
  width: max-content;
  margin: 0 auto;
  align-items: center;
  font-size: 14px;
  line-height: 180%;
  color: #353535;

  &__btn {
    &:not(:last-child) {
      margin-right: 9px;
    }

    &_less {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_more {
      .is-open & {
        display: none;
      }
    }
  }

  &__arrow {
    width: 8px;
    height: 14px;
    fill: #292929;

    .is-open & {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  display: flex;
  width: max-content;
  margin: 0 auto;
  align-items: center;
  font-size: 14px;
  line-height: 180%;
  color: #353535;

  &__btn {
    &:not(:last-child) {
      margin-right: 9px;
    }

    &_less {
      display: none;

      .is-open & {
        display: block;
      }
    }

    &_more {
      .is-open & {
        display: none;
      }
    }
  }

  &__arrow {
    width: 8px;
    height: 14px;
    fill: #292929;

    .is-open & {
      transform: rotate(180deg);
    }
  }
}
