$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.verification-msg {
  --sidebar: 120px;
  width: 100%;
  min-height: 26px;
  flex: none;
  padding-left: var(--sidebar);

  // game page styles
  .game-page & {
    --sidebar: 0;

    @media (orientation: landscape) {
      --sidebar: 52px;
      position: fixed;
      z-index: 10;
    }
  }
  .game-page:has(.fullscreen-open) & {
    display: none;
  }

  &:not(:has(.is-verification-changed)) {
    display: none;
  }

  &__alert {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: var(--sidebar);
    right: 0;
    min-height: inherit;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.02em;
    line-height: 110%;
    padding: 6px;
    color: #fff;
    z-index: 991;


    &.is-verification-changed {
      display: flex;
    }

    &.is-verified {
      background-color: #09AF48;
    }

    &.is-not-verified {
      background-color: #EE0E0E;
    }

    a {
      color: inherit;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__text {
    text-align: center;
  }

  &__icon {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__arr {
    flex-shrink: 0;
    width: 7px;
    height: 10px;
    margin-left: 7px;
    background: var(--icon-arr_r) no-repeat;
    filter: $filterWhite;
  }

  &__close {
    flex-shrink: 0;
    border: 0;
    padding: 0;
    width: 14px;
    height: 14px;
    margin-left: 2px;
    background-image: var(--icon-icon_close);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;
    background-color: transparent;
    filter: $filterWhite;
  }

  @include xs {
    --sidebar: 0;

    &__alert {
      position: static;
      height: 100%;
    }
  }

  @include xxs {
    &__text {
      text-align: left;
    }
  }
}