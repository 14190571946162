.easter-popup {
    margin: 15% auto;
    width: 100%;
    max-width: 308px;
    position: relative;
    background: $c-yellow;
    border-radius: 22px;
    padding: 2px 2px 7px;
    font-family: $f-jost;

    &__close {
        position: absolute;
        top: -17px;
        right: -16px;
        width: 38px;
        height: 41px;
        z-index: 2;
        padding: 2px 2px 5px;
        background-image: url($cdn + '/easter/2023/elements/popup-ellipse.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        outline: none;
        border: none;
        background-color: transparent;

        @include hover() {
            &::before {
                background-color: $c-violet4;
                box-shadow: inset 1px 1px 2px rgba(235, 174, 221, 0.4), inset 0px -2px 6px #450B38;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
        }

        &::before {
            z-index: 1;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            box-shadow: none;
            background-color: $c-violet3;
            transition-property: background-color, box-shadow;
            transition-duration: .2s;
        }

        &::after {
            width: 16px;
            height: 16px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 11px;
            z-index: 2;
            background-image: url($cdn + '/easter/2023/icons/close.svg');
            background-size: 16px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__inner {
        width: 100%;
        background-image: linear-gradient(180deg, #F4D165 0%, #F4D165 100%);
        border-radius: 19px;
        padding: 22px 14px 17px;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 41px;
            height: 18px;
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 2;
            background-image: url($cdn + '/easter/2023/elements/shine-panel.png');
            background-repeat: no-repeat;
            background-size: contain;
            pointer-events: none;
        }
    }
    body.rtl-lang &__inner {
      direction: rtl;
    }
    &__title {
        text-align: center;
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.45em;
        color: $c-violet;
        text-transform: capitalize;
    }

    &__bonus {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.45em;
        text-align: center;
        color: $c-violet;
        margin-bottom: 16px;
    }

    &__text {
        text-align: center;
        margin-bottom: 16px;

        p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.15em;
            color: $c-violet;
        }
    }

    &__list {
        margin: 0 0 23px 0;
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            font-weight: 600;
            font-size: 14px;
            line-height: 1.15em;
            color: $c-violet;

            &:not(:last-child) {
                margin-bottom: 1px;
            }
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
    }

    &__terms {
        width: 100%;
    }
    body.rtl-lang &__terms {
      direction: rtl;
    }

    &__terms-trigger {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: $c-yellow2;
        border-radius: 12px;
        min-height: 67px;
        padding: 20px 40px 20px 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.45em;
        color: $c-violet;
        outline: none;
        border: none;

        .easter-popup__terms.is-opened & {
            border-radius: 12px 12px 0 0;

            &:after {
                transform: translateY(-50%) rotate(270deg);
            }
        }

        &:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            background-image: url($cdn + '/easter/2023/icons/slider-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%) rotate(90deg);
            transition: transform .2s ease;
        }
    }

    &__terms-inner {
        width: 100%;
        display: none;
        background: $c-yellow2;
        border-radius: 0 0 12px 12px;
        padding: 0 16px 12px;

        .easter-popup__terms.is-opened & {
            display: block;
        }
    }

    &__terms-list {
        margin: 0;
        padding: 0;
        width: 100%;

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.15em;
            color: $c-violet;
            margin-bottom: 12px;
        }

        ul,
        ol {
            width: 100%;
            margin-bottom: 12px;
            list-style-position: inside;
        }

        li {
            position: relative;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.15em;
            color: $c-violet;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        table {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.15em;
            color: $c-violet;
            margin-bottom: 12px;
            thead {
              white-space: break-spaces;
              font-size: 12px;
            }
            td {
                padding: 0 3px;
            }
        }
    }
}