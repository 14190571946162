$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.staff-promo {
  display: flex;
  margin: -10px;

  &__item {
    width: calc(50% - 20px);
    margin: 10px;

    &.full {
      width: calc(100% - 20px);
    }
  }

  &__img {
    display: flex;
    height: 250px;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__offer {
    text-align: center;
    margin: auto;
    text-align: center;
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    font-weight: 900;
    text-shadow: 0px 0.14em 1px #0F1F4E;

    @include min-s {
      font-size: 22px;
      line-height: 26px;
    }
  }

  &__content {
    position: relative;
    background: #fff;
    box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
    border-radius: 0 0 5px 5px;
    height: 200px;
    padding: 50px 20px 20px;
  }

  &__name {
    text-align: center;
    color: #666666;
    font-size: 18px;
    font-weight: 700;
  }

  &__text {
    margin-top: 25px;
    color: #292929;
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
  }

  &__btn-box {
    position: absolute;
    top: -25px;
    left: 0;

    width: 100%;
    height: 50px;
    text-align: center;
  }

  @include xs {
    flex-wrap: wrap;

    &__img {
      height: 350px;
    }

    &__item {
      width: calc(100% - 20px);
    }
  }

  @include xxs {
    &__img {
      height: 250px;
    }
  }
}