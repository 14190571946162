$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;


.tournaments{
  padding-bottom:50px;
}

.tournament{
  margin-top:20px;
  box-shadow: 0 0 10px rgba(100, 67, 179, 0.3);
  border-radius: 5px;
  background-color: #ffffff;
  &__row{ display:flex; }
  &__left{ width:465px; min-width:465px; }
  &__right{ width:calc( 100% - 465px );  }
  &__header{
    position:relative;
    cursor:pointer;
    color: #292929;
    &:hover{
      & .tournament__gradient{ color:#fff;}
    }

  }
  &__header-row{
    display:flex;
    align-items: center;
    height:80px;
    justify-content: space-between;
    //&_justify{ justify-content: space-between}
  }
  &__header-row-group{ display:flex;}
  &__gradient{
    background-size:300%;
    border-radius:5px 0 0 5px;
    transition:0.3s;
    background-position: left top;
    .tournament-open &{ color:#fff;}
  }
  &__name{
    padding:0 20px;
    font-size: 16px;
    max-width: 220px;
  }
  &__win{
    font-size: 26px;
    font-weight: 400;
    padding-right:30px;
  }
  &__status{
    position:absolute;
    width:45px;
    height:45px;
    top:-9px;
    right:-9px;
    & svg{
      width:45px;
      height:45px;
    }
  }
  &__date{
    font-size: 14px;
    font-weight: 500;
    padding:0 25px;
  }
  &__m-bet{
    font-size: 14px;
    font-weight: 500;
    padding:0 25px;
  }
  &__m-bet-val{
    color: #666666;
    font-size: 12px;
    font-weight: 400;
  }
  &__timer{
    flex-grow: 1;
    text-align: center;
  }



  &__body{
  }

  &__banner{ 
    height:280px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  &__grey{ background-color: #e9e9e9; }
  &__desc{
    padding:25px 30px ;
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
  &__btns{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    padding:0 30px 30px;
  }
  &__btns-item{
    height:50px;
    line-height: 50px;
    margin-right: 20px;
  }

  &__table{
    min-height:100%;
    border-top:solid 1px #e9e9e9;
    display:flex;
  }
  &__table-td{
    position:relative;
    &:first-child{
      min-width:45%;
      border-right:solid 1px #e9e9e9;
    }
    &:last-child{ min-width:55%; }
  }

  &__games{
    border-top:solid 1px #e9e9e9;
    padding:20px 20px 15px;
    position:relative;

    & .game-item{
      min-width:140px;
      margin-bottom:10px;
    }
  }
  &__games-wrap{
    padding:0 5px;
    max-width:100%;
    overflow-x:auto;
  }
  &__games-scroll{
    position:absolute;
    bottom: 5px;
    left: 0;
    width:100%;
    height:26px;
    background:#fff;
    transition:0.3s;
    .tournament__games:hover &{ height:5px; }
  }



  @include l{
    &__left{ width:382px; min-width:382px; }
    &__right{ width:calc( 100% - 382px ); }
    &__header-row-group{ display:block;}
    &__name{ font-size: 14px; }
    &__win{ font-size: 21px; }
    &__date{ font-size: 12px; padding-bottom:10px;}
    &__m-bet{ font-size: 12px; }
    &__m-bet-val{ font-size: 10px; }
    &__banner{ height:230px;}
  }
  @include s{
    &__left{ width:274px; min-width:274px; }
    &__right{ width:calc( 100% - 274px ); }
    &__header-row{ flex-direction: column; justify-content: center; align-items: flex-start; padding:0 13px;}
    &__header-row-group{ display:flex; }
    &__name{ padding:0;}
    &__date{ padding:0; font-size: 10px;}
    &__m-bet{ font-size: 10px; padding: 0 0 0 10px;}
    &__timer{ flex-grow: 0; font-size: 14px; padding-top:10px;}
    &__banner{ height:165px;}
  }
  @include xs{
    &__row{ display:block;}
    &__left{ width:100%; min-width:100%; }
    &__right{ width:100%; }
    &__gradient{ border-radius: 5px 5px 0 0 ;}
    &__banner{ height:auto; padding-top:50%;}
    &__games-scroll{ display:none;}
  }
}




/* === GRADIENTS === */
.tournament:nth-child(4n+1) .tournament__gradient{ background-image: linear-gradient(to right, #efe4ff 0%, #dcf3ff 35%, #1a485f 70%,  #210749 100%);  }
.tournament:nth-child(4n+2) .tournament__gradient{ background-image: linear-gradient(to right, #def2f7 0%, #fdd0e1 35%, #7bd0e3 70%,  #fb3e82 100%);  }
.tournament:nth-child(4n+3) .tournament__gradient{ background-image: linear-gradient(to right, #fae9fb 0%, #dccfff 35%, #db6290 70%,  #4821a7 100%);  }
.tournament:nth-child(4n+4) .tournament__gradient{ background-image: linear-gradient(to right, #ffdacb 0%, #fecfdf 35%, #ff793f 70%,  #db618e 100%);  }
.tournament-open .tournament__gradient{ background-position:right top !important; border-radius:5px 0 0 0;}
.tournament__header:hover .tournament__gradient{ background-position:right top !important; }






/*  ====  ANIMATION  ====  */


.tournament__body.ng-enter, .tournament__body.ng-leave {
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s;
}

.tournament__body.ng-enter,
.tournament__body.ng-leave.ng-leave-active {
  opacity:0;
  max-height:0px;
}

.tournament__body.ng-leave,
.tournament__body.ng-enter.ng-enter-active {
  opacity:1;
  max-height:1000px;
}

.t-winners{
  width:280px;
  max-width:100%;
  margin:0 auto;
  position:absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding:10px;
  &__top{
    color: #545454;
    font-size: 16px;
    font-weight: 700;
    padding:20px 10px 15px 0;
  }
  &__row{ display:flex;}
  &__t-item {
    min-width: 50%;
    overflow: hidden;
    margin-right:5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
    &__item{
    min-width: calc(50% - 5px);
    padding:10px 0;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    margin-right:5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
  }
  &__content{
    max-height:calc( 100% - 65px);
    overflow-y: auto;
  }
  @include l{
    &__top{
      font-size: 12px;
      font-weight: 700;
    }

  }
}







.t-list{
  padding:10px 30px;
  &_prize{
    width:200px;
    margin:0 auto;
    padding:10px 30px;
  }
  &_winners{


  }
  &__content{
    max-height:calc( 100% - 65px);
    overflow-y: auto;
  }
  &__item{
    height:40px;
    line-height:40px;
    display:flex;

    &:nth-child(1) .t-list__ico{ opacity:1;}
    &:nth-child(2) .t-list__ico{ opacity:0.6;}
    &:nth-child(3) .t-list__ico{ opacity:0.4;}
  }
  &__title{
    color: #545454;
    font-size: 16px;
    font-weight: 700;
    padding:20px 0 15px;

    display:flex;
    justify-content: space-between;

    &-item{width: 50%; padding-left:5px;}
    //&-item:nth-child(1){}
    //&-item:nth-child(2){width:103px;}

  }

  &__ico{
    background:url('/img/place.svg') 50% 11px no-repeat;
    width:25px;
    min-width:25px;
    text-align: center;
    opacity: 0;

    & span{
      display:inline-block;
      line-height:35px;
      font-size:11px;
      color:#fff;
    }
  }
  &__index{
    //background:lightblue;
    padding-left:5px;
    width:40px;
    min-width:30px;
    box-sizing: border-box;
    color: $violetD;
    font-size: 14px;
    font-weight: 600;

  }
  &__sum{
   //background:lightgreen;
    font-size: 12px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
  }
  &__username{
    //background:lightgoldenrodyellow;
    font-size: 12px;
    font-weight: 500;
    flex-grow: 1;
    width: calc(50% - 50px);
    max-width: calc(50% - 50px);
    overflow: hidden;
    margin-right:5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include l{
    padding:10px 15px;
    &__title{
      font-size: 12px;
      font-weight: 700;

    }
    &__index{
      min-width:25px;
      width:25px;
    }
  }
  @include s{

    &_prize{
      width:110px;
    }

    padding:10px 8px;
    &__ico{display:none;}
    &__sum{font-size: 11px;}
    &__username{font-size: 11px; margin-right:3px;}
    &__index{font-size: 11px; min-width: 20px; width: 20px;}
  }

  @include xs{
    &-item:nth-child(1){}
    &-item:nth-child(2){}
  }


}
