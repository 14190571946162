$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;


.login-popup{
  background: #fff url('/img/bg_login.svg') no-repeat center calc(100% + 30px);

  &__btn-box{padding:20px 0 10px;}
  &__formbox{
    display:block;
  }

  &__formbox{
    min-height: 420px;
  }
}

.login-popup.popup__small {
  padding: 24px;
  .g-formbox-item {
    width: 252px;
    .g-input {
      width: 252px;
    }
  }
}

.login-bank__right .g-btn-green{
  background-color: $violet;
  color:#fff;
  &:hover{ background-color: $violetL; }
}
