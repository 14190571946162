$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.game-page-header {
    width: 100%;
    min-height: 52px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: linear-gradient(0deg, #2F1E4A -0.11%, #39205F 100.22%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #292230;
    transform: all .3s;
    &.fullscreen-open {
        min-height: 32px;
        .game-page-header__btn {
            display: none;
        }
        .game-page-header__btn.is-fullscreen {
            display: block;
            width: 24px;
            height: 24px;
            background-image: var(--icon-game-page-fs-close);
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &__wrap {
        display: flex;
    }
    &__btn {
        width: 36px;
        height: 36px;
        background-color: $violetGrey;
        border-radius: 8px;
        border: none;
        margin-left: 8px;
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        @include hover {
            background-color: $greenD;
        }
        &.is-back {
            margin-left: 0;
            margin-right: auto;
            background-image: var(--icon-game-page-back);
        }
        &.is-favourites {
            background-image: var(--icon-game-page-favourites);
        }
        &.f_active {
            background-image: var(--icon-game-page-favourites-on);
        }
        &.is-leaderboard {
            background-image: var(--icon-game-page-leaderboard);
        }
        &.is-deposit {
            background-image: var(--icon-game-page-deposit);
        }
        &.is-fullscreen {
            background-image: var(--icon-game-page-fs-open);
        }
    }
    @media (orientation: landscape) {
        position: relative;
        width: 52px;
        height: 100%;
        flex-direction: column;
        padding: 12px 8px;
        left: 0;
        &.fullscreen-open {
            width: 40px;
            height: 100%;
            padding: 20px 8px;
            .game-page-header__btn.is-fullscreen {
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &__wrap {
            flex-direction: column-reverse;
            margin-right: 0;
        }
        &__btn {
            margin: 0 0 8px 0;
            &.is-fullscreen {
                margin: auto 0 0px 0;
            }
        }
    }
}