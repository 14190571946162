$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 990;

  &__inner {
    display: none;

    @include xs {
      height: 55px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      background-color: $violetD;
      padding: 0 30px;
      display: flex;
      align-items: center;
    }

    @include xxs {
      padding: 0 15px;
    }
  }

  &__burger {
    width: calc(50% - 40px);

    & a {
      padding: 5px;
      margin-left: -5px;
      display: inline-block;
    }

    & svg {
      width: 30px;
      height: 20px;
      fill: #fff;
    }
  }

  &__logo {
    margin-top: -4px;

    & img {
      width: 80px;
      height: 36px;

    }
  }

  &__btns {
    margin-left: auto;

  }

  &__login {
    & a {
      padding: 5px;
      margin-right: -5px;
      display: inline-block;
    }

    & svg {
      width: 27px;
      height: 22px;
      fill: #fff;
    }
  }

  &__balance-btn {
    border-radius: 13px;
    background-color: #9a85cd;
    height: 26px;
    line-height: 26px;
    padding: 0 8px;

    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    display: inline-block;
  }



  &__burger {
    .header.user & {
      width: 45px;
    }
  }
}