$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
html {
  @include xs {
    &.menu-open,
    &.menu-open body {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}

.linda-has-popup,
.linda-has-popup body {
  height: 100%;
  position: relative;
  overflow: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  
}

.page-body {
  max-width: 100%;
  &__container {
    padding-left: 120px;
  }

  @include xs {
    &__container {
      padding-left: 0;

      &.reg-page {
        padding-top: 0;
      }
    }
  }
}

.page-body-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  &__content {
    width: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background: #ecebf6;

    &.is-hide {
      display: none;
    }
  }
}

.container {
  box-sizing: border-box;
  max-width: 1180px;
  padding: 0 50px;
  margin: 0 auto;
  @include m {
    padding: 0 45px;
  }
  @include s {
    padding: 0 35px;
  }
  @include xs {
    padding: 0 30px;
  }
  @include xxs {
    padding: 0 15px;
  }
}
