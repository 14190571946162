$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.main-slider {
  padding-top: 12px;
}

.register-style {
  background: linear-gradient(179.59deg, #102272 3.81%, #5944CF 67.35%, #442059 105.02%);
  box-shadow: 0 4px 4px rgba(72, 39, 111, 0.26);
  border-radius: 16px;
  margin: 0 auto;
  max-width: 328px;
  padding-bottom: 25px;
  padding-top: 12px;
  min-height: 274px;
  position: relative;


  &.is-registration {
    min-height: auto;
  }

  &__wrap {
    &.is-show {
      .is-more {
        background: #5D67D8;
        position: relative;
        min-width: 76px;

        span {
          display: none;
        }

        img {
          display: flex;
          margin: 0 auto;
        }
      }

      .value-btn {
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }

      .paynplay-input__wrap {
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }

    }
  }
}

#button-animation {
  position: absolute;
  bottom: -35px;
  pointer-events: none;
}

.paynplay {
  display: block;
  padding-top: 16px;

  &__min-bet {
    color: #FFE24A;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding-bottom: 8px;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #fff;
    padding-bottom: 6px;

    &.show-guest{
      font-size: 24px;
    }
  }

  &__title-sub {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    padding-bottom: 8px;
  }

  &__form {
    max-width: 302px;
    margin: 0 auto 0;
    position: relative;
  }

  &__cash-buttons {
    display: flex;
    justify-content: center;

    &.value-btn {
      transition: .35s linear;
      opacity: 1;
      visibility: visible;
      z-index: 5;
    }
  }

  &__cash-button {
    display: inline-block;
    //padding: 12px 7px;
    padding: 0;
    text-align: center;
    min-width: 64px;
    min-height: 44px;
    background-color: #fff;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    color: #9A85CC;
    transition-property: background-color, color, border-color;
    transition-duration: 250ms;
    transition-timing-function: ease;
    cursor: pointer;
    border: 2px solid transparent;

    &.active {
      background: #7C35B5;
      border: 2px solid #9E96FF;
      box-shadow: 0 4px 3px rgba(87, 9, 119, 0.34);
      color: #FFFFFF;
    }

    span {
      display: block;
    }

    img {
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      object-fit: none;
      height: 38px;
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  &__submit {
    display: block;
    padding: 16px;
    border: none;
    border-radius: 25px;
    background-color: $greenD;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 1;
    width: 100%;
    color: #fff;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease;
    margin: 16px auto 0;
    max-width: 284px;
  }
}

.paynplay-input {
  position: relative;

  &__wrap {
    display: grid;
    grid-template-columns: 106px 50px 50px;
    transition: .35s linear;

    opacity: 0;
    visibility: hidden;
    z-index: 10;
    width: fit-content;

    position: absolute;
    top: 1px;

    &.is-dep {
      opacity: 1;
      visibility: visible;
      position: relative;
      grid-template-columns: 284px;
      margin: 0 auto;

      .form-wrap {
        display: flex;
      }
    }
  }

  &__btn {
    position: relative;
    padding: 0;
    flex-shrink: 0;
    width: 50px;
    border: none;
    background-color: #ecebf6;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    cursor: pointer;
    z-index: 10;

    &--minus {
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDIwQzE1LjUyMjggMjAgMjAgMTUuNTIyOCAyMCAxMEMyMCA0LjQ3NzE1IDE1LjUyMjggMCAxMCAwQzQuNDc3MTUgMCAwIDQuNDc3MTUgMCAxMEMwIDE1LjUyMjggNC40NzcxNSAyMCAxMCAyMFpNMTMuNDM3NSA4LjQzNzVDMTQuMzAwNCA4LjQzNzUgMTUgOS4xMzcwNiAxNSAxMEMxNSAxMC44NjI5IDE0LjMwMDQgMTEuNTYyNSAxMy40Mzc1IDExLjU2MjVINi41NjI1QzUuNjk5NTUgMTEuNTYyNSA1IDEwLjg2MjkgNSAxMEM1IDkuMTM3MDYgNS42OTk1NSA4LjQzNzUgNi41NjI1IDguNDM3NUgxMy40Mzc1WiIgZmlsbD0iIzlBODVDQyIvPjwvc3ZnPg==);
      background-position: 17px center;
    }

    &--plus {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDIwQzE1LjUyMjggMjAgMjAgMTUuNTIyOCAyMCAxMEMyMCA0LjQ3NzE1IDE1LjUyMjggMCAxMCAwQzQuNDc3MTUgMCAwIDQuNDc3MTUgMCAxMEMwIDE1LjUyMjggNC40NzcxNSAyMCAxMCAyMFpNMTEuNTE3OSA4LjQzNzVIMTMuNDM3NUMxNC4zMDA0IDguNDM3NSAxNSA5LjEzNzA2IDE1IDEwQzE1IDEwLjg2MjkgMTQuMzAwNCAxMS41NjI1IDEzLjQzNzUgMTEuNTYyNUgxMS41MTc5VjEzLjQ4MjFDMTEuNTE3OSAxNC4zMjA0IDEwLjgzODMgMTUgMTAgMTVDOS4xNjE3MSAxNSA4LjQ4MjE0IDE0LjMyMDQgOC40ODIxNCAxMy40ODIxVjExLjU2MjVINi41NjI1QzUuNjk5NTUgMTEuNTYyNSA1IDEwLjg2MjkgNSAxMEM1IDkuMTM3MDYgNS42OTk1NiA4LjQzNzUgNi41NjI1IDguNDM3NUg4LjQ4MjE0VjYuNTE3ODZDOC40ODIxNCA1LjY3OTU3IDkuMTYxNzEgNSAxMCA1QzEwLjgzODMgNSAxMS41MTc5IDUuNjc5NTcgMTEuNTE3OSA2LjUxNzg2VjguNDM3NVoiIGZpbGw9IiM5QTg1Q0MiLz48L3N2Zz4=);
      background-position: 13px center;
      border-left: 1px solid rgba(154, 133, 204, 0.37);
    }
  }

  &__area {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-width: 0;
    background-color: #fff;

    width: calc(100% + 30px);
    margin-right: -30px;

    border-radius: 80px 0 0 80px;
  }

  &__field {
    flex-grow: 1;
    min-width: 0;
    border: none;
    padding: 10px 0;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    text-indent: 18px;
    color: #39205f;
    text-align: left;
    box-sizing: border-box;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &.ng-valid.ng-dirty:not(.novalidate),
    &.ng-invalid.ng-touched:not(.novalidate) {
      border: none;
    }
  }

  &__icon,
  &__currency {
    flex-shrink: 0;
    width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  &__icon {
    padding-left: 7px;
  }

  &__icon-image {
    display: block;
    max-width: 18px;
  }

  &__currency {
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    color: #39205f;
    padding-right: 7px;
  }

  &__error {
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 600;
  }

  &__error-text {
    position: relative;
    margin: 0;
    padding: 15px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    box-sizing: border-box;
    text-align: left;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      background-color: $red;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }

    &::after {
      left: calc(50% - 10px);
      top: -18px;
      border: 10px solid transparent;
      border-bottom-color: $red;
    }
  }
}

.bonus-wrap {
  border-radius: 8px;
  position: relative;

  &:not(.bonus-wrap--no-choice):before {
    position: absolute;
    right: 16px;
    top: 20px;
    content: '';
    background-image: url("/img/arr-r-b.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 9px;
    height: 11px;
    transform: rotate(90deg);
    transition: .35s linear;
    z-index: 50;
  }
}

.bonus-wrap {
  margin-bottom: 22px;

  &__reg {
    background: #FFFFFF;
    box-shadow: 0 8px 12px rgba(82, 44, 129, 0.42);
    border-radius: 8px;
    position: relative;
    margin-bottom: 22px;

    &.dep {
      position: relative !important;
    }

    &:before {
      position: absolute;
      right: 16px;
      top: 20px;
      content: '';
      background-image: url("/img/arr-r-b.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 9px;
      height: 11px;
      transform: rotate(90deg);
      transition: .35s linear;
      z-index: 50;
    }

    &.is-open {
      width: 100%;
      position: absolute;
      z-index: 20;

      &:before {
        transform: rotate(-90deg);
      }

      .bonus-pack {
        display: block;
        opacity: 1;

        &__main {
          box-shadow: unset;
        }
      }

      .dep_1,
      .dep_2 {
        width: 100%;

        &.after-dep {
          border-top: 1px solid #EBE7F5;
          padding-bottom: 12px;
          border-radius: 0 0 8px 8px;
          transition: unset;
        }
      }

      .dep_1 {
        transform: translateY(47px) scale(1);
        z-index: -1;
        height: auto;
        background: white;
        box-shadow: unset;
        border-radius: unset;

        .deposit-wrap {
          opacity: 1;
          transform: scale(1);
        }
      }

      .dep_2 {
        transform: translateY(127px) scale(1);
        z-index: -2;
        height: auto;
        background: white;
        box-shadow: unset;
        border-radius: 0 0 8px 8px;


        .deposit-wrap {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  &.is-open {
    width: 100%;
    height: 200%; //todo
    position: absolute;
    z-index: 1000;
    transition: none; //todo

    &:before {
      transform: rotate(-90deg);
    }

    .bonus-pack {
      display: block;
      opacity: 1;

      &__main {
        box-shadow: unset;
      }
    }

    .dep_1,
    .dep_2 {
      width: 100%;
    }

    .dep_1 {
      transform: translateY(47px) scale(1);
      z-index: -1;
      height: auto;
      background: white;
      box-shadow: unset;
      border-radius: unset;

      .deposit-wrap {
        opacity: 1;
        transform: scale(1);
      }
    }

    .dep_2 {
      transform: translateY(127px) scale(1);
      z-index: -2;
      height: auto;
      background: white;
      box-shadow: unset;
      border-radius: unset;
      border-bottom: 1px solid #ebe7f5;

      .deposit-wrap {
        transform: scale(1);
        opacity: 1;
      }
    }

    .bonus-other {
      opacity: 1;
      position: relative;
      transform: translateY(156px) scale(1);
      border-radius: 0 0 8px 8px;
      background: white;
      box-shadow: 0 1px 2px rgba(146, 127, 191, 0.3);

      .no-bonus {
        opacity: 1;
      }

      &.active {
        transform: translateY(208px);
      }
    }
  }
}

.bonus-pack {
  padding: 0;
  opacity: 0;

  &__main {
    position: absolute;
    transition: .25ms ease-in-out;
    padding: 8px 40px 11px 50px;
    background: white;
    z-index: 3;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

    &--no-choice{
      padding-left: 12px;
      padding-right: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    &:not(.bonus-pack__main--no-choice):before {
      position: absolute;
      content: '';
      background: #FFFFFF;
      border: 2px solid rgba(100, 67, 179, 0.13);
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      border-radius: 50px;
    }

    .icon-bonus-info{
      width: 25px;
      height: 25px;
      margin-left: 15px;
    }
  }

  &.active {
    opacity: 1;

    .bonus-pack__main {
      padding-bottom: 8px;
      position: relative;

      &:not(.bonus-pack__main--no-choice):before {
        background: #09AF48 url(/img/check_w.svg) center center no-repeat;
        border: 2px solid #09AF48;
        box-sizing: border-box;
        background-size: 18px;
      }
    }
  }

  &__deposit {
    padding: 4px 14px 0 50px;
    position: absolute;
    background: white;
    top: 0;
    width: 100%;
    transition: .2s ease-in-out;
    z-index: -1;

    &.dep_1 {
      padding-top: 12px;
      transform: translateY(3px) scale(0.96);
      z-index: 2;
      height: 55px;
      border-radius: 0 0 8px 8px;
      background: #9199F5;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

      &.after-dep {
        transition: unset;
      }

      .deposit-wrap {
        opacity: 0;
        transition-delay: .3s;
        transition-property: opacity;
        padding-bottom: 4px;
        transition-duration: .3s;
        transition-timing-function: linear;
        transform: scale(0);

        &:last-child {
          padding-top: 4px;
        }
      }
    }

    &.dep_2 {
      transform: translateY(8px) scale(0.93);
      z-index: 1;
      height: 55px;
      border-radius: 0 0 8px 8px;
      background: #6B75E7;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

      .deposit-wrap {
        opacity: 0;
        transition-delay: .3s;
        transition-property: opacity;
        padding-bottom: 4px;
        transition-duration: .3s;
        transition-timing-function: linear;
        transform: scale(0);

        &:last-child {
          padding-top: 4px;
          padding-bottom: 12px;
        }
      }
    }
  }

  .bonus-title {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #6443B3;
    padding-bottom: 4px;
  }

  .bonus-amount {
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    color: #6443B3;
  }

  .deposit-title {
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: #B7B3DC;
    padding-bottom: 4px;
  }

  .deposit-amount {
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    color: #6443B3;
  }

  &--no-choice{
    position: relative;

    &:before, &:after{
      content: '';
      display: block;
      border-radius: 0 0 8px 8px;
      height: 21px;
      position: absolute;
    }

    &:before{
      background: #9199f5;
      width: 96%;
      bottom: -4px;
      left: 2%;
      z-index: 1;
    }

    &:after{
      background: #6B75E7;
      width: 92%;
      position: absolute;
      bottom: -8px;
      left: 4%;
      z-index: 0;
    }

    .bonus-title {
      font-size: 20px;
      line-height: 20px;
    }

    .bonus-amount{
      font-weight: 700;
      line-height: 20px;
    }
  }

  .bonus-tooltip{
    position: relative;

    .bonus-pack__deposit {
      display: none;
      width: max-content;
      padding: 12px 16px;
      top: 38px;
      left: auto;
      right: -9px;
      border-radius: 6px;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16px 0px rgba(72, 39, 111, 0.26);
      z-index: 2;

      &:before{
        content: '';
        display: inline-block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 6px 11px 6px;
        border-color: transparent transparent #FFFFFF transparent;
        position: absolute;
        top: -10px;
        right: 15px;
      }
    }

    .deposit-title{
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      padding: 0;
    }

    .deposit-amount{
      font-size: 16px;
      line-height: 16px;
    }

    .deposit-wrap + .deposit-wrap{
      margin-top: 8px;
    }
  }
}

.bonus-other {
  padding: 18px 30px 18px 50px;
  position: absolute;
  top: 0;
  z-index: 0;

  width: 100%;
  transition: .25s linear;
  transform: translateY(10px) scale(0.9);
  background: #4C57C9;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 8px 12px rgba(82, 44, 129, 0.42);

  .no-bonus {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #6443B3;
    position: relative;
    opacity: 0;
    transition-timing-function: linear;
    transition-delay: .3s;
    transition-property: opacity;
    padding-bottom: 4px;
    transition-duration: .3s;

    &:before {
      position: absolute;
      content: '';
      background: #FFFFFF;
      border: 2px solid rgba(100, 67, 179, 0.13);
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      top: -5px;
      left: -38px;
      border-radius: 50px;
    }
  }

  &.ab.active {
    background: #FFFFFF;
    box-shadow: 0 1px 3px rgba(146, 127, 191, 0.5);
  }

  &.active {
    display: block;
    position: relative;
    z-index: 1;

    border-radius: 8px;
    opacity: 1;
    transform: translateY(0px) scale(1);
    background: white;

    transition-timing-function: linear;

    .no-bonus {
      opacity: 1;
    }

    .no-bonus {
      &:before {
        background: #09AF48 url(/img/check_w.svg) center center no-repeat;
        border: 2px solid #09AF48;
        box-sizing: border-box;
        background-size: 18px;
      }
    }
  }
}
