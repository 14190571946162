$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.promo-banner {
  width: 100%;
  margin: 10px 0;
  &__title {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #545454;
    margin: 0 0 4px;
  }
  &__text {
    font-weight: 900;
    font-size: 26px;
    line-height: 30px;
    color: #292929;
    margin: 0;
  }

  @include s {
    &__title {
      font-size: 12px;
      line-height: 15px;
    }
    &__text {
      font-size: 26px;
      line-height: 26px;
    }
  }

  @include xxs {
    &__title {
      font-size: 11px;
      line-height: 15px;
      margin: 0 0 2px;
    }
    &__text {
      font-size: 20px;
      line-height: 24px;
    }
  }
}