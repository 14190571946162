$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.wrap-cont {
  box-shadow: 0 4px 12px rgba(190, 179, 230, 0.57);
  border-radius: 8px;
  max-width: 408px;
  margin: 0 auto;
  padding: 16px 0 32px;

  @include min-s {
    box-shadow: 0 4px 12px rgba(190, 179, 230, 0.57);
    border-radius: 8px;
    max-width: 320px;
    margin: 0 auto;
    padding: 16px;
  }
}
