$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;

.slider-tras {
  display: block;
  padding-top: 100px;

  @include min-xs {
    padding-top: 50px;
  }

  &__wrap {
    position: relative;
    text-align: center;
    color: #FFFFFF;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
  }

  &__text {
    margin-top: 28px;
    font-weight: bold;
    font-size: 28px;
    line-height: 140%;
  }

  &__btn {
    margin-top: 30px;
  }

  &__link {
    margin-top: 20px;
    text-align: center;

    & a {
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      text-decoration-line: underline;
      color: #FFFFFF;
    }
  }

  &__nav{
    position:absolute;
    width: 0px;
    height: 0;
    left: 50%;
    transform: translateY(75px);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  //&__arr {
  //
  //  cursor: pointer;
  //  width: 12px;
  //  height: 12px;
  //  border: none;
  //  margin: 6px 8px 0 8px;
  //
  //  background-image: url('/img/arr-r-slick.svg');
  //  background-size: contain;
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-color: rgba(0, 0, 0, 0);
  //  filter: brightness(100);
  //
  //  &.is-next {
  //    order: 3;
  //  }
  //
  //  &.is-prev {
  //    transform: rotate(-180deg);
  //  }
  //}

  &__dots {

    order: 2;
    flex-shrink: 0;
    display: flex;

    & ul {
      margin: 0;
    }

    & li {
      margin: 0;
      padding: 0;
    }

    & button {
      padding: 0;
      margin: 4px;
      font-size: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: none;
      background: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }

    //& .slick-active button {
    //  background: #fff;
    //}
  }

  //@include xs {
  //  &__title {
  //    font-size: 12px;
  //  }
  //  &__text {
  //    font-size: 20px;
  //    line-height: 140%;
  //  }
  //  &__btn {
  //    margin-top: 16px;
  //  }
  //  &__link {
  //    margin-top: 16px;
  //  }
  //}
}

