$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;
.footerLicense {

  .footerLicense-icons {
    justify-content: start;
    margin-bottom: 20px;

    a:not([href]){
      cursor: default;
    }

    &__license {
      width: 34px;
      height: 34px;
    }
  }

  .footerLicense-text,
  .footerLicense-hiddenText {
    color: #626262;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    p {
      margin: 0 0 10px;
    }
  }

  .footerLicense-hiddenText {
    button {
      padding-inline: 0;

      &::after {
        filter: brightness(0) saturate(100%) invert(43%) sepia(0%) saturate(1731%) hue-rotate(172deg) brightness(92%) contrast(111%);
      }
    }
  }
}
